import { GridSortModel } from '@mui/x-data-grid/models';
import useSearchQueryArgs, { UseQueryArgsProps } from './useSearchQueryArgs';

function useDataGridSearchQueryArgs(props: UseQueryArgsProps = {}) {
  const searchQueryArgs = useSearchQueryArgs(props);

  const setDataGridSortModel = (sortModel: GridSortModel) => {
    if (!sortModel.length) {
      searchQueryArgs.setSortModel({});
      return;
    }
    const sort = sortModel[0];
    searchQueryArgs.setSortModel({
      sortBy: sort.field,
      sortDir: sort.sort,
    });
  };

  return { ...searchQueryArgs, setDataGridSortModel };
}

export default useDataGridSearchQueryArgs;
