const HourGlass = () => {
  return (
    <svg
      width="12"
      height="16"
      viewBox="0 0 12 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.7812 1.78125C10.7812 4.1543 9.84375 6.64453 7.91016 7.875C9.81445 9.13477 10.7812 11.625 10.7812 13.9688H10.8984C11.0742 13.9688 11.25 14.1445 11.25 14.3203V15.0234C11.25 15.2285 11.0742 15.375 10.8984 15.375H0.351562C0.146484 15.375 0 15.2285 0 15.0234V14.3203C0 14.1445 0.146484 13.9688 0.351562 13.9688H0.46875C0.46875 11.625 1.37695 9.13477 3.31055 7.875C1.40625 6.64453 0.46875 4.1543 0.46875 1.78125H0.351562C0.146484 1.78125 0 1.63477 0 1.42969V0.726562C0 0.550781 0.146484 0.375 0.351562 0.375H10.8984C11.0742 0.375 11.25 0.550781 11.25 0.726562V1.42969C11.25 1.63477 11.0742 1.78125 10.8984 1.78125H10.7812ZM1.875 1.78125C1.875 4.76953 3.54492 7.17188 5.625 7.17188C7.67578 7.17188 9.375 4.76953 9.375 1.78125H1.875ZM9.375 13.9688C9.375 11.0098 7.67578 8.57812 5.625 8.57812C3.54492 8.57812 1.875 11.0098 1.875 13.9688H9.375Z"
        fill="#586068"
      />
    </svg>
  );
};

export default HourGlass;
