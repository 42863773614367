import { extendTailwindMerge } from 'tailwind-merge';
import { colors } from './theme';
import dayjs, { Dayjs } from 'dayjs';

export const rgbWithOpacity = (hex: string) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  const opacity = 0.5;
  const result = `rgba(${r}, ${g}, ${b}, ${opacity})`;
  return result;
};

export const isEmailValid = (email: string) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const parseAppt = (info: string) => {
  let type = '';
  switch (info) {
    case 'initial_visit':
      type = 'Initial Visit';
      break;
    case 'return_ob':
      type = 'Return OB';
      break;
    case 'return_gyn':
      type = 'Return GYN';
      break;
    case 'prob_gyn':
      type = 'Prob GYN';
      break;
    case 'post_partum':
      type = 'PP';
      break;
    case 'prenatal':
      type = 'Prenatal';
      break;
    case '90min':
      type = '90 Min';
      break;
    case 'block':
      type = 'Block';
      break;
    case 'general':
      type = 'General';
      break;
  }
  return type;
};

export const parseApptColor = (info: string) => {
  let color = '';
  switch (info) {
    case 'initial_visit':
      color = colors.pastelCyan;
      break;
    case 'return_ob':
      color = colors.pastelGreen;
      break;
    case 'return_gyn':
      color = colors.pastelYellow;
      break;
    case 'prob_gyn':
      color = colors.pastelOrange;
      break;
    case 'post_partum':
      color = colors.pastelBlue;
      break;
    case 'prenatal':
      color = colors.purple;
      break;
    case '90min':
      color = colors.pastelPink;
      break;
    case 'block':
      color = colors.grayBlock;
      break;
    case 'general':
      color = colors.beige;
      break;
  }
  return color;
};

export function calculateGestationalAge(edd?: string | Dayjs | null) {
  if (!edd || !dayjs(edd).isValid()) {
    return '-';
  }
  const today = dayjs();
  const dueDate = dayjs(edd);

  // Give a buffer of 1 month after the due date
  if (today.isAfter(dueDate.add(1, 'month'))) {
    return '-';
  }

  const startOfPregnancy = dueDate.subtract(40, 'weeks');

  // Calculate the difference in days between today and the due date
  const diffInDays = today.diff(startOfPregnancy, 'days');

  // Calculate the gestational age in weeks and days
  const gestationalWeeks = Math.floor(diffInDays / 7);
  const gestationalDays = diffInDays % 7;

  return `${gestationalWeeks}w ${gestationalDays}d`;
}

function calculateDueDateFromLMP(lmpDate: string | Dayjs | null) {
  if (!lmpDate) {
    return null;
  }
  const lmp = dayjs(lmpDate);
  const dueDate = lmp.add(7, 'day').add(9, 'month');
  return dueDate;
}

function calculateDueDateFromConception(conceptionDate: string | Dayjs | null) {
  if (!conceptionDate) {
    return null;
  }

  const conception = dayjs(conceptionDate);
  const dueDate = conception.add(266, 'day');
  return dueDate;
}

export function calculateDueDate({
  lmp,
  edd,
  dateOfConception,
  dateToCalculateDueDate,
}: {
  lmp?: string | Dayjs | null;
  edd?: string | Dayjs | null;
  dateOfConception?: string | Dayjs | null;
  dateToCalculateDueDate?: 'edd' | 'lmp' | 'dateOfConception' | null;
}): Dayjs | string | null | undefined {
  if (dateToCalculateDueDate) {
    switch (dateToCalculateDueDate) {
      case 'edd':
        return edd;
      case 'lmp':
        return lmp && calculateDueDateFromLMP(lmp);
      case 'dateOfConception':
        return (
          dateOfConception && calculateDueDateFromConception(dateOfConception)
        );
    }
  }

  if (edd) {
    return edd;
  }
  if (lmp) {
    return calculateDueDateFromLMP(lmp);
  }
  if (dateOfConception) {
    return calculateDueDateFromConception(dateOfConception);
  }
  return null;
}

export const colorPerInitial = (initial: string) => {
  switch (initial) {
    case 'A':
    case 'I':
    case 'Q':
    case 'Y':
      return 'bg-pastelRed';
    case 'B':
    case 'J':
    case 'R':
    case 'Z':
      return 'bg-pastelOrange';
    case 'C':
    case 'K':
    case 'S':
      return 'bg-pastelYellow';
    case 'D':
    case 'L':
    case 'T':
      return 'bg-pastelGreen';
    case 'E':
    case 'M':
    case 'U':
      return 'bg-pastelCyan';
    case 'F':
    case 'N':
    case 'V':
      return 'bg-pastelBlue';
    case 'G':
    case 'O':
    case 'W':
      return 'bg-pastelPurple';
    case 'H':
    case 'P':
    case 'X':
      return 'bg-pastelPink';
    default:
      return 'bg-grayLight';
  }
};

// Transform an MRN with structure 'A12345678' to 'A 1234 5678'
export function formatMRN(mrn: string | null) {
  if (!mrn) {
    return '-';
  }
  return `${mrn.slice(0, 1)} ${mrn.slice(1, 5)} ${mrn.slice(5)}`;
}

export const customTwMerge = extendTailwindMerge({
  extend: {
    theme: {
      colors: [],
    },
    classGroups: {
      'text-color': [
        {
          text: [
            'aster',
            'asterGray',
            'beige',
            'black',
            'gray',
            'green',
            'neutral-600',
            'grayBackground',
            'grayCard',
            'grayBlock',
            'grayLight',
            'pastelBlue',
            'pastelCyan',
            'pastelGreen',
            'pastelOrange',
            'pastelRed',
            'pastelYellow',
            'pastelPink',
            'pastelPurple',
            'primary',
            'purple',
            'red',
            'secondary',
            'transparent',
            'white',
          ],
        },
      ],
      'font-size': [
        {
          text: [
            'body',
            'bodyMedium',
            'bodySmall',
            'h1',
            'h2',
            'h3',
            'h4',
            'h5',
            'h6',
            'p10',
            'p12',
          ],
        },
      ],
    },
  },
});
