import { colorPerInitial, customTwMerge as twMerge } from '../utils';
import Typography, { Variant } from './Typography';

type CircleProps = {
  name: string;
  height: number;
  width: number;
  customClass?: string;
  textVariant?: Variant;
};

const CircleWithInitials = ({
  name,
  customClass,
  textVariant = 'h4',
  width,
  height,
}: CircleProps) => {
  const nameArray = name.split(' ');
  const initials = `${nameArray[0].charAt(0)}${nameArray.at(-1)?.charAt(0)}`;
  const color = colorPerInitial(nameArray[0].charAt(0).toUpperCase());

  return (
    <div
      className={`${twMerge(
        'rounded-full flex items-center justify-center text-h1 text-gray mix-blend-luminosity',
        [color, customClass]
      )}`}
      style={{ width: `${width}px`, height: `${height}px` }}
    >
      <Typography
        variant={textVariant}
        text={initials}
        customClass="font-normal"
      />
    </div>
  );
};

export default CircleWithInitials;
