import React, { useState, useCallback, useMemo, useEffect } from 'react';
import ButtonType from '../../../components/Button';
import { Option } from '../../../components/Autocomplete';
import BasicTextfield from '../../../components/Textfield';
import CalendarDay from '../../../assets/icons/CalendarDay';
import Clock from '../../../assets/icons/Clock';
import Forms from '../../../assets/icons/Forms';
import HourGlass from '../../../assets/icons/HourGlass';
import dayjs from 'dayjs';
import Typography from '../../../components/Typography';
import { EventImpl } from '@fullcalendar/core/internal';
import { InviteeDTO } from '@aster/shared/dtos/appointment';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CalendarDatePicker from './CalendarDatePicker';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';
import Participants from './Participants';
import SelectInvitees from './SelectInvitees';

function calculateAppointmentTitle(title: string) {
  switch (title) {
    case 'PP':
      return 'Post Partum';
    case 'New OB':
      return 'Initial Visit';
    default:
      return title;
  }
}

type ModalProps = {
  open: boolean;
  eventInfo: EventImpl;
  handleConfirm: (e: Partial<EventImpl>) => void;
  handleCancel?: () => void;
  handleClose: () => void;
  handleRemove?: any;
};

const EditApptModal = (props: ModalProps) => {
  const { open, handleClose, handleCancel, handleConfirm, eventInfo } = props;
  const [note, setNote] = useState<string>(
    eventInfo?._def?.extendedProps?.note
  );
  const appointment = calculateAppointmentTitle(
    eventInfo?._def?.extendedProps.appoitment
  );
  const [reschedule, setReschedule] = useState(false);
  const [startDate, setStartDate] = useState(
    eventInfo?.start
      ? dayjs(eventInfo.start)
      : dayjs().set('hour', 7).set('minute', 0)
  );
  const [endDate, setEndDate] = useState(() =>
    eventInfo?.end
      ? dayjs(eventInfo.end)
      : dayjs().set('hour', 7).set('minute', 30)
  );
  const [loading, setLoading] = useState(false);
  const [invitedPatients, setInvitedPatients] = useState<Option[]>(
    eventInfo?._def?.extendedProps?.invitedPatients.map((p: InviteeDTO) => ({
      id: p.id,
      label: `${p.firstName} ${p.lastName}`,
    })) || []
  );
  const [invitedStaff, setInvitedStaff] = useState<Option[]>(
    () =>
      eventInfo?._def?.extendedProps?.invitedStaffs.map((p: InviteeDTO) => ({
        id: p.id,
        label: `${p.firstName} ${p.lastName}`,
      })) || []
  );

  useEffect(() => {
    setStartDate(dayjs(eventInfo.start));
    setEndDate(dayjs(eventInfo.end));
    setInvitedPatients(
      eventInfo?._def?.extendedProps?.invitedPatients.map((p: InviteeDTO) => ({
        id: p.id,
        label: `${p.firstName} ${p.lastName}`,
      })) || []
    );
    setInvitedStaff(
      eventInfo?._def?.extendedProps?.invitedStaffs.map((p: InviteeDTO) => ({
        id: p.id,
        label: `${p.firstName} ${p.lastName}`,
      })) || []
    );
    setNote(eventInfo?._def?.extendedProps?.note);
  }, [eventInfo]);

  const prepareInfo = useCallback(() => {
    setLoading(true);

    const start = startDate;
    const end = endDate;

    const apptInfo: Partial<EventImpl> & Record<string, unknown> = {
      invitedPatientIDs: invitedPatients.map((option) => option.id),
      invitedStaffIDs: invitedStaff.map((option) => option.id),
      apptId: eventInfo?._def?.publicId,
      dispAppt: eventInfo?._def?.extendedProps.appointment,
      start: start.toDate(),
      end: end.toDate(),
      note,
      checked: eventInfo?._def?.extendedProps?.telehealth,
      color: eventInfo?.borderColor,
    };
    handleConfirm(apptInfo);
    setLoading(false);
  }, [
    endDate,
    eventInfo?._def?.extendedProps.appointment,
    eventInfo?._def?.extendedProps?.telehealth,
    eventInfo?._def?.publicId,
    eventInfo?.borderColor,
    handleConfirm,
    invitedPatients,
    invitedStaff,
    note,
    startDate,
  ]);

  return (
    <Dialog
      open={open}
      keepMounted
      fullWidth
      maxWidth={'sm'}
      onClose={() => {
        handleClose();
        setReschedule(false);
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <div className="flex flex-col items-center">
        <DialogContent className="bg-white w-full mb-1">
          {appointment && <Typography variant="h4" text={appointment} />}
          <div className=" flex flex-col items-center">
            <div className="flex flex-col w-full gap-y-4 ml-1 mb-4">
              <TypeAndDuration eventInfo={eventInfo} />
              <DateAndTime startDate={startDate} endDate={endDate} />
              {!reschedule && (
                <Participants
                  invitedStaff={invitedStaff}
                  invitedPatients={invitedPatients}
                />
              )}
            </div>
            {reschedule && (
              <div className="mt-[-1px]">
                <SelectInvitees
                  invitedPatients={invitedPatients}
                  setInvitedPatients={setInvitedPatients}
                  invitedStaff={invitedStaff}
                  setInvitedStaff={setInvitedStaff}
                />
                <CalendarDatePicker
                  startDate={startDate}
                  onStartDateChange={setStartDate}
                  endDate={endDate}
                  onEndDateChange={setEndDate}
                  eventInfo={eventInfo}
                  isEdit={true}
                />
              </div>
            )}

            <BasicTextfield
              id="note"
              variant="filled"
              label="Short note..."
              rows={4}
              value={note}
              multiline
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setNote(event.target.value);
              }}
            />
          </div>
        </DialogContent>
      </div>
      <DialogActions className="flex justify-start bg-white ">
        {!reschedule && (
          <ButtonType
            variant="contained"
            onClick={() => setReschedule(true)}
            text="Reschedule"
            classes={'!rounded-full'}
          />
        )}
        {!reschedule && (
          <ButtonType
            variant="text"
            onClick={handleCancel}
            text="Delete Appointment"
            classes={'!rounded-full mt-2 !font-semibold'}
          />
        )}
        {reschedule && (
          <ButtonType
            variant="contained"
            onClick={prepareInfo}
            text="Confirm"
            loading={loading}
            classes={'!rounded-full'}
          />
        )}
        {reschedule && (
          <ButtonType
            variant="text"
            onClick={() => setReschedule(false)}
            text="Discard changes"
            classes={'!rounded-full mt-2 !font-semibold'}
          />
        )}
      </DialogActions>
    </Dialog>
  );
};

const TypeAndDuration = ({ eventInfo }: { eventInfo: EventImpl }) => {
  const appointment = calculateAppointmentTitle(
    eventInfo?._def?.extendedProps.appointment
  );
  const duration = useMemo(
    () =>
      `${dayjs(eventInfo?.end).diff(dayjs(eventInfo?.start), 'minutes')} min`,
    [eventInfo?.end, eventInfo?.start]
  );

  return (
    <div className="grid grid-cols-2 w-full h-[50px]">
      <Grid container>
        <Grid item xs={2} pt={3}>
          <Forms />
        </Grid>
        <Grid item xs={10} height={50} mt={2}>
          <p className="text-bodySmall text-gray">Type</p>
          <p className="text-bodySmall font-semibold text-black ">
            {appointment}
          </p>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={2} pt={3}>
          <HourGlass />
        </Grid>
        <Grid item xs={10} height={50} mt={2}>
          <p className="text-bodySmall text-gray">Duration</p>
          <p className="text-bodySmall font-semibold text-black ">{duration}</p>
        </Grid>
      </Grid>
    </div>
  );
};

const DateAndTime = ({
  startDate,
  endDate,
}: {
  startDate: dayjs.Dayjs;
  endDate: dayjs.Dayjs;
}) => {
  const dateDisplay = useMemo(
    () =>
      `${startDate.format('MM/DD/YYYY')}${
        startDate.day() !== endDate.day()
          ? ' - ' + endDate.format('MM/DD/YYYY')
          : ''
      }`,
    [endDate, startDate]
  );

  const displayTime = useMemo(() => {
    const displayTimeString = `${startDate?.format('hh:mm a')} - ${dayjs(
      endDate
    ).format('hh:mm a')}`;
    return displayTimeString;
  }, [startDate, endDate]);

  return (
    <div className="grid grid-cols-2 w-full h-[50px]">
      <Grid container className="items-center">
        <Grid item xs={2}>
          <CalendarDay />
        </Grid>
        <Grid item xs={10} className="items-center">
          <Typography
            variant={'bodySmall'}
            text={dateDisplay}
            customClass="text-black font-semibold"
          />
        </Grid>
      </Grid>
      <Grid container className="items-center">
        <Grid item xs={2}>
          <Clock />
        </Grid>
        <Grid item xs={10}>
          <Typography
            variant={'bodySmall'}
            text={displayTime}
            customClass="text-black font-semibold"
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default EditApptModal;
