import { GTPALDTO } from '@aster/shared/dtos/patient';
import dayjs from 'dayjs';

export const displayAge = (age?: number | null): string => {
  if (!age) return '<strong> XX</strong> years old';
  return `<strong> ${age.toString()}</strong> years old`;
};

export const displayGTPAL = (gtpal?: GTPALDTO | null): string => {
  if (gtpal) {
    const {
      gravida,
      termBirths,
      pretermBirths,
      abortionsAndMiscarriages,
      livingChildren,
    } = gtpal;
    return `<strong>G${gravida}T${termBirths}P${pretermBirths}A${abortionsAndMiscarriages}L${livingChildren}</strong>`;
  }
  return '<strong>GXP0000</strong>';
};

export const displayBirthDate = (
  edd?: string | null,
  lmp?: string | null
): string => {
  if (edd !== null)
    return `<strong>EDD ${dayjs(edd).format('MM/DD/YYYY')}</strong>`;
  if (lmp !== null)
    return `<strong>LM ${dayjs(lmp).format('MM/DD/YYYY')}</strong>`;
  return '<strong>LMP/EDD MM/DD/YYYY</strong>';
};

export const displayGestationalAge = (
  edd?: string | null,
  lmp?: string | null
): string => {
  if (lmp) {
    const lmpDate = dayjs(lmp);
    const today = dayjs();
    const diffInDays = today.diff(lmpDate, 'day');
    const weeks = Math.floor(diffInDays / 7);
    const days = diffInDays % 7;
    return `${weeks}w ${days}d`;
  }

  if (edd) {
    const today = dayjs();
    const dueDate = dayjs(edd);
    const startOfPregnancy = dueDate.subtract(40, 'weeks');
    const diffInDays = today.diff(startOfPregnancy, 'days');
    const gestationalWeeks = Math.floor(diffInDays / 7);
    const gestationalDays = diffInDays % 7;
    return `${gestationalWeeks}w ${gestationalDays}d`;
  }

  return `XXw XXd`;
};
