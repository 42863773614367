import styled from '@mui/system/styled';
import { DataGrid } from '@mui/x-data-grid/DataGrid';
import { DataGridProps } from '@mui/x-data-grid/models/props/DataGridProps';

const StyledDataGrid = styled((props: DataGridProps) => (
  <DataGrid {...props} />
))(({ theme }) => ({
  '&, [class^=MuiDataGrid]': { border: 'none' },
}));

export default StyledDataGrid;
