const Clock = () => {
  return (
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 0.609375C11.5137 0.609375 14.7656 3.86133 14.7656 7.875C14.7656 11.8887 11.5137 15.1406 7.5 15.1406C3.48633 15.1406 0.234375 11.8887 0.234375 7.875C0.234375 3.86133 3.48633 0.609375 7.5 0.609375ZM7.5 13.7344C10.7227 13.7344 13.3594 11.127 13.3594 7.875C13.3594 4.65234 10.7227 2.01562 7.5 2.01562C4.24805 2.01562 1.64062 4.65234 1.64062 7.875C1.64062 11.127 4.24805 13.7344 7.5 13.7344ZM9.28711 10.6875L6.79688 8.87109C6.70898 8.8125 6.67969 8.69531 6.67969 8.60742V3.77344C6.67969 3.59766 6.82617 3.42188 7.03125 3.42188H7.96875C8.14453 3.42188 8.32031 3.59766 8.32031 3.77344V7.93359L10.2539 9.36914C10.4297 9.48633 10.459 9.69141 10.3418 9.86719L9.78516 10.5996C9.66797 10.7754 9.46289 10.8047 9.28711 10.6875Z"
        fill="#586068"
      />
    </svg>
  );
};

export default Clock;
