import BasicTextfield from '../../../components/Textfield';
import Typography from '../../../components/Typography';
import SelectDropdown from '../../../components/SelectDropdown';
import NotificationImportantRoundedIcon from '@mui/icons-material/NotificationImportantRounded';
import ContactEmergencyRoundedIcon from '@mui/icons-material/ContactEmergencyRounded';
import LocalHospitalRoundedIcon from '@mui/icons-material/LocalHospitalRounded';
import Diversity1RoundedIcon from '@mui/icons-material/Diversity1Rounded';
import LocalPharmacyIcon from '@mui/icons-material/LocalPharmacy';
import {
  ContactListInfo,
  PreferredPharmacyInfo,
  ReferralInfo,
} from '../types/patient-profile';

type ContactListInfoProps = {
  contactList: ContactListInfo;
  referralInfo: ReferralInfo;
  preferredPharmacyInfo: PreferredPharmacyInfo;
  handleReferralInfo: (event: any) => void;
  handleReferralInfoSelect: (event: any, property: string) => void;
  handlePreferredPharmacyInfo: (event: any) => void;
  handleInfo: (event: any) => void;
  handleInfoSelect: (event: any, property: string) => void;
};

const ContactList = (props: ContactListInfoProps) => {
  const handleReferralInfo = props.handleReferralInfo;
  const handleReferralInfoSelect = props.handleReferralInfoSelect;
  const handleInfo = props.handleInfo;
  const handleInfoSelect = props.handleInfoSelect;
  const handlePreferredPharmacyInfo = props.handlePreferredPharmacyInfo;
  const {
    emergencyName,
    emergencyRelationship,
    emergencyPhone,
    emergencyEmail,
    emergencyAddress,
    physicianName,
    physicianPhone,
    physicianEmail,
    physicianAddress,
    providerName,
    providerType,
    providerPhone,
    providerEmail,
    providerAddress,
    otherName,
    otherType,
    otherPhone,
    otherEmail,
    otherAddress,
  } = props.contactList;

  const { howDidFindPractice, practiceFindingDetails } = props.referralInfo;
  const {
    preferredPharmacyName,
    preferredPharmacyPhone,
    preferredPharmacyAddress,
  } = props.preferredPharmacyInfo;

  return (
    <div className="container w-full pb-10">
      <Typography
        text="Contact List"
        variant="h5"
        customClass="font-semibold mb-5"
      ></Typography>

      <div className="h-fit bg-grayCard rounded-[10px] px-3 flex flex-col my-4 w-full">
        <div className="flex flex-row justify-start items-center p-2">
          <div className="my-1 mr-1">
            <NotificationImportantRoundedIcon color="error" fontSize="medium" />
          </div>
          <Typography variant="h6" text="Emergency Contact" />
        </div>

        <div className="grid grid-cols-1 grid-flow-row lg:grid-cols-2 2xl:grid-cols-3 gap-x-4">
          <BasicTextfield
            id="emergencyName"
            variant="filled"
            label="Name"
            name="emergencyName"
            value={emergencyName}
            onChange={handleInfo}
            containerClasses="mb-5"
          />
          <BasicTextfield
            id="emergencyRelationship"
            variant="filled"
            label="Relationship"
            name="emergencyRelationship"
            value={emergencyRelationship}
            onChange={handleInfo}
            containerClasses="mb-5"
          />
          <BasicTextfield
            id="emergencyEmail"
            variant="filled"
            label="Email"
            name="emergencyEmail"
            value={emergencyEmail}
            onChange={handleInfo}
            containerClasses="mb-5 lg:col-span-2 2xl:col-span-1"
          />
        </div>
        <div className="grid grid-cols-1 xl:grid-cols-fill-last gap-x-4">
          <BasicTextfield
            id="emergencyPhone"
            variant="filled"
            label="Phone"
            name="emergencyPhone"
            value={emergencyPhone}
            onChange={handleInfo}
            classes="mb-5 xl:min-w-[300px]"
          />
          <BasicTextfield
            id="emergencyAddress"
            variant="filled"
            label="Address"
            name="emergencyAddress"
            value={emergencyAddress}
            onChange={handleInfo}
            classes="mb-5"
          />
        </div>
      </div>

      <div className="w-full h-fit bg-grayCard rounded-[10px] px-3 flex flex-col my-2">
        <div className="flex flex-row justify-start items-center p-2">
          <div className="my-1 mr-1">
            <LocalHospitalRoundedIcon color="primary" fontSize="medium" />
          </div>
          <Typography variant="h6" text="Primary Care Physician (PCP)" />
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-x-4">
          <BasicTextfield
            id="physicianName"
            variant="filled"
            label="Name"
            name="physicianName"
            value={physicianName}
            onChange={handleInfo}
            classes="mb-5"
          />
          <BasicTextfield
            id="physicianAddress"
            variant="filled"
            label="Address"
            name="physicianAddress"
            value={physicianAddress}
            onChange={handleInfo}
            classes="mb-5"
          />
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-x-4">
          <BasicTextfield
            id="physicianPhone"
            variant="filled"
            label="Phone"
            name="physicianPhone"
            value={physicianPhone}
            onChange={handleInfo}
            classes="mb-5"
          />
          <BasicTextfield
            id="physicianEmail"
            variant="filled"
            label="Email"
            name="physicianEmail"
            value={physicianEmail}
            onChange={handleInfo}
            classes="mb-5"
          />
        </div>
      </div>

      <div className="w-full h-fit bg-grayCard rounded-[10px] px-3 flex flex-col my-4">
        <div className="flex flex-row justify-start items-center p-2">
          <div className="my-1 mr-1">
            <ContactEmergencyRoundedIcon color="info" fontSize="medium" />
          </div>
          <Typography variant="h6" text="Current Providers" />
        </div>

        <div className="grid grid-cols-1 items-center lg:grid-cols-2 2xl:grid-cols-3 gap-x-4 gap-y-4">
          <Typography
            variant="body"
            text="Are you currently receiving care from a midwife, or OB/GYN?"
            customClass="mt-1.5"
          />
          <SelectDropdown
            label="Provider Type"
            options={[
              { value: 'obgyn', text: 'OB/GYN' },
              { value: 'midwife', text: 'Midwife' },
              { value: 'doula', text: 'Doula' },
              { value: 'nutritionist', text: 'Nutritionist' },
            ]}
            value={providerType}
            handleChange={(event: any) =>
              handleInfoSelect(event, 'providerType')
            }
            classes="mb-4 md:mb-0"
          />
          <BasicTextfield
            id="providerName"
            variant="filled"
            label="Name"
            name="providerName"
            value={providerName}
            onChange={handleInfo}
            containerClasses="my-5 lg:col-span-2 2xl:col-span-1"
          />
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-x-4">
          <BasicTextfield
            id="providerPhone"
            variant="filled"
            label="Phone"
            name="providerPhone"
            value={providerPhone}
            onChange={handleInfo}
            classes="mb-5"
          />
          <BasicTextfield
            id="providerEmail"
            variant="filled"
            label="Email"
            name="providerEmail"
            value={providerEmail}
            onChange={handleInfo}
            classes="mb-5"
          />
          <BasicTextfield
            id="providerAddress"
            variant="filled"
            label="Address"
            name="providerAddress"
            value={providerAddress}
            onChange={handleInfo}
            containerClasses="mb-5 lg:col-span-2 2xl:col-span-1"
          />
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-x-4 gap-y-4">
          <Typography
            variant="body"
            text="Are you working with any other provider?"
            customClass="mt-1.5"
          />
          <SelectDropdown
            label="Provider Type"
            options={[
              { value: 'obgyn', text: 'OB/GYN' },
              { value: 'midwife', text: 'Midwife' },
              { value: 'doula', text: 'Doula' },
              { value: 'nutritionist', text: 'Nutritionist' },
            ]}
            value={otherType}
            handleChange={(event: any) => handleInfoSelect(event, 'otherType')}
            classes="mb-4 md:mb-0"
          />
          <BasicTextfield
            id="otherName"
            variant="filled"
            label="Name"
            name="otherName"
            value={otherName}
            onChange={handleInfo}
            containerClasses="mb-5 lg:col-span-2 2xl:col-span-1"
          />
        </div>
        <div className="grid grid-cols-1 items-center lg:grid-cols-2 2xl:grid-cols-3 gap-x-4">
          <BasicTextfield
            id="otherPhone"
            variant="filled"
            label="Phone"
            name="otherPhone"
            value={otherPhone}
            onChange={handleInfo}
            classes="mb-5"
          />
          <BasicTextfield
            id="otherEmail"
            variant="filled"
            label="Email"
            name="otherEmail"
            value={otherEmail}
            onChange={handleInfo}
            classes="mb-5"
          />
          <BasicTextfield
            id="otherAddress"
            variant="filled"
            label="Address"
            name="otherAddress"
            value={otherAddress}
            onChange={handleInfo}
            containerClasses="mb-5 lg:col-span-2 2xl:col-span-1"
          />
        </div>
      </div>

      <div className="w-full h-fit bg-grayCard rounded-[10px] px-3 flex flex-col my-2">
        <div className="flex flex-row justify-start items-center p-2">
          <div className="my-1 mr-1">
            <LocalPharmacyIcon color="primary" fontSize="medium" />
          </div>
          <Typography variant="h6" text="Preferred Pharmacy" />
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-x-4">
          <BasicTextfield
            id="preferredPharmacyName"
            variant="filled"
            label="Pharmacy Name"
            name="preferredPharmacyName"
            value={preferredPharmacyName}
            onChange={handlePreferredPharmacyInfo}
            classes="mb-5"
          />
          <BasicTextfield
            id="preferredPharmacyAddress"
            variant="filled"
            label="Pharmacy Address"
            name="preferredPharmacyAddress"
            value={preferredPharmacyAddress}
            onChange={handlePreferredPharmacyInfo}
            classes="mb-5"
          />
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-x-4">
          <BasicTextfield
            id="preferredPharmacyPhone"
            variant="filled"
            label="Pharmacy Phone"
            name="preferredPharmacyPhone"
            value={preferredPharmacyPhone}
            onChange={handlePreferredPharmacyInfo}
            classes="mb-5"
          />
        </div>
      </div>

      <div className="w-full h-fit bg-grayCard rounded-[10px] px-3 flex flex-col  my-2">
        <div className="flex flex-row justify-start items-center p-2">
          <div className="my-1 mr-1">
            <Diversity1RoundedIcon color="success" fontSize="medium" />
          </div>
          <Typography variant="h6" text="Referral" />
        </div>

        <div className="grid grid-cols-1 items-center lg:grid-cols-2 2xl:grid-cols-3 gap-x-4 gap-y-4">
          <Typography
            variant="body"
            text="How did you hear about our practice?"
            customClass="mt-1.5 "
          />
          <SelectDropdown
            label="Referral Type"
            options={[
              { value: 'friend', text: 'Friend' },
              { value: 'family', text: 'Family Member' },
              { value: 'internet_search', text: 'Internet Search' },
              { value: 'community', text: 'Community' },
              { value: 'social_media', text: 'Social Media' },
              { value: 'healthcare_provider', text: 'Healthcare Provider' },
              { value: 'other', text: 'Other' },
            ]}
            value={howDidFindPractice ?? ''}
            handleChange={(event: any) =>
              handleReferralInfoSelect(event, 'howDidFindPractice')
            }
            classes="mb-4 md:mb-0"
          />
          <BasicTextfield
            id="practiceFindingDetails"
            variant="filled"
            label="Details"
            name="practiceFindingDetails"
            value={practiceFindingDetails}
            onChange={handleReferralInfo}
            containerClasses="my-5 lg:col-span-2 2xl:col-span-1"
          />
        </div>
      </div>
    </div>
  );
};

export default ContactList;
