import TryFirst from '../../assets/icons/TryFirst';
import UserPlus from '../../assets/icons/UserPlus';
import ButtonType from '../../components/Button';
import CalendarPlus from '../../assets/icons/CalendarPlus';
import BookSpells from '../../assets/icons/BookSpells';
import { useNavigate } from 'react-router-dom';
import Typography from '../../components/Typography';
import { Card } from './HomeCard';
import midwife from '../../assets/empty-images/home-illustration.webp';
import AddIcon from '@mui/icons-material/Add';
import dayjs from 'dayjs';
import { useAuth } from '../../authentication/AuthProvider';

function getTimeOfDay() {
  const currentHour = dayjs().hour();

  if (currentHour >= 5 && currentHour < 12) {
    return 'Morning';
  } else if (currentHour >= 12 && currentHour < 18) {
    return 'Afternoon';
  } else {
    return 'Evening';
  }
}

export default function () {
  const navigate = useNavigate();
  const { profile } = useAuth();
  return (
    <div className="flex flex-col w-full items-center justify-center py-24">
      <div className="flex items-center">
        <div className="flex flex-col">
          <Typography
            variant="h3"
            text={`${getTimeOfDay()} ${profile?.firstName},`}
          />
          <Typography
            variant="h3"
            text="Welcome to Aster!"
            customClass="font-normal"
          />
        </div>
        <img src={midwife} alt="Midwife" className="w-80" />
      </div>
      <Typography
        variant="body"
        text="Congratulations on taking the first step towards streamlining your practice with Aster. You can now focus on providing the best care! Let’s get started:"
        customClass="text-gray text-center max-w-[68ch]"
      />

      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-10 mt-16">
        <div className="flex-1 relative">
          <div className="absolute -top-10 left-[-55px] ">
            <TryFirst />
          </div>
          <Card
            icon={<UserPlus />}
            title="Add new clients"
            description="Start with just a name and email. Additional details can be added whenever you're ready."
            button={
              <ButtonType
                variant="contained"
                text="Client"
                onClick={() => navigate('/patients')}
                icon
                classes="w-full mt-auto"
                specificIcon={<AddIcon className="text-white mr-2" />}
              />
            }
          />
        </div>

        <div className="flex-1">
          <Card
            icon={<CalendarPlus />}
            title="Schedule appointments"
            description="Use our intuitive calendar to schedule appointments, or even a calendar block for some me time."
            button={
              <ButtonType
                variant="contained"
                text="Appointment"
                onClick={() => navigate('/calendar')}
                classes="w-full mt-auto w-[210px]"
                icon
                specificIcon={<AddIcon className="text-white mr-2" />}
              />
            }
          />
        </div>
        <div className="flex-1">
          <Card
            icon={<BookSpells />}
            title="Take secure notes"
            description="Quickly document encounters using our template options: Basic, SOAP, Prenatal, among others."
            button={
              <ButtonType
                variant="contained"
                text="Start with a client"
                onClick={() => navigate('/patients')}
                classes="w-full mt-auto w-[210px]"
              />
            }
          />
        </div>
      </div>

      <div className="flex flex-col items-center justify-center my-10">
        <Typography
          variant="h6"
          text="Support when you need it"
          customClass="mb-2"
        />
        <Typography
          variant="bodySmall"
          text="We’re just an email or text away. For personal, dedicated support, "
          customClass="text-gray"
        />
        <span className="text-gray text-bodySmall font-normal">
          ping us at{' '}
          <a
            className="text-secondary"
            href="mailto:support@astercare.com?subject=Support&body=Hi%20Aster%20Team,"
          >
            support@astercare.com
          </a>
          .
        </span>
      </div>
    </div>
  );
}
