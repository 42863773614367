import BasicTextfield from '../../../components/Textfield';
import MultipleCheckboxes from '../../../components/MultipleCheckboxes';
import RadioOptions from '../../../components/RadioOptions';
import Typography from '../../../components/Typography';
import { Checkbox, FormControlLabel } from '@mui/material';
import { colors } from '../../../theme';
import { GynHisInfo } from '../types/patient-profile';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateField } from '@mui/x-date-pickers/DateField';
import dayjs from 'dayjs';

type GynHisProps = {
  gynHisInfo: GynHisInfo;
  handleInfo: (event: any) => void;
  handleCheck: (event: any, property: string) => void;
  handleCheckboxes: (values: any, property: string) => void;
  handleOption: (property: string, value: any) => void;
  handleDate: (property: string, value: any) => void;
};

type Item = {
  value: string;
  checked: boolean;
};

const GynecologicHistory = (props: GynHisProps) => {
  const {
    hasPeriods,
    arePeriodsRegular,
    averagePeriodLength,
    averagePeriodCycleLength,
    periodUnknown,
    problemsWithPeriods,
    cycleUnknown,
    sexuallyActive,
    sexuallyActiveWith,
    sexualActivityType,
    ageOfFirstSexualActivity,
    sexualPartnersNumber,
    sexualProtectionType,
    lastPapTestDate,
    unknownPap,
    lastPapTestResult,
    papAbnormalityHistoryAndTreatment,
    lastSTIScreeningDate,
    unknownSTI,
    previousInfectionDiseasesOrSTI,
    lastMammogramDate,
    unknownMammogram,
    mammogramAbnormalityHistoryAndTreatment,
  } = props.gynHisInfo;

  const handleInfo = props.handleInfo;
  const handleCheck = props.handleCheck;
  const handleOption = props.handleOption;
  const handleCheckboxes = props.handleCheckboxes;
  const handleDate = props.handleDate;
  const handleCheckboxProblems = (value: string) => {
    const updatedItems = problemsWithPeriods.map((item: Item) =>
      item.value === value ? { ...item, checked: !item.checked } : item
    );
    handleCheckboxes(updatedItems, 'problemsWithPeriods');
  };

  const handleCheckboxActivityChange = (value: string) => {
    const updatedItems = sexualActivityType.map((item: Item) =>
      item.value === value ? { ...item, checked: !item.checked } : item
    );
    handleCheckboxes(updatedItems, 'sexualActivityType');
  };

  const handleCheckboxsexuallyActiveWithChange = (value: string) => {
    const updatedItems = sexuallyActiveWith.map((item: Item) =>
      item.value === value ? { ...item, checked: !item.checked } : item
    );
    handleCheckboxes(updatedItems, 'sexuallyActiveWith');
  };

  const handleCheckboxProtectionChange = (value: string) => {
    const updatedItems = sexualProtectionType.map((item: Item) =>
      item.value === value ? { ...item, checked: !item.checked } : item
    );
    handleCheckboxes(updatedItems, 'sexualProtectionType');
  };

  const handleCheckboxSTIChange = (value: string) => {
    const updatedItems = previousInfectionDiseasesOrSTI.map((item: Item) =>
      item.value === value ? { ...item, checked: !item.checked } : item
    );
    handleCheckboxes(updatedItems, 'previousInfectionDiseasesOrSTI');
  };

  return (
    <div className="container w-fit overflow-auto pb-10 ">
      <div className="grid grid-cols-1 overflow-auto">
        <div>
          <Typography
            text="Gynecologic History"
            variant="h5"
            customClass="font-semibold mb-5"
          ></Typography>

          <RadioOptions
            classes="my-4"
            value={hasPeriods}
            label="Have you had a period?"
            handleChange={(event: any) =>
              handleOption('hasPeriods', event.target.value === 'true')
            }
            options={[
              {
                value: true,
                text: 'Yes',
                key: 'yes',
              },
              { value: false, text: 'Never had a period', key: 'no' },
            ]}
          />

          {hasPeriods && (
            <div className="flex flex-col">
              <RadioOptions
                classes="mb-5"
                value={arePeriodsRegular}
                label="Are your periods regular?"
                handleChange={(event: any) =>
                  handleOption(
                    'arePeriodsRegular',
                    event.target.value === 'true'
                  )
                }
                options={[
                  {
                    value: true,
                    text: 'Yes',
                    key: 'yes',
                  },
                  { value: false, text: 'No', key: 'no' },
                ]}
              />
              <Typography
                text="Avarage length of periods"
                variant="body"
                customClass="text-gray font-semibold"
              />
              <div className="flex flex-row gap-20">
                <BasicTextfield
                  disabled={periodUnknown}
                  id="averagePeriodLength"
                  variant="filled"
                  label="Days"
                  type="number"
                  name="averagePeriodLength"
                  value={averagePeriodLength}
                  onChange={(event: any) => handleInfo(event)}
                  classes={`mt-1 mb-4 w-[150px]`}
                />
                <FormControlLabel
                  className="h-[24px] mt-4"
                  key="periodUnknown"
                  control={
                    <Checkbox
                      sx={{
                        color: colors.grayLight,
                        '&.Mui-checked': {
                          color: colors.green,
                        },
                      }}
                      checked={periodUnknown}
                      onChange={(event: any) =>
                        handleCheck(event, 'periodUnknown')
                      }
                      disableRipple
                    />
                  }
                  label="Unsure"
                />
              </div>
              <Typography
                text="Avarage cycle length (every x days)"
                variant="body"
                customClass="text-gray font-semibold"
              />
              <div className="flex flex-row gap-20">
                <BasicTextfield
                  id="averagePeriodCycleLength"
                  variant="filled"
                  label="Days"
                  name="averagePeriodCycleLength"
                  value={averagePeriodCycleLength}
                  disabled={cycleUnknown}
                  type="number"
                  onChange={(event: any) => handleInfo(event)}
                  classes={`mt-1 mb-4 w-[150px]`}
                />
                <FormControlLabel
                  className="h-[24px] mt-4"
                  key="cycleUnknown"
                  control={
                    <Checkbox
                      sx={{
                        color: colors.grayLight,
                        '&.Mui-checked': {
                          color: colors.green,
                        },
                      }}
                      checked={cycleUnknown}
                      onChange={(event: any) =>
                        handleCheck(event, 'cycleUnknown')
                      }
                      disableRipple
                    />
                  }
                  label="Unsure"
                />
              </div>
              <Typography
                text="Are there any problems with your periods?"
                variant="body"
                customClass="text-gray font-semibold"
              />
              <div className="flex flex-row">
                <MultipleCheckboxes
                  handleCheckboxChange={handleCheckboxProblems}
                  items={problemsWithPeriods}
                  separateInHalf
                />
              </div>
            </div>
          )}
        </div>
        <div className="">
          <RadioOptions
            classes="my-4"
            value={sexuallyActive}
            label="Are you currently sexually active?"
            handleChange={(event: any) =>
              handleOption('sexuallyActive', event.target.value)
            }
            options={[
              {
                value: 'yes',
                text: 'Yes',
                key: 'yes',
              },
              { value: 'no', text: 'No', key: 'no' },
              { value: 'never', text: 'Never been', key: 'never' },
            ]}
          />

          {sexuallyActive !== null && sexuallyActive !== 'never' && (
            <div className="flex flex-col w-fit mb-4">
              <Typography
                text="What types of sexual activity do you engage in?"
                variant="body"
                customClass="text-gray font-semibold"
              />
              <div className="flex flex-row ">
                <MultipleCheckboxes
                  handleCheckboxChange={handleCheckboxActivityChange}
                  items={sexualActivityType}
                  separateInHalf
                />
              </div>
              <Typography
                text="Do you currently use any contraceptives and/or protection?"
                variant="body"
                customClass="text-gray font-semibold"
              />
              <div className="flex flex-row">
                <MultipleCheckboxes
                  handleCheckboxChange={handleCheckboxProtectionChange}
                  items={sexualProtectionType}
                  separateInHalf
                />
              </div>
            </div>
          )}
        </div>

        <Typography
          text="Have you had any Pap Smears? If yes, please provide your best estimated date."
          variant="body"
          customClass="text-gray font-semibold mt-4"
        />
        <RadioOptions
          classes="mt-[-15px] mb-4"
          value={unknownPap}
          label=""
          handleChange={(event: any) =>
            handleOption('unknownPap', event.target.value)
          }
          options={[
            {
              value: 'yes',
              text: 'Yes',
              key: 'yes',
            },
            { value: 'never', text: 'Never Had', key: 'never' },
            {
              value: 'unsure',
              text: 'Unsure',
              key: 'unsure',
            },
          ]}
        />
        {unknownPap === 'yes' && (
          <div>
            <div className="flex flex-row items-center gap-5 mb-4">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateField
                  label="Last Pap Date"
                  maxDate={dayjs()}
                  format="MM/YYYY"
                  value={lastPapTestDate && dayjs(lastPapTestDate)}
                  sx={{
                    backgroundColor: 'white',
                    borderRadius: 1,
                  }}
                  onChange={(event: any) => {
                    handleDate('lastPapTestDate', event);
                  }}
                />
              </LocalizationProvider>
            </div>
            <BasicTextfield
              id="lastPapTestResult"
              variant="filled"
              label="Result"
              name="lastPapTestResult"
              value={lastPapTestResult ?? ''}
              onChange={(event: any) => handleInfo(event)}
              classes={`mt-1 mb-4 w-[400px]`}
            />
            <Typography
              text="Do you have history and treatment for any previous abnormal Pap Smears?"
              variant="body"
              customClass="text-gray font-semibold"
            />
            <BasicTextfield
              id="papAbnormalityHistoryAndTreatment"
              variant="filled"
              label="History and treatment"
              name="papAbnormalityHistoryAndTreatment"
              value={papAbnormalityHistoryAndTreatment ?? ''}
              onChange={(event: any) => handleInfo(event)}
              classes={`mt-1 mb-4 w-[400px]`}
            />
          </div>
        )}

        <div>
          <Typography
            text="Have you had any STI screening? If yes, please provide your best estimated date."
            variant="body"
            customClass="text-gray font-semibold mt-4"
          />
          <RadioOptions
            classes="mt-[-20px] mb-4"
            value={unknownSTI}
            label=""
            handleChange={(event: any) =>
              handleOption('unknownSTI', event.target.value)
            }
            options={[
              {
                value: 'yes',
                text: 'Yes',
                key: 'yes',
              },
              { value: 'never', text: 'Never Had', key: 'never' },
              {
                value: 'unsure',
                text: 'Unsure',
                key: 'unsure',
              },
            ]}
          />
          {unknownSTI === 'yes' && (
            <>
              <div className="flex flex-row items-center gap-5 mb-4">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateField
                    label="Last STI Screening Date"
                    format="MM/YYYY"
                    value={lastSTIScreeningDate && dayjs(lastSTIScreeningDate)}
                    maxDate={dayjs()}
                    sx={{
                      backgroundColor: 'white',
                      borderRadius: 1,
                    }}
                    onChange={(event: any) => {
                      handleDate('lastSTIScreeningDate', event);
                    }}
                  />
                </LocalizationProvider>
              </div>
              <Typography
                text="Do you have any history of infectious diseases or STI?"
                variant="body"
                customClass="text-gray font-semibold"
              />
              <div className="flex flex-row mb-4">
                <MultipleCheckboxes
                  handleCheckboxChange={handleCheckboxSTIChange}
                  items={previousInfectionDiseasesOrSTI}
                  separateInHalf
                />
              </div>
            </>
          )}
        </div>

        <div>
          <Typography
            text="Have you had any mammogram? If yes, please provide your best estimated date."
            variant="body"
            customClass="text-gray font-semibold"
          />
          <RadioOptions
            classes="mt-[-20px]"
            value={unknownMammogram}
            label=""
            handleChange={(event: any) =>
              handleOption('unknownMammogram', event.target.value)
            }
            options={[
              { value: 'yes', text: 'Yes', key: 'yes' },
              { value: 'never', text: 'Never Had', key: 'never' },
              {
                value: 'unsure',
                text: 'Unsure',
                key: 'unsure',
              },
            ]}
          />
          {unknownMammogram === 'yes' && (
            <>
              <div className="flex flex-row items-center gap-5 my-4">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateField
                    label="Date"
                    format="MM/YYYY"
                    value={lastMammogramDate && dayjs(lastMammogramDate)}
                    maxDate={dayjs()}
                    sx={{
                      backgroundColor: 'white',
                      borderRadius: 1,
                    }}
                    onChange={(event: any) => {
                      handleDate('lastMammogramDate', event);
                    }}
                  />
                </LocalizationProvider>
              </div>
              <Typography
                text="Do you have any history and follow-ups for any abnormality with mammograms?"
                variant="body"
                customClass="text-gray font-semibold"
              />
              <BasicTextfield
                id="mammogramAbnormalityHistoryAndTreatment"
                variant="filled"
                label="History and treatment"
                name="mammogramAbnormalityHistoryAndTreatment"
                value={mammogramAbnormalityHistoryAndTreatment ?? ''}
                onChange={(event: any) => handleInfo(event)}
                classes={`mt-1 mb-4 w-[400px]`}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default GynecologicHistory;
