import { Divider, Menu, MenuItem } from '@mui/material';
import { signOut } from 'aws-amplify/auth';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import Clients from '../assets/icons/Clients';
import SignOut from '../assets/icons/SignOut';
import Typography from '../components/Typography';
import CircleWithInitials from './CircleWIthInitials';
import { useAuth } from '../authentication/AuthProvider';

type ListOptionProps = {
  text: string;
  link: string;
  icon?: () => JSX.Element;
  onClick?: () => void;
};

const Header = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: any) => {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { profile, logout } = useAuth();

  const MenuOption = (item: ListOptionProps) => {
    return (
      <Link to={item.link}>
        <MenuItem className="p-4">
          {item.icon && (
            <div className="mr-4">
              <item.icon />
            </div>
          )}
          <Typography variant="bodySmall" text={item.text} />
        </MenuItem>
      </Link>
    );
  };

  const SignOutMenuItem = () => (
    <MenuItem
      onClick={() => {
        signOut()
          .then(() => {
            logout();
          })
          .catch((err) => console.log(err));
      }}
      className="p-4 cursor-pointer"
    >
      <div className="mr-4">
        <SignOut />
      </div>
      <Typography variant="bodySmall" text="Sign out" />
    </MenuItem>
  );

  if (profile) {
    return (
      <div className="w-full h-[40px] flex flex-col justify-center items-end py-8 px-5 border-b-[1px] border-b-grayLight">
        <div className="flex flex-row justify-center">
          <div className="flex flex-col justify-center px-4">
            <a target="_blank" href={'https://help.astercare.com/'}>
              <Typography
                variant="bodySmall"
                text={`Help Center`}
                customClass="font-semibold"
              />
            </a>
          </div>
          <div className="flex flex-col justify-center pr-4 pl-4 border-l-[1px] border-l-grayLight pl-2">
            <a
              href={`mailto:hello@astercare.com?subject=Aster%20Support&body=Hi%20Aster%20Team,`}
            >
              <Typography
                variant="bodySmall"
                text={`Email support`}
                customClass="font-semibold"
              />
            </a>
          </div>
          <div
            className="flex flex-row cursor-pointer border-l-[1px] border-l-grayLight pl-2"
            onMouseDown={handleClick}
          >
            <div className="flex flex-col justify-center">
              <CircleWithInitials
                customClass="mx-2"
                width={30}
                height={30}
                textVariant={'bodySmall'}
                name={`${profile?.firstName} ${profile?.lastName}`}
              />
            </div>
            <div className="flex flex-col justify-center">
              <Typography
                variant="bodySmall"
                text={`${profile?.firstName} ${profile?.lastName}`}
                customClass="font-semibold"
              />
              <Typography
                variant="bodySmall"
                text={`${profile?.practiceName}`}
                customClass="text-gray"
              />
            </div>
            <div className="flex flex-col justify-center">
              <i className="fa fa-angle-down ml-2 text-gray"></i>
            </div>
          </div>
        </div>
        <Menu
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          className="shadow-md rounded-md mt-4"
          MenuListProps={{
            onMouseLeave: handleClose,
          }}
        >
          <MenuOption text="Manage members" link="/members" icon={Clients} />
          <Divider />
          <SignOutMenuItem />
        </Menu>
      </div>
    );
  }
  return (
    <div className="w-full h-[40px] py-10 flex flex-col justify-center items-center"></div>
  );
};

export default Header;
