import React, { useState } from 'react';
import { ChildInfo } from '../types/patient-profile';
import Typography from '../../../components/Typography';
import { Tabs, TextField } from '@mui/material';
import RadioYN from '../../../components/RadioYN';
import RadioOptions from '../../../components/RadioOptions';
import { StyledTab } from '../../../components/StyledTab';

interface ChildrenInfoProps {
  childrenInfo: ChildInfo[];
  onChildrenInfoChange: (newChildInfo: ChildInfo[]) => void;
}

const ChildInfoTab: React.FC<ChildrenInfoProps> = ({
  childrenInfo,
  onChildrenInfoChange,
}) => {
  const handleChildInfoChange = (index: number, newChildInfo: ChildInfo) => {
    const newChildrenInfoArray = [...childrenInfo];
    newChildrenInfoArray[index] = newChildInfo;
    onChildrenInfoChange(newChildrenInfoArray);
  };

  const [activeTab, setActiveTab] = useState<number>(0);
  const handleTabChange = (_: any, newValue: number) => {
    setActiveTab(newValue);
  };

  const [numTabs, setNumTabs] = useState<number>(childrenInfo?.length ?? 0);

  const handleNumTabsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = parseInt(event.target.value);
    value = isNaN(value) || value < 0 ? 0 : value;
    if (value < (childrenInfo?.length ?? 0)) {
      const updatedInputs = [...childrenInfo];
      updatedInputs.splice(value);
      onChildrenInfoChange(updatedInputs);
      setActiveTab(value > 0 ? value - 1 : 0);
    } else if (value > (childrenInfo?.length ?? 0)) {
      const updatedInputs = [
        ...childrenInfo,
        ...Array.from(
          { length: value - (childrenInfo?.length ?? 0) },
          () =>
            ({
              isLiving: true,
              name: '',
              sex: 'female',
            } as ChildInfo)
        ),
      ];
      onChildrenInfoChange(updatedInputs);
    }

    setNumTabs(value);
  };

  return (
    <section className="px-3 mt-4 flex flex-col bg-[#E0E7E7] rounded-[10px] py-2 pb-4">
      <Typography
        variant="h6"
        text="Children in this pregnancy"
        customClass="mb-2"
      />

      <Typography
        text="How many children were part of this pregnancy?"
        variant="body"
        customClass="text-gray font-semibold mb-5"
      />
      <TextField
        label="Number of children"
        value={numTabs}
        type="number"
        onChange={handleNumTabsChange}
        className="mb-4 w-fit"
      />

      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        indicatorColor="primary"
        variant="scrollable"
        allowScrollButtonsMobile
        className="max-w-full"
      >
        {Array.from({ length: childrenInfo?.length ?? 0 }, (_, index) => (
          <StyledTab key={index} label={`Child ${index + 1}`} />
        ))}
      </Tabs>

      {childrenInfo?.length > 0 ? (
        <div className="my-2 ml-2 mt-4">
          <div className="mb-5">
            <RadioYN
              hide
              value={childrenInfo[activeTab].isLiving}
              field={`childLiving-${activeTab}`}
              question="Is this child currently living?"
              handleOption={(event: any) =>
                handleChildInfoChange(activeTab, {
                  ...childrenInfo[activeTab],
                  isLiving: event.target.value === 'true',
                })
              }
            />
          </div>

          {childrenInfo[activeTab].isLiving && (
            <div className="flex flex-col">
              <div className="mb-5">
                <Typography
                  text="Child's name:"
                  variant="body"
                  customClass="text-gray mb-2 font-semibold"
                />
                <TextField
                  id={`name-${activeTab}`}
                  label="Name"
                  value={childrenInfo[activeTab].name}
                  onChange={(e) =>
                    handleChildInfoChange(activeTab, {
                      ...childrenInfo[activeTab],
                      name: e.target.value,
                    })
                  }
                />
              </div>
              <div className="mb-5">
                <RadioOptions
                  label="Child's sex"
                  value={childrenInfo[activeTab].sex}
                  handleChange={(event: any) =>
                    handleChildInfoChange(activeTab, {
                      ...childrenInfo[activeTab],
                      sex: event.target.value,
                    })
                  }
                  options={[
                    { value: 'female', text: 'Female', key: 'female' },
                    { value: 'male', text: 'Male', key: 'male' },
                    { value: 'other', text: 'Other', key: 'other' },
                  ]}
                />
              </div>
            </div>
          )}
        </div>
      ) : null}
    </section>
  );
};

export default ChildInfoTab;
