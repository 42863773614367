import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import ButtonType from './Button';

type ModalProps = {
  title: string;
  description: string;
  confirm: string;
  dismiss: string;
  open: boolean;
  loading?: boolean;
  handleConfirm: any;
  handleCancel?: () => void;
  handleClose: () => void;
};

const Modal = (props: ModalProps) => {
  const {
    open,
    title,
    description,
    confirm,
    dismiss,
    loading,
    handleClose,
    handleConfirm,
    handleCancel,
  } = props;

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <div className="flex flex-col items-center">
        <DialogContent className="flex flex-col items-center">
          <p className="text-h4 font-semibold">{title}</p>
          <p className="mt-1">{description}</p>
        </DialogContent>
      </div>
      <DialogActions
        className="
          bg-grayBackground
          "
      >
        <ButtonType
          variant="text"
          onClick={handleCancel}
          text={dismiss}
          className="w-[120px] h-[44px]"
        />
        <ButtonType
          loading={loading}
          variant="contained"
          onClick={handleConfirm}
          text={confirm}
          className="w-[100px] h-[44px]"
        />
      </DialogActions>
    </Dialog>
  );
};

export default Modal;
