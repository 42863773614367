import { useSnackbar } from '../../../components/Snack';
import axios from '../../../app/axiosConfig';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CreatePatientDTO } from '@aster/shared/dtos/patient';

export const useUpdatePatientMutation = (patientID: string) => {
  const { showMessage } = useSnackbar();
  const queryClient = useQueryClient();

  const updatePatient = async (newForm: CreatePatientDTO) =>
    axios.put(`patients/${patientID}`, newForm);

  const updatePatientMutation = useMutation({
    mutationKey: ['updatePatient'],
    mutationFn: updatePatient,
    onSuccess: () => {
      showMessage({
        message: 'Patient updated successfully',
        type: 'success',
      });
      queryClient.invalidateQueries({ queryKey: ['patient', patientID] });
      queryClient.invalidateQueries({
        queryKey: ['patientForLabs', patientID],
      });
      queryClient.invalidateQueries({
        queryKey: ['isPatientValidForLabs', patientID],
      });
    },
    onError: () => {
      showMessage({
        message: 'An error occurred while updating patient. Please try again.',
        type: 'error',
      });
    },
  });

  return { updatePatientMutation };
};
