import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '../../../components/Typography';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import {
  LabsOrderDetailsDTO,
  TryVitalResult,
  TryVitalResultEntry,
} from '@aster/shared/dtos/labs';
import dayjs from 'dayjs';
import axios from '../../../app/axiosConfig';
import { useMutation, useQuery } from '@tanstack/react-query';
import { CircularProgress } from '@mui/material';
import { customTwMerge as twMerge } from '../../../utils';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import ButtonType from '../../../components/Button';
import CircleWIthInitials from '../../../components/CircleWIthInitials';
import Tag from '../../../components/Tag';
import StyledDataGrid from '../../../components/StyledDataGrid';
import { resultSeverityClassColor } from '../../notes/sections/utils';

export type LabOrderDetailsProps = {
  labOrderID?: string | null;
};

export const LabOrderDetails = ({ labOrderID }: LabOrderDetailsProps) => {
  const fetchLabOrderDetails = async () => {
    const response = await axios.get<LabsOrderDetailsDTO>(
      `/v2/labs/orders/${labOrderID}`
    );
    return response.data;
  };

  const { data: labOrder, isLoading } = useQuery({
    queryKey: ['labOrders', labOrderID],
    queryFn: fetchLabOrderDetails,
    enabled: labOrderID != undefined,
  });

  if (isLoading)
    return (
      <div className="grid place-content-center h-40">
        <CircularProgress />
      </div>
    );

  if (!labOrderID || !labOrder) return false;

  const { results, createdAt, labTest, orderedBy, status, priority } = labOrder;

  return (
    <article className="flex flex-col gap-4">
      <header className="flex flex-col">
        <div className="flex justify-between">
          <Typography variant="h1" text={labTest.name} />
          <div className="flex flex-col">
            <div className="flex gap-1 items-center justify-end mb-auto">
              <CircleWIthInitials
                name={orderedBy}
                height={20}
                width={20}
                textVariant="meta"
              />
              <Typography
                customClass="font-semibold"
                variant="bodySmall"
                text={orderedBy}
              />
            </div>
            <div className="flex gap-2">
              {priority && (
                <Tag tagText="Priority" color="gray" textColor="white"></Tag>
              )}
              <Tag
                tagText="Ordered"
                rightText={dayjs(createdAt).format('MM/DD/YYYY')}
              ></Tag>
            </div>
          </div>
        </div>
        <div className="flex mt-2 justify-between">
          <Tag
            tagText={status}
            className="capitalize"
            textColor="white"
            color="gray"
          ></Tag>
          <div className="flex gap-2">
            <Tag
              tagText="Collected"
              rightText={
                results?.metadata.date_collected
                  ? dayjs(results.metadata.date_collected).format('MM/DD/YYYY')
                  : '-'
              }
            ></Tag>
            <Tag
              tagText="Received"
              rightText={
                results?.metadata.date_received
                  ? dayjs(results.metadata.date_received).format('MM/DD/YYYY')
                  : '-'
              }
            ></Tag>
            <Tag
              tagText="Reported"
              rightText={
                results?.metadata.date_reported
                  ? dayjs(results.metadata.date_reported).format('MM/DD/YYYY')
                  : '-'
              }
            ></Tag>
          </div>
        </div>
      </header>

      <Typography
        variant="body"
        customClass="capitalize p-2 w-full text-center bg-neutral-100 rounded-lg"
        text={results?.metadata.interpretation ?? '-'}
      />

      {results ? (
        <LabResults results={results}></LabResults>
      ) : (
        <Typography
          text="The results have not arrived yet. Please try later."
          variant="body"
          customClass="mt-4"
        />
      )}
    </article>
  );
};

const LabResults = ({ results }: { results: TryVitalResult | null }) => {
  if (!results) return false;

  const isOutOfRange = (resultEntry: TryVitalResultEntry) =>
    resultEntry.is_above_max_range ||
    resultEntry.is_above_max_range ||
    resultEntry.interpretation === 'abnormal' ||
    resultEntry.interpretation === 'critical';

  const columns: GridColDef[] = [
    {
      field: 'interpretation',
      headerClassName: 'bg-grayBackground',
      renderHeader: () => (
        <div className="flex justify-center items-center rounded-full bg-asterGray opacity-75 h-5 w-5">
          <PriorityHighIcon className="text-white text-bodySmall" />
        </div>
      ),
      width: 70,
      renderCell: (params: GridCellParams) => {
        const result = params.row as TryVitalResultEntry;
        return (
          isOutOfRange(result) && (
            <div
              className={twMerge(
                'rounded-full h-4 w-4 text-white grid place-content-center',
                resultSeverityClassColor(result.interpretation ?? '', 'bg')
              )}
            >
              <p className="text-bodySmall">
                {result.is_above_max_range ? 'H' : 'L'}
              </p>
            </div>
          )
        );
      },
    },
    {
      field: 'name',
      headerClassName: 'bg-grayBackground text-bodySmall',
      headerName: 'Variable',
      flex: 1,
    },
    {
      field: 'result',
      headerClassName: 'bg-grayBackground text-bodySmall',
      headerName: 'Result',
      flex: 1,
      renderCell: (params: GridCellParams) => {
        const result = params.row as TryVitalResultEntry;
        return (
          <div className="flex items-center gap-2">
            <Typography
              variant="bodySmall"
              customClass={`font-semibold ${resultSeverityClassColor(
                result.interpretation ?? ''
              )}`}
              text={
                result.type === 'comment'
                  ? result.result
                  : `${result.result} ${result.unit}`
              }
            />
          </div>
        );
      },
    },
    {
      field: 'range',
      headerName: 'Ref. Range',
      headerClassName: 'bg-grayBackground text-bodySmall',
      width: 100,
      renderCell: (params: GridCellParams) => {
        const result = params.row as TryVitalResultEntry;
        return (
          <Typography
            customClass="justify-self-end font-semibold"
            variant="bodySmall"
            text={`${result.min_range_value} - ${result.max_range_value}`}
          />
        );
      },
    },
    {
      field: 'notes',
      headerClassName: 'bg-grayBackground text-bodySmall',
      headerName: 'Notes',
      flex: 1.5,
      renderCell: (params: GridCellParams) => {
        const result = params.row as TryVitalResultEntry;
        return (
          <Typography
            customClass="justify-self-end font-semibold"
            variant="bodySmall"
            text={result.notes ?? '-'}
          />
        );
      },
    },
  ];

  return (
    <StyledDataGrid
      disableColumnFilter
      disableDensitySelector
      disableColumnSelector
      disableColumnMenu
      getRowClassName={(params) =>
        twMerge(
          'text-bodySmall',
          resultSeverityClassColor(params.row.interpretation ?? '')
        )
      }
      className="col-span-2"
      hideFooterPagination={true}
      getRowId={(row) => row.name}
      rows={results.results}
      columns={columns}
      disableRowSelectionOnClick
    />
  );
};

export const LabOrderDetailsDialog = (
  props: DialogProps & LabOrderDetailsProps & { handleClose: () => void }
) => {
  const downloadPDF = async () => {
    const response = await axios.get(
      `/v2/labs/orders/${props.labOrderID}/results/pdf`,
      {
        responseType: 'blob',
        headers: {
          accept: 'application/pdf',
        },
      }
    );
    return response.data;
  };

  const { mutate, status } = useMutation({
    mutationFn: downloadPDF,
    onSuccess: (data) => {
      const url = window.URL.createObjectURL(data);
      window.open(url, '_blank');
    },
  });

  return (
    <Dialog maxWidth="md" {...props}>
      <DialogContent>
        <LabOrderDetails {...props}></LabOrderDetails>
      </DialogContent>
      <DialogActions>
        <ButtonType variant="text" text="Close" onClick={props.handleClose} />
        <ButtonType
          loading={status === 'pending'}
          onClick={() => mutate()}
          variant="contained"
          text="Download PDF"
        />
      </DialogActions>
    </Dialog>
  );
};
