import React, { useState } from 'react';
import { GridColDef } from '@mui/x-data-grid';
import { Button } from '@mui/material';
import { colors } from '../../../theme';
import BasicCheckbox from '../../../components/Checkbox';
import CollapsibleComponent from '../../../components/CollapsibleComponent';
import StyledDataGrid from '../../../components/StyledDataGrid';
import BasicTextfield from '../../../components/Textfield';
import Typography from '../../..//components/Typography';
import ButtonType from '../../../components/Button';
import { checkboxFHTData, heartRateLabels } from '../constants';
import dayjs from 'dayjs';
import { FHTInfo } from './types/birth.types';

type FHTProps = {
  data: FHTInfo[];
  disabled: boolean;
  handleNote: (note: string) => void;
  handleSave: (property: string, event: FHTInfo[]) => void;
};

type HeartRateButtonProps = {
  label: string;
};

const FHT = ({ data, disabled, handleSave, handleNote }: FHTProps) => {
  const [accels, setAccels] = useState('');
  const [stage, setStage] = useState('');
  const [when, setWhen] = useState('');
  const [number, setNumber] = useState('');
  const rows = data || [];

  const createNote = () => {
    const note = `FHT: ${accels}, ${stage ? `${stage},` : ''} ${when}, ${
      number ? `${number} beats per minute` : ''
    } at ${dayjs(new Date()).format('HH:mm:ss')}.`;
    handleNote(note);
  };

  const save = () => {
    const newResult: FHTInfo = {
      id: rows.length + 1,
      accels,
      stage,
      when,
      number,
      time: dayjs(new Date()).format('HH:mm:ss'),
    };
    handleSave('fht', [...rows, newResult]);
    createNote();
    setAccels('');
    setStage('');
    setWhen('');
    setNumber('');
  };

  const columns: GridColDef<(typeof rows)[number]>[] = [
    {
      field: 'accels',
      headerName: 'Auscultation',
      headerAlign: 'left',
      flex: 1.2,
      headerClassName: 'bg-grayBackground',
      sortable: false,
    },
    {
      field: 'stage',
      headerName: 'State',
      headerAlign: 'left',
      flex: 1.2,
      headerClassName: 'bg-grayBackground',
      sortable: false,
    },
    {
      field: 'when',
      headerName: 'When',
      type: 'number',
      headerAlign: 'left',
      flex: 1.2,
      headerClassName: 'bg-grayBackground',
      sortable: false,
    },
    {
      field: 'number',
      headerName: 'FHT',
      headerAlign: 'left',
      flex: 1,
      headerClassName: 'bg-grayBackground',
      sortable: false,
    },
    {
      field: 'time',
      headerName: 'Hour',
      headerAlign: 'left',
      flex: 1.2,
      headerClassName: 'bg-grayBackground',
      sortable: false,
    },
  ];

  const HeartRateButton = ({ label }: HeartRateButtonProps) => (
    <Button
      className={`justify-center px-2.5 py-1.5 rounded-md border border-solid border-stone-200 w-[55px] lowercase ${
        label === number ? 'bg-primary text-white' : 'bg-white'
      }`}
      onClick={() => setNumber(label)}
    >
      {label}
    </Button>
  );

  return (
    <CollapsibleComponent label="FHT auscultation">
      <div className="flex flex-col self-stretch px-2.5 pb-2 rounded-b-lg bg-grayCard w-full xl:max-w-[450px]">
        {!disabled && (
          <>
            <div className="flex-1 ml-2">
              {checkboxFHTData.decel.map((item, index) => (
                <BasicCheckbox
                  key={index}
                  onChange={() => setAccels(item.label)}
                  label={item.label}
                  checked={item.label === accels}
                />
              ))}
            </div>
            {accels !== 'No decels' && (
              <div className="flex-1 ml-2">
                {checkboxFHTData.timing.map((item, index) => (
                  <BasicCheckbox
                    key={index}
                    onChange={() => setStage(item.label)}
                    label={item.label}
                    checked={item.label === stage}
                  />
                ))}
              </div>
            )}
            <div className="flex-1 ml-2">
              {checkboxFHTData.uc.map((item, index) => (
                <BasicCheckbox
                  key={index}
                  onChange={() => setWhen(item.label)}
                  label={item.label}
                  checked={item.label === when}
                />
              ))}
            </div>
            <Typography
              variant="bodySmall"
              text="Choose from the following options or enter the exact BPM"
              customClass="m-3 text-gray "
            />
            <div className="flex flex-row w-full">
              <Typography
                variant="body"
                text="Beats per Minute:"
                customClass="m-3 text-gray w-[60%] "
              />
              <BasicTextfield
                id="bpm"
                variant="filled"
                name="number"
                label="bpm"
                value={number}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setNumber(event.target.value);
                }}
                classes="w-[200px] mx-1"
              />
            </div>

            <div className="flex flex-col flex-wrap overflow-auto justify-center content-start px-2 text-sm font-semibold leading-5 text-center whitespace-nowrap pb-2">
              {Array.from({ length: 3 }).map((_, rowIndex) => (
                <div key={rowIndex} className="flex gap-2.5 mt-2.5">
                  {heartRateLabels
                    .slice(rowIndex * 5, rowIndex * 5 + 5)
                    .map((label, colIndex) => (
                      <HeartRateButton key={colIndex} label={label} />
                    ))}
                </div>
              ))}
            </div>
            <ButtonType
              variant="contained"
              onClick={save}
              text="Add"
              classes={'max-h-[30px] my-2'}
            />
          </>
        )}
        <StyledDataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          pageSizeOptions={[5]}
          disableRowSelectionOnClick
          autoHeight
          slots={{
            noRowsOverlay: () => (
              <div className="flex justify-center items-center h-full">
                No FHT information yet.
              </div>
            ),
          }}
          sx={{
            backgroundColor: colors.white,
            overflow: 'auto',
            fontSize: '12px',
            textAlign: 'center',
          }}
        />
      </div>
    </CollapsibleComponent>
  );
};

export default FHT;
