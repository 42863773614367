import React from 'react';
import messages from '../assets/empty-images/messages.webp';

const Messages = () => {
  return (
    <div className="flex flex-col justify-center items-center h-full w-full">
      <img src={messages} alt="Messages" width="481px" />
      <div className="flex flex-col items-center">
        <p className="text-h3 text-primary">Messages will be available soon</p>
        <p className="text-body text-gray ">
          Send and receive messages from patients and colleagues
        </p>
      </div>
    </div>
  );
};

export default Messages;
