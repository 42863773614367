import BasicTextfield from '../../../components/Textfield';
import Typography from '../../../components/Typography';
import SelectDropdown from '../../../components/SelectDropdown';
import { RelationshipInfo } from '../types/patient-profile';

type ContactInfoProps = {
  relationship: RelationshipInfo;
  handleInfo: (event: any, property: string) => void;
};

const Relationship = (props: ContactInfoProps) => {
  const { relationshipStatus, partnerName } = props.relationship;
  const handleInfo = props.handleInfo;

  return (
    <div className="flex justify-center md:justify-start">
      <div>
        <Typography
          text="Relationship"
          variant="h5"
          customClass="font-semibold mb-5"
        ></Typography>
        <div className="flex mb-5">
          <SelectDropdown
            label="Relationship status"
            options={[
              { value: 'single', text: 'Single' },
              { value: 'married', text: 'Married' },
              { value: 'divorced', text: 'Divorced' },
              { value: 'widowed', text: 'Widowed' },
              { value: 'partnered', text: 'Partnered' },
              { value: 'other', text: 'Other' },
            ]}
            value={relationshipStatus}
            width="300px"
            handleChange={(event: any) =>
              handleInfo(event, 'relationshipStatus')
            }
          />
        </div>
        {relationshipStatus !== 'single' ? (
          <BasicTextfield
            id="partnersname"
            variant="filled"
            label="Partner's name"
            name="partnerName"
            value={partnerName}
            onChange={(event: any) => handleInfo(event, 'partnerName')}
            classes={`mt-4 w-[300px]`}
          />
        ) : null}
      </div>
    </div>
  );
};

export default Relationship;
