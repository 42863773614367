import axios from '../../app/axiosConfig';
import Typography from '../../components/Typography';
import React, { useMemo, useState } from 'react';
import ButtonType from '../../components/Button';
import {
  GridColDef,
  GridRowSelectionModel,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import UploadDocumentModal from './UploadDocumentModal';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { CircularProgress, Snackbar } from '@mui/material';
import { createId } from '@paralleldrive/cuid2';
import { colors } from '../../theme';
import { PutObjectCommand } from '@aws-sdk/client-s3';
import { getS3ClientWithCredentials } from './utils/get-s3-client-with-credentials';
import { DocumentIcon } from './DocumentIcon';
import { downloadFile } from './utils/download-file';
import { NoRowsOverlay } from './NoRowsOverlay';
import StyledDataGrid from '../../components/StyledDataGrid';
import ShareDocumentsModal from './ShareDocumentsModal';
import { Delete, Download } from '@mui/icons-material';
import { deleteFiles, fetchPracticeFiles } from './utils/requests';
import DeleteDocumentsModal from './DeleteDocumentsModal';
import { useAuth } from '../../authentication/AuthProvider';

const PracticeDocuments = () => {
  const [isUploading, setIsUploading] = useState(false);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [openSharingModal, setOpenSharingModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [rowSelectionModel, setRowSelectionModel] =
    React.useState<GridRowSelectionModel>([]);

  const queryClient = useQueryClient();
  const { profile } = useAuth();

  const sendEmailWithDocuments = async (patientId: string, email: string) => {
    setIsUploading(true);
    const s3Keys = selectedDocuments.map((doc) => doc.s3key);
    const response = await axios.post(`/documents/${patientId}/send`, {
      documentS3Keys: s3Keys,
    });
    if (response.status === 200) {
      setSnackBarMessage(`Files sent to ${email}`);
      setOpenSnackbar(true);
    } else {
      setSnackBarMessage(`Failed to send files to ${email}`);
      setOpenSnackbar(true);
    }
    setIsUploading(false);
    return response;
  };

  const downloadFilesBulk = () => {
    selectedDocuments.forEach((doc) => {
      downloadFile(doc.s3key, doc.name);
    });
  };

  const postFiles = async (files: File[]) => {
    setIsUploading(true);
    const s3 = await getS3ClientWithCredentials();
    if (s3) {
      try {
        for (const file of files) {
          const s3key = `${profile ? profile.practiceId : ''}-${createId()}`;
          const s3object = await s3.send(
            new PutObjectCommand({
              Bucket: import.meta.env.VITE_DOCUMENTS_BUCKET,
              Key: s3key,
              Body: file,
              ContentType: file.type,
            })
          );
          if (s3object.$metadata.httpStatusCode === 200) {
            await axios.post('/documents/', {
              s3key,
              fileName: file.name,
              folderID: null,
            });
          }
        }
      } catch (error) {
        setSnackBarMessage('Failed to upload file');
        setOpenSnackbar(true);
      }
    } else {
      setSnackBarMessage('Permission required to upload files');
      setOpenSnackbar(true);
    }
    setIsUploading(false);
  };

  const addFileMutation = useMutation({
    mutationKey: ['addFile'],
    mutationFn: postFiles,
    onMutate: async (files) => {
      setSnackBarMessage(
        `Uploading ${files.length} file${files.length > 1 ? 's' : ''}...`
      );
      setOpenSnackbar(true);
      const previousFiles = queryClient.getQueryData(['practiceFiles']);
      queryClient.setQueryData(['practiceFiles'], (old: any) => {
        const optimisticFiles = files.map((file) => ({
          id: createId(),
          fileName: file.name,
          s3key: '',
          timeCreated: '',
        }));
        return [...old, ...optimisticFiles];
      });
      return { previousFiles };
    },
    onError: (_err, _variables, context) => {
      queryClient.setQueryData(['practiceFiles'], context?.previousFiles);
      setSnackBarMessage(`Failed to upload file`);
      setOpenSnackbar(true);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['practiceFiles'] });
    },
  });

  const handleDeleteFiles = () => {
    const ids = selectedDocuments.map((doc) => doc.id);
    return removeFilesMutation.mutate(ids);
  };

  const removeFilesMutation = useMutation({
    mutationKey: ['removeFiles'],
    mutationFn: deleteFiles,
    onMutate: async (ids) => {
      setSnackBarMessage(`Deleting files...`);
      setOpenSnackbar(true);
      const previousFiles = queryClient.getQueryData(['practiceFiles']);
      queryClient.setQueryData(['practiceFiles'], (old: any) => {
        return old.filter((file: any) => !ids.includes(file.id));
      });
      return { previousFiles };
    },
    onError: (_err, _variables, context) => {
      queryClient.setQueryData(['practiceFiles'], context?.previousFiles);
    },
    onSuccess: () => {
      setSnackBarMessage(`Files deleted`);
      setOpenSnackbar(true);
    },
  });

  const { data, isLoading } = useQuery({
    queryKey: ['practiceFiles'],
    queryFn: fetchPracticeFiles,
  });

  const rows: RowData[] = useMemo(() => {
    if (data) {
      return data.map((file: any) => {
        return {
          id: file.id,
          file: file.file,
          name: file.fileName,
          s3key: file.s3key,
          timeCreated: file.timeCreated,
        };
      });
    }
    return [];
  }, [data]);

  const selectedDocuments = useMemo(() => {
    return rows.filter((row) => rowSelectionModel.includes(row.id));
  }, [rowSelectionModel]);

  const columns: GridColDef[] = [
    {
      field: 'file',
      width: 50,
      renderCell: (params) => {
        const { name } = params.row as RowData;

        return <div className="flex items-center">{DocumentIcon(name)}</div>;
      },
    },
    { field: 'name', width: 200 },
    {
      field: 'id',
      flex: 1,
      align: 'right',

      renderCell: (params) => {
        const { s3key } = params.row as RowData;
        if (s3key === '' || s3key === null) {
          return <i className={'fa fa-spinner px-6 fa-spin'}></i>;
        }
        return <></>;
      },
    },
  ];

  const CustomToolBar = () => (
    <div className="flex justify-between m-2">
      <div className="flex flex-1">
        <GridToolbarQuickFilter className="flex-1 mr-8" />
      </div>
    </div>
  );

  if (isLoading) {
    return (
      <div className="flex flex-col justify-center items-center w-full h-full">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="full-w-container flex-col">
      <div className="flex justify-between flex-wrap gap-2">
        <div className="flex flex-col">
          <Typography variant={'h2'} text="Practice Documents" />
          <Typography
            variant={'h6'}
            customClass="leading-1"
            text={`View, upload, and download files ${
              profile && profile.practiceName
                ? 'for ' + profile.practiceName
                : ''
            }`}
          />
        </div>
        <div className="flex gap-2">
          <ButtonType
            disabled={selectedDocuments.length === 0}
            variant="contained"
            text={'Share Selected'}
            notRounded
            onClick={() => setOpenSharingModal(true)}
            icon={true}
            specificIcon={
              <FileUploadOutlinedIcon htmlColor="#FFFFFF" className="mr-2" />
            }
          />
          <ButtonType
            disabled={selectedDocuments.length === 0}
            variant="outlined"
            text={'Download'}
            notRounded
            onClick={downloadFilesBulk}
            icon={true}
            specificIcon={
              <Download htmlColor={colors.asterGray} className="mr-2" />
            }
          />
          <ButtonType
            disabled={selectedDocuments.length === 0}
            variant="outlined"
            text={'Delete'}
            notRounded
            onClick={() => {
              setOpenDeleteModal(true);
            }}
            icon={true}
            specificIcon={
              <Delete htmlColor={colors.asterGray} className="mr-2" />
            }
          />

          <ButtonType
            variant="contained"
            text={'Upload Files'}
            notRounded
            onClick={() => setOpenUploadModal(true)}
            icon={true}
            specificIcon={
              <FileUploadOutlinedIcon htmlColor="#FFFFFF" className="mr-2" />
            }
          />
        </div>
      </div>
      <div className="grid">
        <StyledDataGrid
          checkboxSelection
          onRowSelectionModelChange={(newSelection) => {
            setRowSelectionModel(newSelection);
          }}
          isRowSelectable={(params) => params.row.s3key}
          pageSizeOptions={[10, 25, 50]}
          slots={{
            toolbar: CustomToolBar,
            columnHeaders: () => null,
            noRowsOverlay: NoRowsOverlay,
          }}
          autoHeight
          columns={columns}
          rows={rows}
          className="mt-8"
          disableColumnFilter
          disableDensitySelector
          disableColumnSelector
          disableColumnMenu
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
        />
      </div>
      <UploadDocumentModal
        title="Upload Files"
        dismiss="Cancel"
        confirm="Upload"
        isUploading={isUploading}
        handleCancel={() => setOpenUploadModal(false)}
        open={openUploadModal}
        handleConfirm={addFileMutation}
        handleClose={() => setOpenUploadModal(false)}
      />
      <ShareDocumentsModal
        selectedDocuments={selectedDocuments}
        title="Share Documents"
        dismiss="Cancel"
        confirm="Send via Aster email"
        open={openSharingModal}
        handleConfirm={sendEmailWithDocuments}
        handleClose={() => setOpenSharingModal(false)}
        isUploading={isUploading}
      />
      <DeleteDocumentsModal
        open={openDeleteModal}
        handleClose={() => setOpenDeleteModal(false)}
        handleConfirm={handleDeleteFiles}
      />
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        autoHideDuration={3000}
        message={snackBarMessage}
        sx={{
          backgroundColor: colors.gray,
          color: colors.white,
        }}
      />
    </div>
  );
};

export default PracticeDocuments;
