import BasicTextfield from '../../../components/Textfield';
import RadioOptions from '../../../components/RadioOptions';
import Typography from '../../../components/Typography';
import { SocialInfo } from '../types/patient-profile';
import MultipleCheckboxes from '../../../components/MultipleCheckboxes';
import { CheckboxItem } from '../types/question';

type SocialInfoProps = {
  socialInfo: SocialInfo;
  handleOption: (property: string, value: any) => void;
  handleInfo: (event: any) => void;
  handleCheckboxes: (values: any, property: string) => void;
};

const SocialInformation = (props: SocialInfoProps) => {
  const {
    hasSocialSupport,
    employmentStatus,
    doFeelSafe,
    doFeelFinanciallySecure,
    doHaveAdequateTransportation,
    housingSituation,
    doHaveEnoughHealthyFood,
    lackOfHealthyFoodDetails,
    nationalOrLocalPrograms,
    doFeelSafeOnCurrentRelationship,
    isPartnerControlling,
    doHaveConcernsAboutChildSafety,
    hasPartnerForcedSexually,
    hasPartnerEverThreatenedOrMadeFeelUnsafe,
    hasPreviousPartnerAbusiveExperience,
    previousPartnerAbusiveExperienceDetails,
  } = props.socialInfo;

  const handleOption = props.handleOption;
  const handleInfo = props.handleInfo;
  const handleCheckboxes = props.handleCheckboxes;
  const handleCheckboxNationalOrLocalPrograms = (value: string) => {
    const updatedItems = nationalOrLocalPrograms.map((item: CheckboxItem) =>
      item.value === value ? { ...item, checked: !item.checked } : item
    );
    handleCheckboxes(updatedItems, 'nationalOrLocalPrograms');
  };

  return (
    <div className="container w-fit pb-10">
      <div className="grid grid-cols-1">
        <Typography
          text="Social Information"
          variant="h5"
          customClass="font-semibold mb-5"
        ></Typography>
        <RadioOptions
          value={hasSocialSupport}
          label="Do you have social support available?"
          handleChange={(event: any) =>
            handleOption('hasSocialSupport', event.target.value)
          }
          options={[
            {
              value: 'yes',
              text: 'Yes I feel supported',
              key: 'yes',
            },
            { value: 'sometimes', text: 'Sometimes', key: 'sometimes' },
            { value: 'no', text: 'No', key: 'no' },
          ]}
        />
        <br />

        <RadioOptions
          value={employmentStatus}
          label="Are you currently employed?"
          handleChange={(event: any) =>
            handleOption('employmentStatus', event.target.value)
          }
          options={[
            {
              value: 'full-time',
              text: 'Yes full time',
              key: 'full',
            },
            {
              value: 'part-time',
              text: 'Yes part time',
              key: 'part',
            },
            { value: 'unemployed', text: 'Unemployed', key: 'unemployed' },
            {
              value: 'full-time-student',
              text: 'Full time student',
              key: 'student',
            },
            {
              value: 'part-time-student',
              text: 'Part-time student',
              key: 'studentpart',
            },
          ]}
        />
        <br />

        <RadioOptions
          value={doFeelSafe}
          label="Do you feel safe?"
          handleChange={(event: any) =>
            handleOption('doFeelSafe', event.target.value)
          }
          options={[
            {
              value: 'yes',
              text: 'Yes',
              key: 'yes',
            },
            { value: 'somewhat', text: 'Somewhat', key: 'somewhat' },
            { value: 'no', text: 'No', key: 'no' },
          ]}
        />
        <br />

        <RadioOptions
          value={doFeelFinanciallySecure}
          label="Do you feel financially secure?"
          handleChange={(event: any) =>
            handleOption('doFeelFinanciallySecure', event.target.value)
          }
          options={[
            {
              value: 'yes',
              text: 'Yes',
              key: 'yes',
            },
            { value: 'for_now', text: 'Yes for now', key: 'fornow' },
            { value: 'no', text: 'No', key: 'no' },
          ]}
        />
        <br />

        <RadioOptions
          value={doHaveAdequateTransportation}
          label="Do you have adequate transportation to get to medical appointments?"
          handleChange={(event: any) =>
            handleOption('doHaveAdequateTransportation', event.target.value)
          }
          options={[
            {
              value: 'yes',
              text: 'Yes',
              key: 'yes',
            },
            { value: 'sometimes', text: 'Sometimes', key: 'sometimes' },
            { value: 'no', text: 'No', key: 'no' },
          ]}
        />
        <br />

        <RadioOptions
          value={housingSituation}
          label="What is your housing situation today?"
          handleChange={(event: any) =>
            handleOption('housingSituation', event.target.value)
          }
          options={[
            {
              value: 'has_housing',
              text: 'I have housing',
              key: 'housing',
            },
            {
              value: 'not_has_housing',
              text: 'I do not have housing',
              key: 'no-housing',
            },
            {
              value: 'worried_about_future',
              text: 'I have housing but I am worried about not having this in future',
              key: 'no',
            },
            {
              value: 'staying_with_family_or_friends',
              text: 'I am staying with friends/family',
              key: 'staying',
            },
          ]}
        />
        <br />

        <RadioOptions
          classes="mb-2"
          value={doHaveEnoughHealthyFood}
          label="Are you worried about access to food?"
          handleChange={(event: any) =>
            handleOption(
              'doHaveEnoughHealthyFood',
              event.target.value === 'true'
            )
          }
          options={[
            {
              value: false,
              text: 'Yes',
              key: 'yes',
            },
            { value: true, text: 'No', key: 'no' },
          ]}
        />
        {!doHaveEnoughHealthyFood && (
          <BasicTextfield
            id="lackOfHealthyFoodDetails"
            variant="filled"
            label="Details"
            name="lackOfHealthyFoodDetails"
            value={lackOfHealthyFoodDetails || ''}
            onChange={handleInfo}
            classes="w-[300px]"
          />
        )}
        <br />

        <Typography
          text="Are you part of any local or national level programs?"
          variant="body"
          customClass="text-gray font-semibold"
        />

        <div className="flex flex-row mb-4">
          <MultipleCheckboxes
            handleCheckboxChange={handleCheckboxNationalOrLocalPrograms}
            separateInHalf
            items={nationalOrLocalPrograms}
          />
        </div>

        <br />

        <RadioOptions
          value={doFeelSafeOnCurrentRelationship}
          label="Do you feel safe in your current relationship?"
          handleChange={(event: any) =>
            handleOption('doFeelSafeOnCurrentRelationship', event.target.value)
          }
          options={[
            {
              value: 'yes',
              text: 'Yes',
              key: 'yes',
            },
            { value: 'somewhat', text: 'Somewhat', key: 'somewhat' },
            { value: 'no', text: 'No', key: 'no' },
            { value: 'na', text: 'N/A', key: 'na' },
          ]}
        />
        <br />

        <RadioOptions
          value={isPartnerControlling}
          label="Does your partner ever try to control what you do, who you see or talk to, or where you go?"
          handleChange={(event: any) =>
            handleOption('isPartnerControlling', event.target.value)
          }
          options={[
            {
              value: 'yes',
              text: 'Yes',
              key: 'yes',
            },
            { value: 'somewhat', text: 'Somewhat', key: 'somewhat' },
            { value: 'no', text: 'No', key: 'no' },
            { value: 'na', text: 'N/A', key: 'na' },
          ]}
        />
        <br />

        <RadioOptions
          value={doHaveConcernsAboutChildSafety}
          label="Do you have any concerns about the safety of your children?"
          handleChange={(event: any) =>
            handleOption('doHaveConcernsAboutChildSafety', event.target.value)
          }
          options={[
            {
              value: 'yes',
              text: 'Yes',
              key: 'yes',
            },
            { value: 'somewhat', text: 'Somewhat', key: 'somewhat' },
            { value: 'no', text: 'No', key: 'no' },
            { value: 'na', text: 'N/A', key: 'na' },
          ]}
        />
        <br />

        <RadioOptions
          value={hasPartnerForcedSexually}
          label="Has your partner ever forced you into sexual activities that you did not want?"
          handleChange={(event: any) =>
            handleOption('hasPartnerForcedSexually', event.target.value)
          }
          options={[
            {
              value: 'yes',
              text: 'Yes',
              key: 'yes',
            },
            { value: 'somewhat', text: 'Somewhat', key: 'somewhat' },
            { value: 'no', text: 'No', key: 'no' },
            { value: 'na', text: 'N/A', key: 'na' },
          ]}
        />
        <br />

        <RadioOptions
          value={hasPartnerEverThreatenedOrMadeFeelUnsafe}
          label="Has your current partner ever threatened you or made you feel afraid?"
          handleChange={(event: any) =>
            handleOption(
              'hasPartnerEverThreatenedOrMadeFeelUnsafe',
              event.target.value
            )
          }
          options={[
            {
              value: 'yes',
              text: 'Yes',
              key: 'yes',
            },
            { value: 'somewhat', text: 'Somewhat', key: 'somewhat' },
            { value: 'no', text: 'No', key: 'no' },
            { value: 'na', text: 'N/A', key: 'na' },
          ]}
        />
        <br />
        <RadioOptions
          classes="mb-2"
          value={hasPreviousPartnerAbusiveExperience}
          label="Have you previously experienced violence, aggression or controlling behavior from a partner? Please provide details"
          handleChange={(event: any) =>
            handleOption(
              'hasPreviousPartnerAbusiveExperience',
              event.target.value === 'true'
            )
          }
          options={[
            {
              value: true,
              text: 'Yes',
              key: 'yes',
            },
            { value: false, text: 'No', key: 'no' },
          ]}
        />
        {hasPreviousPartnerAbusiveExperience && (
          <BasicTextfield
            id="previousPartnerAbusiveExperienceDetails"
            variant="filled"
            label="Details"
            name="previousPartnerAbusiveExperienceDetails"
            value={previousPartnerAbusiveExperienceDetails || ''}
            onChange={handleInfo}
            classes="w-[300px]"
          />
        )}
        <br />
      </div>
    </div>
  );
};

export default SocialInformation;
