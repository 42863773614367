import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import ButtonType from '../../../components/Button';
import Typography from '../../../components/Typography';
import { Divider } from '@mui/material';
import HouseMedicalPrimary from '../../../assets/icons/HouseMedicalPrimary';
import { useForm } from '@tanstack/react-form';
import { zodValidator } from '@tanstack/zod-form-adapter';
import { z } from 'zod';
import BasicTextfield from '../../../components/Textfield';
import BorderLinearProgress from './LinearProgress';
import { US_STATES } from '../constants';
import SelectDropdown from '../../../components/SelectDropdown';
import { useState } from 'react';
import { useDosespot } from '../DosespotProvider';
import { useAuth } from '../../../authentication/AuthProvider';
import { formatPhone } from '../util/formatPhone';

type AddPracticeModalProps = {
  incrementStep: () => void;
};

const AddClinic = ({ incrementStep }: AddPracticeModalProps) => {
  const dosespotContext = useDosespot();
  const [formError, setFormError] = useState<string[] | null>(null);
  const { profile } = useAuth();
  const form = useForm({
    defaultValues: {
      practice_name: profile ? profile.practiceName : '',
      practice_address: '',
      practice_city: '',
      practice_state: '',
      practice_zip: '',
      practice_phone: '',
    },
    validatorAdapter: zodValidator,
    onSubmit: async (values) => {
      setFormError(null);

      return createDosespotClinic()
        .then((res) => {
          if (res && res.data.ModelState) {
            const errors = Object.values(
              res.data.ModelState as Record<string, string[]>
            ).map((error) => error[0]);
            setFormError(errors);
            return;
          }
          incrementStep();
        })
        .catch((error) => {
          console.error(error);
          setFormError([
            'Error creating clinic, please make sure all fields are filled out correctly',
          ]);
        });
    },
  });

  const createDosespotClinic = async () => {
    const phone = formatPhone(form.getFieldValue('practice_phone'));
    const clinic = await dosespotContext.createDosespotClinic({
      ClinicName: form.getFieldValue('practice_name'),
      Address1: form.getFieldValue('practice_address'),
      City: form.getFieldValue('practice_city'),
      State: form.getFieldValue('practice_state'),
      ZipCode: form.getFieldValue('practice_zip'),
      PrimaryPhone: phone,
    });
    return clinic;
  };

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          void form.handleSubmit();
        }}
      >
        <DialogActions className="flex justify-between p-6">
          <Typography
            variant={'h4'}
            customClass="font-semibold"
            text={'Add your practice'}
          />
          <div className="flex items-end gap-4">
            <div className="flex flex-col items-center w-[140px]">
              <Typography
                variant="bodySmall"
                customClass="text-asterGray"
                text="1/3 steps"
              />
              <BorderLinearProgress
                variant="determinate"
                className="w-full mt-1"
                value={33}
              />
            </div>
          </div>
        </DialogActions>
        <Divider />
        <DialogContent className="grid grid-cols-1 items-start gap-4">
          <div className="grid col-span-1 items-start">
            <div className="flex justify-between pr-5 pl-8">
              <Typography
                customClass="font-semibold leading-0"
                variant="h6"
                text="Practice Information"
              />
              <HouseMedicalPrimary />
            </div>
            {formError &&
              formError.map((error) => (
                <Typography
                  variant={'bodyMedium'}
                  customClass="text-red mt-2"
                  text={error}
                />
              ))}
            <div className="grid gap-5 p-5 mt-5 bg-grayBackground rounded-[20px]">
              <form.Field
                name="practice_name"
                validators={{
                  onBlur: z
                    .string()
                    .min(1, {
                      message: 'Practice name is required',
                    })
                    .max(35, {
                      message: 'Practice name must be less than 35 characters',
                    }),
                }}
                children={(field) => (
                  <BasicTextfield
                    id="practice_name"
                    error={field.state.meta.errors.length > 0}
                    helperText={field.state.meta.errors.join('\r')}
                    name={field.name}
                    value={field.state.value}
                    onBlur={field.handleBlur}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      field.handleChange(event.target.value)
                    }
                    required
                    variant="filled"
                    label="Practice name"
                    width="100%"
                  />
                )}
              />
              <form.Field
                name="practice_address"
                validators={{
                  onBlur: z
                    .string()
                    .min(1, {
                      message: 'Practice address is required',
                    })
                    .max(35, {
                      message:
                        'Practice address must be less than 35 characters',
                    }),
                }}
                children={(field) => (
                  <BasicTextfield
                    id="practice_address"
                    error={field.state.meta.errors.length > 0}
                    helperText={field.state.meta.errors.join('\r')}
                    name={field.name}
                    value={field.state.value}
                    onBlur={field.handleBlur}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      field.handleChange(event.target.value)
                    }
                    required
                    variant="filled"
                    label="Practice address"
                    width="100%"
                  />
                )}
              />
              <form.Field
                name="practice_city"
                validators={{
                  onBlur: z
                    .string()
                    .min(1, {
                      message: 'Practice city is required',
                    })
                    .max(35, {
                      message: 'Practice city must be less than 35 characters',
                    }),
                }}
                children={(field) => (
                  <BasicTextfield
                    id="practice_city"
                    error={field.state.meta.errors.length > 0}
                    helperText={field.state.meta.errors.join('\r')}
                    name={field.name}
                    value={field.state.value}
                    onBlur={field.handleBlur}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      field.handleChange(event.target.value)
                    }
                    required
                    variant="filled"
                    label="Practice city"
                    width="100%"
                  />
                )}
              />
              <div className="grid grid-cols-2 gap-x-4">
                <form.Field
                  name="practice_state"
                  validators={{
                    onBlur: z
                      .string()
                      .min(2, {
                        message: 'Practice state is required',
                      })
                      .max(20, {
                        message:
                          'Practice state must be less than 20 characters',
                      }),
                  }}
                  children={(field) => (
                    <SelectDropdown
                      handleChange={(
                        event: React.ChangeEvent<HTMLSelectElement>
                      ) => field.handleChange(event.target.value)}
                      options={US_STATES}
                      name={field.name}
                      value={field.state.value}
                      required
                      label="Practice state"
                      width="100%"
                    />
                  )}
                />
                <form.Field
                  name="practice_zip"
                  validators={{
                    onBlur: z
                      .string()
                      .min(1, {
                        message: 'Practice zip is required',
                      })
                      .max(10, {
                        message: 'Practice zip must be less than 10 characters',
                      }),
                  }}
                  children={(field) => (
                    <BasicTextfield
                      id="practice_zip"
                      error={field.state.meta.errors.length > 0}
                      helperText={field.state.meta.errors.join('\r')}
                      name={field.name}
                      value={field.state.value}
                      onBlur={field.handleBlur}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        field.handleChange(event.target.value)
                      }
                      required
                      variant="filled"
                      label="Practice zip"
                      width="100%"
                    />
                  )}
                />
              </div>
              <form.Field
                name="practice_phone"
                validators={{
                  onBlur: z
                    .string()
                    .min(1, {
                      message: 'Practice phone is required',
                    })
                    .max(25, {
                      message: 'Practice phone must be less than 25 characters',
                    }),
                }}
                children={(field) => (
                  <BasicTextfield
                    id="practice_phone"
                    error={field.state.meta.errors.length > 0}
                    helperText={field.state.meta.errors.join('\r')}
                    name={field.name}
                    value={field.state.value}
                    onBlur={field.handleBlur}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      field.handleChange(event.target.value)
                    }
                    required
                    variant="filled"
                    label="Practice phone"
                    width="100%"
                  />
                )}
              />
            </div>
          </div>
          <div className="flex justify-center w-full">
            <form.Subscribe
              selector={(state) => [state.canSubmit, state.isSubmitting]}
              children={([canSubmit, isSubmitting]) => (
                <ButtonType
                  classes="w-1/2"
                  disabled={!canSubmit}
                  loading={isSubmitting}
                  type="submit"
                  variant="contained"
                  text={'Next'}
                />
              )}
            ></form.Subscribe>
          </div>
        </DialogContent>
      </form>
    </>
  );
};

export default AddClinic;
