import { createContext, useContext } from 'react';
import { ToastContainer, toast, Slide, ToastPosition } from 'react-toastify';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { customTwMerge } from '../utils';

export type Snack = {
  message: string;
  type: 'error' | 'info' | 'success';
  position?: ToastPosition;
  autoClose?: boolean | number;
};

type SnackBarProps = {
  snack: Snack | null;
};

const SnackView = ({ snack }: SnackBarProps) => {
  return (
    <div
      className={customTwMerge(
        'text-white text-bodyMedium font-semibold',
        getBackgroundColorClass(snack?.type)
      )}
    >
      {snack?.message}
    </div>
  );
};

function getBackgroundColorClass(type: Snack['type'] | undefined) {
  switch (type) {
    case 'error':
      return '!bg-red';
    case 'info':
      return '!bg-gray';
    case 'success':
      return '!bg-green';
    default:
      return '';
  }
}

type SnackbarContextType = {
  showMessage: (snack: Snack) => void;
};

const defaultState: SnackbarContextType = {
  showMessage: () => {
    console.warn('setSnack not implemented');
  },
};

const SnackbarContext = createContext<SnackbarContextType>(defaultState);

function SnackbarProvider({ children }: { children: React.ReactNode }) {
  const showMessage = (snack: Snack) => {
    toast(() => <SnackView snack={snack} />, {
      type: snack.type,
      position: snack.position || 'top-center',
      autoClose:
        typeof snack.autoClose === 'boolean'
          ? snack.autoClose
            ? 3000
            : false
          : snack.autoClose,
    });
  };

  const value: SnackbarContextType = {
    showMessage,
  };

  return (
    <SnackbarContext.Provider value={value}>
      <ToastContainer
        toastClassName={customTwMerge('text-white !py-0')}
        bodyClassName={customTwMerge('text-white')}
        hideProgressBar
        newestOnTop
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        style={{ fontFamily: 'figtree' }}
        limit={3}
        icon={false}
        pauseOnHover
        theme="colored"
        transition={Slide}
        closeButton={({ closeToast }) => (
          <IconButton disableRipple onClick={closeToast}>
            <CloseIcon className="text-white" />
          </IconButton>
        )}
      />
      {children}
    </SnackbarContext.Provider>
  );
}

function useSnackbar() {
  const context = useContext(SnackbarContext);
  if (context === undefined) {
    throw new Error('useSnackbar must be used within a SnackbarProvider');
  }
  return context;
}

export { SnackbarProvider, useSnackbar };
