export const resultSeverityClassColor = (
  interpretation: string | null | undefined,
  propertyModified: 'text' | 'bg' = 'text'
) => {
  switch (interpretation) {
    case 'critical':
      return `${propertyModified}-red`;
    case 'abnormal':
      return `${propertyModified}-secondary`;
    default:
      return '';
  }
};
