const CalendarDay = () => {
  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.28125 11.1562C3.01758 11.1562 2.8125 10.9512 2.8125 10.6875V7.875C2.8125 7.64062 3.01758 7.40625 3.28125 7.40625H6.09375C6.32812 7.40625 6.5625 7.64062 6.5625 7.875V10.6875C6.5625 10.9512 6.32812 11.1562 6.09375 11.1562H3.28125ZM11.7188 2.25C12.4805 2.25 13.125 2.89453 13.125 3.65625V13.9688C13.125 14.7598 12.4805 15.375 11.7188 15.375H1.40625C0.615234 15.375 0 14.7598 0 13.9688V3.65625C0 2.89453 0.615234 2.25 1.40625 2.25H2.8125V0.84375C2.8125 0.609375 3.01758 0.375 3.28125 0.375H4.21875C4.45312 0.375 4.6875 0.609375 4.6875 0.84375V2.25H8.4375V0.84375C8.4375 0.609375 8.64258 0.375 8.90625 0.375H9.84375C10.0781 0.375 10.3125 0.609375 10.3125 0.84375V2.25H11.7188ZM11.7188 13.793V5.0625H1.40625V13.793C1.40625 13.9102 1.46484 13.9688 1.58203 13.9688H11.543C11.6309 13.9688 11.7188 13.9102 11.7188 13.793Z"
        fill="#586068"
      />
    </svg>
  );
};

export default CalendarDay;
