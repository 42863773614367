import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { useParams } from 'react-router-dom';
import NoteHeader from './sections/NoteHeader';
import TextArea from '../../components/TextArea';
import ConfirmationModal from '../../components/ConfirmationModal';
import { useLeavingPageProtection } from '../../hooks/usePageLeavingProtection';
import { Codes } from './types/encounter';
import TemplateComponent from './templates/TemplateComponent';
import { useAuth } from '../../authentication/AuthProvider';
import PatientProfileForEncounter from './sections/PatientProfileForEncounter';
import { useUpdateEncounterMutation } from './mutations/update-encounter.mutation';
import { useEncounterDetailQuery } from './queries/encounter-detail.query';

const SoapNote = () => {
  const { patient, encounterId } = useParams();
  const [signed, setSigned] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [enforceSaveMissing, setEnforceSaveMissing] = useState(false);
  const [noteInfo, setNoteInfo] = useState({
    subjective: '',
    objective: '',
    assessment: '',
    plan: '',
  });
  const [selectedCodes, setSelectedCodes] = useState<Codes>({
    cptCodes: [],
    icdCodes: [],
  });

  const { profile } = useAuth();

  const { encounterData, isEncounterLoading } = useEncounterDetailQuery(
    encounterId as string
  );

  const handleChangeCodes = (codes: Codes) => {
    setSelectedCodes(codes);
  };

  useEffect(() => {
    if (encounterData) {
      if (encounterData.content) {
        const { subjective, objective, assessment, plan } = Object.keys(
          encounterData.content
        ).length
          ? encounterData.content
          : { subjective: '', objective: '', assessment: '', plan: '' };
        setNoteInfo({
          subjective: subjective,
          objective: objective,
          assessment: assessment,
          plan: plan,
        });
      }
      const { cptCodes, icdCodes } = encounterData as Codes;
      setSelectedCodes({ cptCodes, icdCodes });
      setSigned(encounterData.signedBy !== null);
    }
  }, [encounterData, encounterId]);

  const { updateEncounter } = useUpdateEncounterMutation(encounterId, () => {
    setIsDirty(false);
    setEnforceSaveMissing(false);
  });

  const saveInfo = (signed: boolean) => {
    setSigned(signed);
    const info = {
      patientID: patient,
      startTime: encounterData?.startTime,
      endTime: encounterData?.endTime,
      signed,
      content: {
        subjective: noteInfo.subjective,
        objective: noteInfo.objective,
        assessment: noteInfo.assessment,
        plan: noteInfo.plan,
      },
      templateType: encounterData?.templateType,
      cptCodes: selectedCodes.cptCodes,
      icdCodes: selectedCodes.icdCodes,
    };
    if (signed) {
      setOpenConfirmation(false);
    }
    updateEncounter.mutate(info);
  };

  const { blocker } = useLeavingPageProtection({ canLeave: !isDirty });
  const handleClose = (confirmed: boolean) => {
    if (confirmed) {
      saveInfo(false);
      setEnforceSaveMissing(true);
      blocker?.reset?.();
    } else {
      blocker?.proceed?.();
      setIsDirty(false);
      setEnforceSaveMissing(false);
    }
  };

  const handleUnsaved = (isDirty: boolean) => {
    setIsDirty(isDirty);
  };

  if (isEncounterLoading) {
    return (
      <div className="flex flex-col justify-center items-center h-full w-full">
        <CircularProgress />
      </div>
    );
  }

  const handleFormInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setNoteInfo((prevNote) => ({
      ...prevNote,
      [name]: value,
    }));
    setIsDirty(true);
  };

  const handleChangeNote = (content: string) => {
    setNoteInfo((prevNote) => ({
      ...prevNote,
      subjective: content,
    }));
    setIsDirty(true);
  };

  const buttons = [
    { value: 'history', text: 'History' },
    { value: 'labs', text: 'Labs/Ultrasounds' },
    { value: 'laborSOAP', text: 'Labor SOAP' },
  ];

  return (
    <div className="flex flex-col lg:flex-row overflow-auto h-full w-full">
      <div className="w-fit lg:max-w-[400px]">
        <PatientProfileForEncounter
          isEncounterSigned={signed}
          enforceSaveMissing={enforceSaveMissing}
          handleUnsaved={handleUnsaved}
        />
      </div>
      <div className="flex flex-col max-w-[1000px] lg:w-full mt-5 mx-10 lg:overflow-y-auto">
        <NoteHeader
          encounterInfo={encounterData}
          selectedCodes={selectedCodes}
          disabled={updateEncounter.status === 'pending'}
          setSelectedCodes={handleChangeCodes}
          title="SOAP Note"
          buttonMessageLeft="Save draft"
          actionLeft={() => saveInfo(false)}
          buttonMessageRight="Sign note"
          actionRight={() => setOpenConfirmation(true)}
          hide={signed}
        />
        <div className="flex flex-col">
          <div className="w-full">
            <TemplateComponent
              noteInfo={noteInfo.subjective}
              label="Subjective"
              signed={signed}
              handleChangeNote={handleChangeNote}
              buttons={buttons}
            />
          </div>
          <div className="w-full">
            <TextArea
              name="objective"
              textValue={noteInfo.objective}
              topLabel="Objective"
              topLabelVariant="h6"
              headerClassName="mb-2"
              placeholder=" "
              handleChange={handleFormInput}
              border="border-grayBackground"
              disabled={signed}
              rows={4}
            />
          </div>
          <div className="w-full">
            <TextArea
              name="assessment"
              textValue={noteInfo.assessment}
              topLabel="Assessment"
              topLabelVariant="h6"
              headerClassName="mb-2"
              placeholder=" "
              handleChange={handleFormInput}
              border="border-grayBackground"
              disabled={signed}
              rows={4}
            />
          </div>
          <div className="w-full">
            <TextArea
              name="plan"
              textValue={noteInfo.plan}
              topLabel="Plan"
              topLabelVariant="h6"
              headerClassName="mb-2"
              placeholder=" "
              handleChange={handleFormInput}
              border="border-grayBackground"
              disabled={signed}
              rows={4}
            />
          </div>
        </div>
      </div>
      <ConfirmationModal
        open={openConfirmation}
        title="Sign this encounter note?"
        description={`You're signing as ${profile && profile.firstName} ${
          profile && profile.lastName
        }`}
        confirm="Sign"
        dismiss="Cancel"
        handleClose={() => setOpenConfirmation(false)}
        handleConfirm={() => saveInfo(true)}
        handleCancel={() => setOpenConfirmation(false)}
      />
      <ConfirmationModal
        open={blocker.state === 'blocked'}
        title="Unsaved Changes"
        description={`Are you sure you want to leave? Changes may not be saved.`}
        confirm="Save"
        dismiss="Leave"
        handleClose={() => handleClose(true)}
        handleConfirm={() => handleClose(true)}
        handleCancel={() => handleClose(false)}
      />
    </div>
  );
};

export default SoapNote;
