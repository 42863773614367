export const heartRateLabels = [
  '60s',
  '70s',
  '80s',
  '90s',
  '100s',
  '110s',
  '120s',
  '130s',
  '140s',
  '150s',
  '160s',
  '170s',
  '180s',
  '190s',
  '200s',
];

export const checkboxFHTData = {
  decel: [{ label: 'No decels' }, { label: 'Accels' }, { label: 'Decel' }],
  timing: [{ label: 'Early' }, { label: 'Late' }, { label: 'Variables' }],
  uc: [{ label: 'Before UC' }, { label: 'After UC' }, { label: 'During UC' }],
};

export const defaultPrenatalData = {
  notes: '',
  extraFlag: '',
  imaging: '',
  mood: '',
  safety: '',
  plan: '',
  measurement: '',
  measurementData: '',
  pain: null,
  fetalHeartRate: '',
  painInformation: '',
};
