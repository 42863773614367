import Grid from '@mui/material/Grid';
import Users from '../../../assets/icons/Users';
import Typography from '../../../components/Typography';
import { Option } from '../../../components/Autocomplete';

type ParticipantsProps = {
  invitedPatients: Option[];
  invitedStaff: Option[];
};

const Participants = ({ invitedPatients, invitedStaff }: ParticipantsProps) => (
  <div className="grid grid-cols-2 w-full h-auto">
    {invitedPatients?.length > 0 && (
      <Grid container className="h-auto">
        <Grid item xs={2} pt={3}>
          <Users />
        </Grid>
        <Grid item xs={10} height={50} mt={2} className="h-auto">
          <p className="text-bodySmall text-gray">Patients</p>
          {invitedPatients.map((patient) => (
            <Typography
              key={patient.id}
              variant={'bodySmall'}
              text={patient.label}
              customClass="text-black font-semibold"
            />
          ))}
        </Grid>
      </Grid>
    )}
    {invitedStaff.length > 0 && (
      <Grid container>
        <Grid item xs={2} pt={3}>
          <Users />
        </Grid>
        <Grid item xs={10} height={50} mt={2}>
          <p className="text-bodySmall text-gray">Practitioners</p>
          {invitedStaff.map((staff) => (
            <Typography
              key={staff.id}
              variant={'bodySmall'}
              text={staff.label}
              customClass="text-black font-semibold"
            />
          ))}
        </Grid>
      </Grid>
    )}
  </div>
);

export default Participants;
