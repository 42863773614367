import { useMutation } from '@tanstack/react-query';
import axios from '../../../app/axiosConfig';

type CompleteFormMutationProps = {
  onSuccess: () => void;
};

export const useCompleteFormMutation = ({
  onSuccess,
}: CompleteFormMutationProps) => {
  const completeForm = async (token: string) =>
    axios.post(`patients/complete-form/${token}`);

  const completeFormMutation = useMutation({
    mutationKey: ['completeFormLink'],
    mutationFn: completeForm,

    onSuccess: () => {
      onSuccess();
    },
  });

  return { completeFormMutation };
};
