import React from 'react';
import MultipleCheckboxes from '../../../components/MultipleCheckboxes';
import Typography from '../../../components/Typography';
import { AccessInfo, CommuInfo } from '../types/patient-profile';

type Item = {
  value: string;
  checked: boolean;
};

type AccessProps = {
  accessInfo: AccessInfo;
  commuInfo: CommuInfo;
  handleCheckboxesCommu: (values: any, property: string) => void;
  handleCheckboxesAccess: (values: any) => void;
};

const Preferences = (props: AccessProps) => {
  const { specialNeeds } = props.accessInfo;
  const { preferredCommunicationMethods } = props.commuInfo;
  const handleCheckboxesAccess = props.handleCheckboxesAccess;
  const handleCheckboxesCommu = props.handleCheckboxesCommu;

  const handleCommunicationsChange = (value: string) => {
    const updatedItems = preferredCommunicationMethods.map((item: Item) =>
      item.value === value ? { ...item, checked: !item.checked } : item
    );
    handleCheckboxesCommu(updatedItems, 'preferredCommunicationMethods');
  };

  const handleAccessibilityChange = (value: string) => {
    const updatedItems = specialNeeds.map((item: Item) =>
      item.value === value ? { ...item, checked: !item.checked } : item
    );
    handleCheckboxesAccess(updatedItems);
  };

  return (
    <div className="grid grid-cols-1">
      <Typography
        text="Communications Preference"
        variant="h5"
        customClass="font-semibold mb-5"
      ></Typography>

      <Typography
        text="Please select your preferred communication methods; you can change these anytime by contacting us."
        variant="body"
        customClass="text-gray font-semibold"
      />
      <br />
      <div className="flex flex-row">
        <MultipleCheckboxes
          handleCheckboxChange={handleCommunicationsChange}
          items={preferredCommunicationMethods}
        />
      </div>
      <Typography
        text="Accessibility"
        variant="h5"
        customClass="font-semibold mb-5 mt-7"
      ></Typography>

      <Typography
        text="Is there any special assistance or accommodations you require for your appointments?"
        variant="body"
        customClass="text-gray font-semibold"
      />
      <br />
      <div className="flex flex-row">
        <MultipleCheckboxes
          handleCheckboxChange={handleAccessibilityChange}
          items={specialNeeds}
        />
      </div>
    </div>
  );
};

export default Preferences;
