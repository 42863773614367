import { PatientProfileForEncounterDTO } from '@aster/shared/dtos/patient';
import axios from '../../../app/axiosConfig';
import { useQuery } from '@tanstack/react-query';

const fetchPatientProfile = async (patientID: string) => {
  const response = await axios.get<PatientProfileForEncounterDTO>(
    `patients/${patientID}/profile`
  );
  return response.data;
};

export function usePatientProfileQuery(patientID: string) {
  const { data: patientProfile, isLoading: isPatientProfileLoading } = useQuery(
    {
      queryKey: ['patientProfile', patientID],
      queryFn: () => fetchPatientProfile(patientID),
      enabled: !!patientID,
    }
  );
  return { patientProfile, isPatientProfileLoading };
}
