const Trash = () => {
  return (
    <svg
      width="18"
      height="21"
      viewBox="0 0 18 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5625 17.375C11.3672 17.375 11.25 17.2578 11.25 17.0625V6.4375C11.25 6.28125 11.3672 6.125 11.5625 6.125H12.1875C12.3438 6.125 12.5 6.28125 12.5 6.4375V17.0625C12.5 17.2578 12.3438 17.375 12.1875 17.375H11.5625ZM5.3125 17.375C5.11719 17.375 5 17.2578 5 17.0625V6.4375C5 6.28125 5.11719 6.125 5.3125 6.125H5.9375C6.09375 6.125 6.25 6.28125 6.25 6.4375V17.0625C6.25 17.2578 6.09375 17.375 5.9375 17.375H5.3125ZM17.1875 3C17.3438 3 17.5 3.15625 17.5 3.3125V3.9375C17.5 4.13281 17.3438 4.25 17.1875 4.25H16.25V18.625C16.25 19.6797 15.3906 20.5 14.375 20.5H3.125C2.07031 20.5 1.25 19.6797 1.25 18.625V4.25H0.3125C0.117188 4.25 0 4.13281 0 3.9375V3.3125C0 3.15625 0.117188 3 0.3125 3H4.375L5.66406 1.28125C6.01562 0.8125 6.5625 0.5 7.1875 0.5H10.3125C10.8984 0.5 11.4453 0.8125 11.7969 1.28125L13.125 3H17.1875ZM6.67969 2.02344L5.9375 3H11.5625L10.7812 2.02344C10.6641 1.86719 10.5078 1.78906 10.3125 1.75H7.1875C6.95312 1.78906 6.79688 1.86719 6.67969 2.02344ZM15 18.625V4.25H2.5V18.625C2.5 18.9766 2.77344 19.25 3.125 19.25H14.375C14.6875 19.25 15 18.9766 15 18.625ZM8.4375 17.375C8.24219 17.375 8.125 17.2578 8.125 17.0625V6.4375C8.125 6.28125 8.24219 6.125 8.4375 6.125H9.0625C9.21875 6.125 9.375 6.28125 9.375 6.4375V17.0625C9.375 17.2578 9.21875 17.375 9.0625 17.375H8.4375Z"
        fill="#586068"
      />
    </svg>
  );
};

export default Trash;
