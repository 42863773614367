import { IconButton } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Tag from '../../../components/Tag';
import {
  calculateGestationalAge,
  calculateDueDate,
  formatMRN,
} from '../../../utils';
import { GTPALDTO, PatientProblem } from '@aster/shared/dtos/patient';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import CircleWithInitials from '../../../components/CircleWIthInitials';

type PatientProfileForEncounterHeaderProps = {
  age?: number | null;
  name: string;
  edd?: string | null;
  dateOfConception?: string | null;
  lmp?: string | null;
  dateToCalculateDueDate?: 'edd' | 'lmp' | 'dateOfConception' | null;
  gtpal: GTPALDTO;
  dateOfBirth?: string | null;
  mrn?: string | null;
  problemList?: PatientProblem[];
};

const PatientProfileForEncounterHeader = ({
  age,
  name,
  edd,
  dateOfConception,
  lmp,
  dateToCalculateDueDate,
  gtpal,
  dateOfBirth,
  mrn,
  problemList,
}: PatientProfileForEncounterHeaderProps) => {
  const navigate = useNavigate();
  const dueDate = calculateDueDate({
    lmp,
    edd,
    dateOfConception,
    dateToCalculateDueDate,
  });
  const gestationAge = calculateGestationalAge(dueDate);
  const gtpalInfoFull =
    gtpal &&
    `G${gtpal.gravida} T${gtpal.termBirths} P${gtpal.pretermBirths} A${gtpal.abortionsAndMiscarriages} L${gtpal.livingChildren}`;
  const hasAdvancedMaternalAge = Boolean(
    problemList?.find((problem) => problem.abbr === 'AMA')
  );

  return (
    <div className="flex gap-2 m-2 mt-3 mb-4 mr-0">
      <IconButton
        className="pr-0 h-[50px] p-0"
        aria-label="Go back"
        onClick={() => navigate(-1)}
        disableRipple
      >
        <ArrowBackIosIcon className="text-[22px] text-gray" />
      </IconButton>

      <CircleWithInitials
        customClass="mx-0"
        height={50}
        width={50}
        name={name}
      />

      <div className="flex flex-1 flex-col">
        <div className="flex flex-col gap-[5px]">
          <div className="flex items-center gap-[5px]">
            <p className="text-h4 font-semibold leading-6">{name}</p>
            {age && (
              <Tag
                tooltip="Age"
                className="font-normal"
                tagText={age.toString()}
              />
            )}
          </div>
          <div className="flex flex-wrap gap-[5px]">
            <div className="flex flex-wrap gap-[5px]">
              {mrn && (
                <Tag tooltip="Medical Record Number" tagText={formatMRN(mrn)} />
              )}
              {dateOfBirth && (
                <Tag
                  tooltip="Date of Birth"
                  tagText="DOB"
                  rightText={dayjs(dateOfBirth).format('MM/DD/YYYY')}
                />
              )}
              {(edd || dateOfConception) && (
                <Tag
                  tooltip="Gestational Age"
                  tagText="GA"
                  rightText={gestationAge}
                />
              )}
              {dueDate && (
                <Tag
                  tooltip="Estimated Due Date"
                  tagText="EDD"
                  rightText={dayjs(dueDate).format('MM/DD/YYYY')}
                />
              )}
            </div>
            <div className="flex gap-[5px]">
              {gtpalInfoFull && (
                <Tag
                  tooltip={gtpalInfoFull}
                  tagText={gtpalInfoFull}
                  className="font-semibold"
                  textColor="white"
                  color="gray"
                />
              )}
              {hasAdvancedMaternalAge && (
                <Tag
                  tooltip="Advanced Maternal Age"
                  tagText="AMA"
                  className="opacity-80  font-semibold"
                  color="beige"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientProfileForEncounterHeader;
