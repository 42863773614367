import * as React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import { colors } from '../theme';

type Placement = 'start' | 'top' | 'end' | 'bottom';
type CheckProps = {
  checked: boolean;
  label?: string;
  placement?: Placement;
  disabled?: boolean;
  defaultChecked?: boolean;
  onChange: any;
  classes?: string;
};

const BasicCheckbox = (props: CheckProps) => {
  const {
    checked,
    label,
    disabled,
    defaultChecked,
    onChange,
    placement,
    classes,
  } = props;
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={checked}
          onChange={onChange}
          sx={{
            color: colors.grayLight,
            '&.Mui-checked': {
              color: colors.green,
            },
          }}
          className={classes}
          disableRipple
        />
      }
      label={label}
      disabled={disabled}
      key={label}
      defaultChecked={defaultChecked}
      labelPlacement={placement || 'end'}
    />
  );
};

export default BasicCheckbox;
