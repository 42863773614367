import { useRef, useState } from 'react';
import dayjs from 'dayjs';
import ButtonType from '../../../components/Button';
import Signature from '../../../assets/icons/Signature';
import CodingModal from '../components/CodingModal';
import { Codes } from '../types/encounter';
import Typography from '../../../components/Typography';
import Billing from '../../../assets/icons/BillingCode';
import Popover from '@mui/material/Popover';
import { EncounterDTO } from '@aster/shared/dtos/encounter';
import EncounterStartTimeUpdater from './EditEncounterDate';

type TitleProps = {
  encounterInfo: EncounterDTO | undefined;
  selectedCodes: Codes;
  setSelectedCodes: (codes: Codes) => void;
  title: string;
  disabled?: boolean;
  buttonMessageLeft?: string;
  buttonMessageRight: string;
  actionLeft?: any;
  actionRight: any;
  hide?: boolean;
};

const NoteHeader = ({
  encounterInfo,
  selectedCodes,
  setSelectedCodes,
  title,
  disabled,
  buttonMessageLeft,
  buttonMessageRight,
  actionLeft,
  actionRight,
  hide,
}: TitleProps) => {
  const [codeModalOpen, setCodeModalOpen] = useState(false);
  const editButtonRef = useRef<HTMLButtonElement>();
  const [editPooperOpen, setEditPopoverOpen] = useState(false);
  const toggleEditPopover = () => setEditPopoverOpen((isOpen) => !isOpen);
  const closeEditPopover = () => setEditPopoverOpen(false);

  return (
    <>
      <div className="flex flex-row flex-wrap gap-4 w-full justify-between items-end mb-4">
        <div className="flex flex-col">
          <div className="flex gap-4 items-center">
            <p className="text-bodySmall text-gray">
              {dayjs(encounterInfo?.startTime).format(
                'ddd MMM D, YYYY • hh:mm a'
              )}
            </p>
            {encounterInfo?.signedBy == null && (
              <>
                <ButtonType
                  ref={editButtonRef}
                  onClick={toggleEditPopover}
                  variant="outlined"
                  text="Edit"
                  classes="max-h-8 rounded-[8px] text-bodySmall text-gray py-1 px-4"
                />
                <Popover
                  open={editPooperOpen}
                  anchorEl={editButtonRef.current}
                  onClose={closeEditPopover}
                  transformOrigin={{ vertical: -10, horizontal: 'left' }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                >
                  <EncounterStartTimeUpdater
                    closeEditPopover={closeEditPopover}
                    encounterInfo={encounterInfo}
                  />
                </Popover>
              </>
            )}
          </div>
          <Typography variant="h1" customClass={'leading-none'} text={title} />
        </div>
        {!hide && (
          <div className="flex gap-4 shrink-0">
            <ButtonType
              variant="outlined"
              text="Code"
              onClick={() => setCodeModalOpen(true)}
              icon
              disabled={disabled}
              specificIcon={
                <div className="mr-2">
                  <Billing />
                </div>
              }
            />

            {buttonMessageLeft && (
              <ButtonType
                variant="outlined"
                disabled={disabled}
                text={buttonMessageLeft}
                onClick={actionLeft}
                loading={disabled}
              />
            )}
            <ButtonType
              variant="contained"
              disabled={disabled}
              text={buttonMessageRight}
              onClick={actionRight}
              icon
              specificIcon={
                <div className="mr-2">
                  <Signature />
                </div>
              }
            />
          </div>
        )}
      </div>
      <CodingModal
        encounterInfo={encounterInfo}
        selectedCodes={selectedCodes}
        setSelectedCodes={setSelectedCodes}
        open={codeModalOpen}
        handleClose={() => setCodeModalOpen(false)}
        dismiss="Cancel"
        confirm="Save"
      />
    </>
  );
};

export default NoteHeader;
