import { getS3ClientWithCredentials } from './get-s3-client-with-credentials';
import { GetObjectCommand } from '@aws-sdk/client-s3';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';

export const downloadFile = async (s3key: string, fileName: string) => {
  const s3 = await getS3ClientWithCredentials();
  if (s3) {
    const url = await getSignedUrl(
      s3,
      new GetObjectCommand({
        Bucket: import.meta.env.VITE_DOCUMENTS_BUCKET,
        Key: s3key,
        ResponseContentDisposition: 'attachment; filename ="' + fileName + '"',
      }),
      { expiresIn: 60 }
    );
    if (url) {
      window.open(url, '_blank');
    }
  }
  return;
};
