import React, { useState, useEffect, useRef } from 'react';
import Typography from '../../../components/Typography';
import Timer from '../../../assets/icons/Timer';
import Pause from '../../../assets/icons/Pause';
import Reset from '../../../assets/icons/Reset';
import Play from '../../../assets/icons/Play';
import Stop from '../../../assets/icons/Stop';

const TimerComponent: React.FC = () => {
  const [time, setTime] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [isStopped, setIsStopped] = useState(false);
  const [lastStart, setLastStart] = useState(0);
  const [millisElapsedBeforeLastStart, setMillisElapsedBeforeLastStart] =
    useState(0);
  const requestRef = useRef<number>();

  const updateStopwatch = () => {
    const milliseconds = Date.now() - lastStart + millisElapsedBeforeLastStart;
    const seconds = Math.floor(milliseconds / 1000);
    setTime(seconds);
    requestRef.current = requestAnimationFrame(updateStopwatch);
  };

  useEffect(() => {
    if (isRunning) {
      requestRef.current = requestAnimationFrame(updateStopwatch);
    }
    return () => cancelAnimationFrame(requestRef.current!);
  }, [isRunning, lastStart, millisElapsedBeforeLastStart]);

  const formatTime = (time: number): string => {
    const minutes = Math.floor(time / 60)
      .toString()
      .padStart(2, '0');
    const seconds = (time % 60).toString().padStart(2, '0');
    return `${minutes}:${seconds}`;
  };

  const startTimer = () => {
    setLastStart(Date.now());
    setIsRunning(true);
    setIsPaused(false);
    setIsStopped(false);
  };

  const pauseTimer = () => {
    setMillisElapsedBeforeLastStart(
      millisElapsedBeforeLastStart + (Date.now() - lastStart)
    );
    setIsRunning(false);
    setIsPaused(true);
    cancelAnimationFrame(requestRef.current!);
  };

  const stopTimer = () => {
    setMillisElapsedBeforeLastStart(
      millisElapsedBeforeLastStart + (Date.now() - lastStart)
    );
    setIsRunning(false);
    setIsPaused(false);
    setIsStopped(true);
    cancelAnimationFrame(requestRef.current!);
  };

  const resetTimer = () => {
    setTime(0);
    setMillisElapsedBeforeLastStart(0);
    setIsStopped(false);
  };

  return (
    <div className="flex flex-row space-x-2 mr-10">
      <div className="flex flex-row gap-1 mt-1">
        <Timer />
        <Typography
          text={formatTime(time)}
          variant="bodySmall"
          customClass="flex-grow text-center text-green mx-1 pb-2"
        />
      </div>
      {!isRunning && !isPaused && !isStopped && (
        <button onClick={startTimer}>
          <Play />
        </button>
      )}
      {isRunning && (
        <>
          <button onClick={pauseTimer}>
            <Pause />
          </button>
          <button onClick={stopTimer}>
            <Stop />
          </button>
        </>
      )}
      {isPaused && (
        <>
          <button onClick={startTimer}>
            <Play />
          </button>
          <button onClick={stopTimer}>
            <Stop />
          </button>
        </>
      )}
      {isStopped && (
        <>
          <button onClick={startTimer}>
            <Play />
          </button>
          <button onClick={resetTimer}>
            <Reset />
          </button>
        </>
      )}
    </div>
  );
};

export default TimerComponent;
