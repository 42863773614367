import React, { useState } from 'react';

import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import { Link } from 'react-router-dom';
import AsterLogo from '../assets/aster';
import LeftArrow from '../assets/icons/LeftArrow';
import House from '../assets/icons/House';
import Calendar from '../assets/icons/Calendar';
import Clients from '../assets/icons/Clients';
import SignOut from '../assets/icons/SignOut';
import Divider from '@mui/material/Divider';
import { colors } from '../theme';
import Messages from '../assets/icons/Messages';
import Forms from '../assets/icons/Forms';
import Billing from '../assets/icons/Billing';
import Workflows from '../assets/icons/Workflows';
import Programs from '../assets/icons/Programs';
import Documents from '../assets/icons/Documents';
import Analytics from '../assets/icons/Analytics';
import Settings from '../assets/icons/Settings';
import Typography from './Typography';
import { useAuth } from '../authentication/AuthProvider';
import Prescription from '../assets/icons/Prescription';
import { NotificationBadge } from './StyledBadge';
import { useDosespot } from '../features/dosespot/DosespotProvider';

type ItemType = {
  link: string;
  icon: React.ReactElement;
  title: string;
  className?: string;
  children?: React.ReactNode;
};

const MenuOption = (item: ItemType) => {
  return (
    <MenuItem
      component={<Link to={item.link} className={item.className || ''} />}
      icon={item.icon}
    >
      <Typography variant="bodyMedium" text={item.title} />
      {item.children}
    </MenuItem>
  );
};

const Navbar = () => {
  const dosespotContext = useDosespot();

  const [collapsed, setCollapsed] = useState(false);
  const { logout } = useAuth();

  return (
    <Sidebar
      collapsed={collapsed}
      backgroundColor={colors.grayBackground}
      width="180px"
      style={{
        height: '100vh',
        zIndex: 1,
        borderRight: `1px solid ${colors.grayLight}`,
        maxWidth: 180,
      }}
    >
      <Menu
        menuItemStyles={{
          button: {
            backgroundColor: colors.grayBackground,
            '&:hover': {
              backgroundColor: colors.white,
              color: colors.primary,
              borderRadius: '1000px',
            },
          },
        }}
      >
        <div className="grid grid-cols-2 gap-2 w-full my-5">
          <div className={`${collapsed ? 'hidden' : 'inline ml-5'}`}>
            <AsterLogo />
          </div>
          <div
            className={`mt-2 ${
              collapsed ? 'rotate-180 ml-12' : 'flex justify-end mr-3'
            }`}
            onClick={() => {
              setCollapsed(!collapsed);
            }}
          >
            <LeftArrow />
          </div>
        </div>

        <div className={collapsed ? 'pr-1' : ''}>
          <MenuOption link="/" icon={<House />} title="Home" />
          <MenuOption link="/messages" icon={<Messages />} title="Messages" />
          <MenuOption link="/calendar" icon={<Calendar />} title="Calendar" />
          <MenuOption link="/patients" icon={<Clients />} title="Patients" />
          <MenuOption
            className="relative"
            link="/prescription"
            icon={<Prescription />}
            title="Prescriptions"
          >
            {dosespotContext.dosespotData.notificationsCount > 0 && (
              <NotificationBadge
                className="absolute top-[50%] right-6"
                badgeContent={
                  <p className="text-[10px]">
                    {dosespotContext.dosespotData.notificationsCount}
                  </p>
                }
              />
            )}
          </MenuOption>
          <Divider
            className="bg-grayLight"
            sx={{ marginTop: 2, marginBottom: 2 }}
          />
          <MenuOption link="/forms" icon={<Forms />} title="Forms" />
          <MenuOption link="/billing" icon={<Billing />} title="Billing" />
          <MenuOption
            link="/workflows"
            icon={<Workflows />}
            title="Workflows"
          />
          <MenuOption link="/programs" icon={<Programs />} title="Programs" />

          <Divider
            className="bg-grayLight"
            sx={{ marginTop: 2, marginBottom: 2 }}
          />
          <MenuOption
            link="/documents"
            icon={<Documents />}
            title="Documents"
          />
          <MenuOption
            link="/analytics"
            icon={<Analytics />}
            title="Analytics"
          />
          <MenuOption link="/settings" icon={<Settings />} title="Settings" />

          <Divider
            className="bg-grayLight"
            sx={{ marginTop: 2, marginBottom: 1 }}
          />
          <MenuItem
            icon={<SignOut />}
            onClick={() => logout()}
            className="md:mt-[4%] text-body"
          >
            Sign out
          </MenuItem>
        </div>
      </Menu>
    </Sidebar>
  );
};

export default Navbar;
