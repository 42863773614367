import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useAuth } from './AuthProvider';

function SignOutRedirecter() {
  const currentUrl = useLocation();
  const navigate = useNavigate();
  const { logout } = useAuth();

  useEffect(() => {
    logout(currentUrl.pathname);
  }, [currentUrl.pathname, navigate]);

  return null;
}

export default SignOutRedirecter;
