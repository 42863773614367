import React, { useEffect, useState } from 'react';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import ArrowRightRoundedIcon from '@mui/icons-material/ArrowRightRounded';
import { useParams } from 'react-router-dom';
import CollapsibleText from '../../components/CollapsibleText';
import NoteHeader from './sections/NoteHeader';
import Typography from '../../components/Typography';
import RadioOptions from '../../components/RadioOptions';
import womanFigure from '../../assets/Woman_Figure.webp';
import MultipleCheckboxes from '../../components/MultipleCheckboxes';
import { useLeavingPageProtection } from '../../hooks/usePageLeavingProtection';
import TextArea from '../../components/TextArea';
import ConfirmationModal from '../../components/ConfirmationModal';
import { Codes } from './types/encounter';
import { defaultPrenatalData } from './constants';
import TemplateComponent from './templates/TemplateComponent';
import { useAuth } from '../../authentication/AuthProvider';
import { useUpdateEncounterMutation } from './mutations/update-encounter.mutation';
import { useEncounterDetailQuery } from './queries/encounter-detail.query';
import PatientProfileForEncounter from './sections/PatientProfileForEncounter';

type Item = {
  text: string;
  value: string;
  checked: boolean;
};

const defaultFlags: Item[] = [
  {
    text: 'None of the following',
    value: 'None of the following',
    checked: false,
  },
  { text: 'Pain & contractions', value: 'Pain & contractions', checked: false },
  {
    text: 'Visual changes or loss',
    value: 'Visual changes or loss',
    checked: false,
  },
  { text: 'Vaginal bleeding', value: 'Vaginal bleeding', checked: false },
  { text: 'Sudden swelling', value: 'Sudden swelling', checked: false },
  {
    text: 'Reduced fetal movement',
    value: 'Reduced fetal movement',
    checked: false,
  },
  { text: 'Headaches', value: 'Headaches', checked: false },
  { text: 'RUQ pain', value: 'RUQ pain', checked: false },
  { text: 'Leaking of fluid', value: 'Leaking of fluid', checked: false },
  { text: 'Other', value: 'Other', checked: false },
];

const PrenatalNote = () => {
  const { patient, encounterId } = useParams();
  const [isCollapsed, setCollapsed] = useState(true);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [flagItems, setFlagItems] = useState<Item[]>(defaultFlags);
  const [isDirty, setIsDirty] = useState(false);
  const [enforceSaveMissing, setEnforceSaveMissing] = useState(false);
  const [state, setState] = useState({
    notes: '',
    extraFlag: '',
    imaging: '',
    mood: '',
    safety: '',
    plan: '',
    measurement: '',
    measurementData: '',
    pain: null,
    painInformation: '',
    fetalHeartRate: '',
  });
  const [signed, setSigned] = useState(false);
  const [selectedCodes, setSelectedCodes] = useState<Codes>({
    cptCodes: [],
    icdCodes: [],
  });

  const { encounterData } = useEncounterDetailQuery(encounterId as string);

  const handleChangeCodes = (codes: Codes) => {
    setSelectedCodes(codes);
  };

  useEffect(() => {
    if (encounterData) {
      const {
        notes,
        extraFlag,
        imaging,
        mood,
        safety,
        plan,
        prenatalExamination,
      } = encounterData.content || defaultPrenatalData;

      setState({
        notes,
        extraFlag,
        imaging,
        mood,
        safety,
        plan,
        measurement: prenatalExamination?.measurement,
        measurementData: prenatalExamination?.measurementData,
        pain: prenatalExamination?.pain,
        painInformation: prenatalExamination?.painInformation,
        fetalHeartRate: prenatalExamination?.fetalHeartRate,
      });

      const flagData = encounterData.content?.flags || defaultFlags;
      const { cptCodes, icdCodes } = encounterData;

      setFlagItems(flagData);
      setSelectedCodes({ cptCodes, icdCodes });
      setSigned(encounterData.signedBy !== null);
    }
  }, [encounterData]);

  const { updateEncounter } = useUpdateEncounterMutation(encounterId, () => {
    setIsDirty(false);
    setEnforceSaveMissing(false);
  });

  const saveInfo = (signed: boolean) => {
    setSigned(signed);
    const info = {
      patientId: patient,
      templateType: 'prenatal' as const,
      signed,
      content: {
        notes: state.notes,
        flags: flagItems,
        extraFlag: state.extraFlag,
        imaging: state.imaging,
        mood: state.mood,
        safety: state.safety,
        plan: state.plan,
        prenatalExamination: {
          measurement: state.measurement,
          measurementData: state.measurementData,
          pain: state.pain,
          painInformation: state.painInformation,
          fetalHeartRate: state.fetalHeartRate,
        },
      },
      cptCodes: selectedCodes.cptCodes,
      icdCodes: selectedCodes.icdCodes,
    };
    if (signed) {
      setOpenConfirmation(false);
    }
    updateEncounter.mutate(info);
  };

  const toggleCollapse = () => {
    setCollapsed(!isCollapsed);
  };

  const handleFormInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setIsDirty(true);
  };

  const handleChangeNote = (content: string) => {
    setState((prevState) => ({
      ...prevState,
      notes: content,
    }));
    setIsDirty(true);
  };

  const handleOption = (
    event: React.ChangeEvent<HTMLInputElement>,
    option: string
  ) => {
    setState((prevState) => ({
      ...prevState,
      [option]: event.target.value,
    }));
    setIsDirty(true);
  };

  const handleCheckboxChange = (value: string) => {
    setFlagItems((prevItems) =>
      prevItems.map((item) =>
        item.value === value ? { ...item, checked: !item.checked } : item
      )
    );
    setIsDirty(true);
  };

  const { profile } = useAuth();

  const { blocker } = useLeavingPageProtection({ canLeave: !isDirty });

  const handleClose = (confirmed: boolean) => {
    if (confirmed) {
      saveInfo(false);
      setEnforceSaveMissing(true);
      blocker?.reset?.();
    } else {
      blocker?.proceed?.();
      setIsDirty(false);
      setEnforceSaveMissing(false);
    }
  };

  const handleUnsaved = (isDirty: boolean) => {
    setIsDirty(isDirty);
  };

  const buttons = [
    { value: 'prenatalIniVis', text: 'Inital Visit' },
    { value: 'week1014', text: '10-14' },
    { value: 'week1519', text: '15-19' },
  ];

  return (
    <div className="flex flex-col lg:flex-row overflow-auto">
      <div className="w-fit lg:max-w-[400px]">
        <PatientProfileForEncounter
          isEncounterSigned={signed}
          enforceSaveMissing={enforceSaveMissing}
          handleUnsaved={handleUnsaved}
        />
      </div>
      <div className="flex flex-col mt-5 px-10 flex-grow lg:overflow-y-auto">
        <NoteHeader
          encounterInfo={encounterData}
          selectedCodes={selectedCodes}
          disabled={updateEncounter.status === 'pending'}
          setSelectedCodes={handleChangeCodes}
          title="Prenatal Note"
          buttonMessageLeft="Save draft"
          actionLeft={() => saveInfo(false)}
          buttonMessageRight="Sign note"
          actionRight={() => setOpenConfirmation(true)}
          hide={signed}
        />

        <div className="flex flex-col md:flex-row pb-10 scroll-pb-10">
          {/* Middle section */}
          <div className="flex flex-col gap-[15px] w-full mb-10 min-w-[360px]">
            <div className="w-full">
              <TemplateComponent
                noteInfo={state.notes}
                label="Notes"
                signed={signed}
                handleChangeNote={handleChangeNote}
                buttons={buttons}
              />
            </div>
            <div className="w-full">
              <div className="w-full">
                <button
                  onClick={toggleCollapse}
                  className="w-full rounded-lg hover:bg-grayBackground flex justify-start items-center mb-2"
                >
                  {!isCollapsed && <ArrowRightRoundedIcon />}
                  {isCollapsed && <ArrowDropDownRoundedIcon />}
                  <Typography variant="h6" text="Flags" />
                </button>
              </div>
              {isCollapsed && (
                <>
                  <div className="flex flex-row w-full">
                    <MultipleCheckboxes
                      handleCheckboxChange={handleCheckboxChange}
                      items={flagItems}
                      separateInHalf={!(window.innerWidth < 900)}
                      disabled={signed}
                    />
                  </div>
                  <TextArea
                    name="extraFlag"
                    textValue={state.extraFlag}
                    handleChange={handleFormInput}
                    disabled={signed}
                  />
                </>
              )}
            </div>
            <div>
              <CollapsibleText
                label="Imaging"
                textValue={state.imaging}
                name="imaging"
                handleChange={handleFormInput}
                disabled={signed}
              />
            </div>
            <div>
              <CollapsibleText
                label="Mood"
                textValue={state.mood}
                name="mood"
                handleChange={handleFormInput}
                disabled={signed}
              />
            </div>
            <div>
              <CollapsibleText
                label="Safety"
                textValue={state.safety}
                name="safety"
                handleChange={handleFormInput}
                disabled={signed}
              />
            </div>
            <div>
              <CollapsibleText
                label="Plan"
                textValue={state.plan}
                name="plan"
                handleChange={handleFormInput}
                disabled={signed}
              />
            </div>
          </div>
          {/* Examination Section */}
          <div className="md:w-1/3 my-2 md:my-0 mx-5 flex flex-col">
            <Typography variant="h6" text="Examination" />
            <div className="w-full mt-2">
              <img src={womanFigure} alt="Woman Figure" className="my-2" />
            </div>

            <div className="mt-10">
              <RadioOptions
                classes="mb-1"
                disabled={signed}
                value={state.measurement}
                handleChange={(event: any) =>
                  handleOption(event, 'measurement')
                }
                options={[
                  {
                    value: 'uteruspalpation',
                    text: 'Uterus palpation',
                    key: 'uteruspalpation',
                  },
                  { value: 'sfh', text: 'FH (cm)', key: 'sfh' },
                ]}
              />
              <TextArea
                name="measurementData"
                textValue={state.measurementData}
                topLabel=" "
                placeholder=" "
                handleChange={handleFormInput}
                border="border-grayBackground"
                disabled={signed}
              />
            </div>
            <div>
              <RadioOptions
                classes="mb-1"
                disabled={signed}
                value={state.pain}
                handleChange={(event: any) => handleOption(event, 'pain')}
                options={[
                  {
                    value: 'nopain',
                    text: 'No Pain',
                    key: 'nopain',
                  },
                  { value: 'pain', text: 'Pain', key: 'pain' },
                ]}
              />
              <TextArea
                name="painInformation"
                textValue={state.painInformation}
                topLabel=" "
                placeholder=" "
                handleChange={handleFormInput}
                border="border-grayBackground"
                disabled={signed}
              />
            </div>
            <div className="mt-5">
              <TextArea
                name="fetalHeartRate"
                textValue={state.fetalHeartRate}
                topLabel="Fetal Heart Rate (bpm)"
                topLabelVariant="body"
                placeholder=" "
                handleChange={handleFormInput}
                border="border-grayBackground"
                disabled={signed}
              />
            </div>
          </div>
        </div>
      </div>
      <ConfirmationModal
        open={openConfirmation}
        title="Sign this encounter note?"
        description={`You're signing as ${profile && profile.firstName} ${
          profile && profile.lastName
        }`}
        confirm="Sign"
        dismiss="Cancel"
        handleClose={() => setOpenConfirmation(false)}
        handleConfirm={() => saveInfo(true)}
        handleCancel={() => setOpenConfirmation(false)}
      />
      <ConfirmationModal
        open={blocker.state === 'blocked'}
        title="Unsaved Changes"
        description={`Are you sure you want to leave? Changes may not be saved.`}
        confirm="Save"
        dismiss="Leave"
        handleClose={() => handleClose(true)}
        handleConfirm={() => handleClose(true)}
        handleCancel={() => handleClose(false)}
      />
    </div>
  );
};

export default PrenatalNote;
