import React, { useCallback, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import BasicTextfield from '../../../components/Textfield';
import ButtonType from '../../../components/Button';
import Typography from '../../../components/Typography';
import dayjs from 'dayjs';
import Apgar from './Apgar';
import InfoIcon from '@mui/icons-material/Info';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import NRP from '../../../assets/nrp.pdf';
import BabyVitals from './BabyVitals';
import TimerComponent from './Timer';
import { customTwMerge } from '../../../utils';
import { ApgarInfo } from './types/apgar.types';
import { BabyVitalsInfo, ResuscitationInfo } from './types/baby.types';
import { MilestoneInfo } from './types/birth.types';

const Milestone = React.memo(
  ({
    title,
    date,
    name,
    disabled,
    handleClick,
  }: MilestoneInfo & {
    disabled: boolean;
    handleClick: (name: string, title: string) => void;
  }) => {
    return (
      <button
        className={`flex justify-center items-center my-2.5 hover:bg-grayBackground ${
          disabled ? 'bg-grayCard pointer-events-none' : 'bg-white'
        } rounded-xl w-full`}
        onClick={() => handleClick(name, title)}
      >
        <div className="flex flex-grow items-start mx-2 my-3">
          <Typography
            text={title}
            variant="bodySmall"
            customClass="font-semibold py-1"
          />
        </div>

        <div className="flex items-center justify-center p-2 mr-1 rounded-xl bg-stone-50 text-ellipsis text-bodySmall">
          {date ? dayjs(date).format('MM/DD/YYYY HH:mm') : 'Date/Time'}
        </div>
      </button>
    );
  }
);

type ResuscitationProps = {
  open: boolean;
  setClose: (shouldSave: boolean) => void;
  disabled: boolean;
  data: ResuscitationInfo;
  apgar: ApgarInfo[];
  babyVitals: BabyVitalsInfo[];
  handleNote: (note: string) => void;
  handleSave: (
    property: string,
    value: ResuscitationInfo | Partial<ApgarInfo>[] | Partial<BabyVitalsInfo>[]
  ) => void;
};

const Resuscitation = React.memo(
  ({
    open,
    setClose,
    disabled,
    data,
    apgar,
    babyVitals,
    handleSave,
    handleNote,
  }: ResuscitationProps) => {
    const [openNRP, setOpenNRP] = useState(false);
    const [note, setNote] = useState(data?.resNote ?? '');
    const [additionalNote, setAdditionalNote] = useState(
      data?.additionalNote ?? ''
    );
    const [emsNote, setEmsNote] = useState(data?.emsNote ?? '');

    const handleOpen = useCallback(() => {
      setOpenNRP(true);
    }, []);

    const handleClose = useCallback(() => {
      setOpenNRP(false);
    }, []);

    const newNote = useCallback(
      (property: string, value: string) => {
        const note = `Resuscitation: ${
          property ? `${property}: ` : ''
        } ${value} at ${dayjs(new Date()).format('MM/DD/YYYY HH:mm:ss')}`;
        handleNote(note);
      },
      [handleNote]
    );

    const handleClick = useCallback(
      (name: string, title: string) => {
        handleSave('resuscitation', { ...data, [name]: dayjs().toISOString() });
        newNote('', title);
      },
      [data, handleSave, newNote]
    );

    const onSave = () => {
      if (note !== '' && note !== data.resNote) {
        handleSave('resuscitation', { ...data, resNote: note });
        newNote('Note', note);
      }
      if (additionalNote !== '' && additionalNote !== data.additionalNote) {
        handleSave('resuscitation', {
          ...data,
          additionalNote: additionalNote,
        });
        newNote('Additional Note', additionalNote);
      }
      if (emsNote !== '' && emsNote !== data.emsNote) {
        handleSave('resuscitation', { ...data, emsNote: emsNote });
        newNote('EMS Note', emsNote);
      }
      setClose(true);
    };

    return (
      <Dialog
        fullWidth
        maxWidth={openNRP ? 'xl' : 'md'}
        open={open}
        onClose={setClose}
      >
        <DialogContent>
          <div className="flex flex-col">
            <div className="flex items-center justify-between w-full">
              <Typography
                text="Resuscitation"
                variant="h4"
                customClass="flex-grow text-center ml-20"
              />
              <TimerComponent />

              <ButtonType
                variant="outlined"
                icon
                specificIcon={<InfoIcon className="mr-1 text-gray" />}
                text="Reference"
                notRounded
                classes={customTwMerge('text-gray', openNRP ? 'mr-40' : 'mr-4')}
                onClick={handleOpen}
              />
              {openNRP && (
                <Typography
                  text="Neonatal Resuscitation Program"
                  variant="h4"
                  customClass="flex-grow text-center mr-10"
                />
              )}
            </div>

            <div className="flex flex-row mx-4 overflow-hidden">
              <div className="flex flex-col md:w-2/3 h-full m-2">
                {/* Initial */}
                <div className="flex flex-col h-full rounded-lg bg-grayCard px-5">
                  <Typography text="Initial" variant="h6" />
                  <div className="flex flex-row gap-2 justify-between">
                    <Milestone
                      title="Dry"
                      name="dry"
                      date={data?.dry}
                      handleClick={handleClick}
                      disabled={disabled}
                    />
                    <Milestone
                      title="Stimulate"
                      name="stimulate"
                      date={data?.stimulate}
                      handleClick={handleClick}
                      disabled={disabled}
                    />
                    <Milestone
                      title="Suction"
                      name="suction"
                      date={data?.suction}
                      handleClick={handleClick}
                      disabled={disabled}
                    />
                  </div>
                </div>

                {/* Res steps */}
                <div className="h-full rounded-lg bg-grayCard px-5 mt-5">
                  <Typography text="Resuscitation steps" variant="h6" />
                  <div className="flex flex-row gap-5 h-ful">
                    <div className="flex flex-col w-1/2">
                      <Milestone
                        title="Oxygen"
                        name="oxigen"
                        date={data?.oxigen}
                        handleClick={handleClick}
                        disabled={disabled}
                      />
                      <Milestone
                        title="LMA/iGel"
                        name="gel"
                        date={data?.gel}
                        handleClick={handleClick}
                        disabled={disabled}
                      />
                      <Milestone
                        title="Epinephrine"
                        name="epi"
                        date={data?.epi}
                        handleClick={handleClick}
                        disabled={disabled}
                      />
                    </div>
                    <div className="flex flex-col w-1/2">
                      <Milestone
                        title="PPV/NCPAP"
                        name="ppv"
                        date={data?.ppv}
                        handleClick={handleClick}
                        disabled={disabled}
                      />
                      <Milestone
                        title="Chest compressions"
                        name="chest"
                        date={data?.chest}
                        handleClick={handleClick}
                        disabled={disabled}
                      />
                      <Milestone
                        title="Other"
                        name="other"
                        date={data?.other}
                        handleClick={handleClick}
                        disabled={disabled}
                      />
                    </div>
                  </div>
                  <br />
                  <BasicTextfield
                    id="note"
                    variant="filled"
                    name="resNote"
                    label="Note"
                    disabled={disabled}
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                    classes="mb-4"
                  />
                </div>
                {/* APGGAR */}
                <br />
                <div className="flex flex-col">
                  <Apgar
                    handleNote={handleNote}
                    handleSave={handleSave}
                    disabled={disabled}
                    data={apgar}
                    isGray
                  />
                  <div className="px-2.5 mt-2 rounded-lg bg-grayCard py-2">
                    <Typography
                      variant="h6"
                      text="Additional Notes"
                      customClass="rounded-t-lg bg-grayCard w-[101%]"
                    />
                    <BasicTextfield
                      id="additional-note"
                      variant="filled"
                      classes="mb-2"
                      name="additionalNote"
                      label="Insert new note..."
                      disabled={disabled}
                      value={additionalNote}
                      onChange={(e) => setAdditionalNote(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col md:w-1/3 h-full">
                {/* Vitals */}
                <div className="px-2.5 mt-2 rounded-lg bg-beige py-2">
                  <Typography
                    variant="h6"
                    text="Vitals"
                    customClass="rounded-t-lg bg-beige w-[101%] pl-3"
                  />
                  <BabyVitals
                    displayVertical
                    disabled={disabled}
                    data={babyVitals}
                    handleSave={handleSave}
                    handleNote={handleNote}
                  />
                </div>

                {/* EMS */}
                <div className="px-2.5 mt-2 rounded-lg bg-grayCard py-2">
                  <Typography
                    variant="h6"
                    text="EMS"
                    customClass="rounded-t-lg w-[101%] pl-3"
                  />

                  <Milestone
                    title="Call"
                    name="emsCall"
                    date={data?.emsCall}
                    handleClick={handleClick}
                    disabled={disabled}
                  />
                  <Milestone
                    title="Arrival"
                    name="emsArrival"
                    date={data?.emsArrival}
                    handleClick={handleClick}
                    disabled={disabled}
                  />
                  <Milestone
                    title="Departure"
                    name="emsDeparture"
                    date={data?.emsDeparture}
                    handleClick={handleClick}
                    disabled={disabled}
                  />

                  <BasicTextfield
                    id="note"
                    variant="filled"
                    classes="mb-2"
                    name="emsNote"
                    label="Note"
                    disabled={disabled}
                    value={emsNote}
                    onChange={(e) => setEmsNote(e.target.value)}
                  />
                </div>
              </div>
              {openNRP && (
                <div className="w-full shadow-xl ml-10 ">
                  <Worker
                    workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}
                  >
                    <Viewer fileUrl={NRP} />
                  </Worker>
                </div>
              )}
            </div>
          </div>
        </DialogContent>
        <DialogActions className="flex items-center justify-end w-full">
          <ButtonType variant="contained" text="Save" onClick={onSave} />
          {openNRP && (
            <ButtonType
              variant="contained"
              text="Close"
              onClick={handleClose}
            />
          )}
        </DialogActions>
      </Dialog>
    );
  }
);

export default Resuscitation;
