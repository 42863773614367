import { PatientProfileForEncounterDTO } from '@aster/shared/dtos/patient';
import {
  displayAge,
  displayBirthDate,
  displayGTPAL,
  displayGestationalAge,
} from '../templateUtils';

export const laborAdmissionNote = (
  patientData?: PatientProfileForEncounterDTO,
  profile?: any
) => `
<strong> History:</strong><br>

${displayAge(patientData?.age)} ${displayGTPAL(
  patientData?.gtpal
)} estimated by ${displayBirthDate(patientData?.edd, patientData?.lmp)}<br>

Presenting to <strong>${profile.practiceName}</strong> for:<br>
Active labor<br>

<strong> ${
  patientData?.name
}</strong> reports continued fetal movement and no vaginal bleeding. <strong>${
  patientData?.name
}</strong> reports spontaneous onset of labor at <strong>XXXX</strong> SROM at <strong>XXXX</strong><br>

Membranes are ruptured with <strong>XXXX</strong><br>
ROM confirmed by pt observation<br>

Labs/Ultrasounds:<br>

Blood Type <strong>XXXX</strong><br>
Antibody Screen:<br>
RPR:<br>
HBV S Ag:<br>
HIV Status:<br>
CT/NG:br>
Rubella status:<br>
GBS:<br>
GDM:<br>
<br>
Anatomy scan:<br>
<br>
Most recent CBC on <strong>XX/XX/XXXX</strong> shows Hemoglobin is <strong>XX</strong><br>
<br>
Pregnancy complications:<br>
<br>
Medical History: <strong>XXXXXXXX</strong><br>
<br>
<strong> EXAM:</strong><br>
General:<br>
S/Sx of preeclampsia are not present<br>
She ate <strong>________</strong> at <strong>XX:XX</strong><br>
She has been drinking water and is well hydrated.<br>
She is accompanied by husband and doula for support<br>
Abdomen: Gravid, soft, nontender. FM palpable<br>
Extremities: Warm and well perfused. No clonus. Reflexes NML<br>
Pelvic: <strong>[WNL. No lesions, sores or abnormalities found. / Declined]</strong><br>

Fetus: ___ presentation and _____ position by <strong>[Leopold's, cervical exam, ultrasound]</strong>.<br>
EFW is <strong>_____</strong>g
FHT baseline is <strong>XXX</strong> with accelerations +/- without decelerations in relation to movement and uterine contractions.<br>
<br>
Ctx now are regular, every <strong>X</strong> min, lasting <strong>XX</strong> seconds, <strong>XXXX</strong> in intensity.<br>
<br>
<strong> Plan:</strong><br>
Meets all risk criteria for intrapartum care at <strong>${
  profile.practiceName
}</strong>. No indications for physician consult present.<br>
<br>
Admit to <strong>${
  profile.practiceName
}</strong> for management of labor with anticipated NSVD.<br>
`;

export const history = (
  patientData?: PatientProfileForEncounterDTO,
  profile?: any
) =>
  `<strong>${patientData?.name}</strong> is a${displayAge(
    patientData?.age
  )} ${displayGTPAL(patientData?.gtpal)} at <strong>${displayGestationalAge(
    patientData?.edd,
    patientData?.lmp
  )}</strong> estimated by ${displayBirthDate(
    patientData?.edd,
    patientData?.lmp
  )} attending <strong>${profile.practiceName}</strong> for care.
`;

export const labs = () =>
  ` 
   <br> Date of lab results:  <strong>XX/XX/XX</strong> at Gestational Age: <strong>XXW+XD</strong><br>
  <strong> CBC:</strong><br>
  Blood Type: <strong>XXXX </strong><br>
  Antibody Screen: <br>
  RPR: <br>
  HBV S Ag: <br>
  HIV Status: <br>
  CT/NG: <br>
  Rubella status:<br>
  GBS: <br>
  GDM: <br>
`;

export const imaging = () =>
  ` Ultrasound scan at <strong>XX</strong> Weeks on <strong>XX/XX</strong> (date)<br>
  Performed at <strong>XX Clinic</strong><br>
  <strong> Results:</strong><br>
  EFW:<br>
  Biparietal diameter (BPD):<br>
  Head circumference (HC):<br>
  Abdominal circumference (AC):<br>
  Femur length (FL):<br>
  Placental position:<br>
  Additional measurements:<br>
`;

export const initialOB = (
  patientData?: PatientProfileForEncounterDTO,
  profile?: any
) => `
<strong>${patientData?.name}</strong> is a${displayAge(
  patientData?.age
)} ${displayGTPAL(patientData?.gtpal)}, attended <strong>${
  profile.practiceName
}</strong> with partner, <strong>[Partner First Name]</strong>, for an initial prenatal with <strong>${
  profile.firstName
} ${profile.lastName}</strong>.<br>
Previously seeing <strong>${
  profile.practiceName
}</strong>, and desires an out-of-hospital birth.<br>
Reports _____<br>
<strong> History:</strong><br>
OB hx:<br>
EDD: <strong>${patientData?.edd ? patientData?.edd : ''}</strong><br>
LMP: <strong>${patientData?.lmp ? patientData?.lmp : ''}</strong><br>
Conception/date of intercourse:<br>
Cycles q 28-30 days<br>
Positive pregnancy test:<br>
U/S :<br>
Final EDD:<br>
GP0000<br>
Previous pregnancies: DOB, GA, length of labor, type of delivery, male/female, lacerations, medicated vs unmedicated, breastfed, issues with labor/birth/PP<br>
PMH:<br>
PSH:<br>
Gyn hx:<br>
STDs:<br>
Genital herpes: <br>
Last PAP:<br>
Results of last PAP:<br>
Hx of abnormal PAPs:<br>
Hx of LEEP:<br>
Menarche:<br>
Allergies: NKDA<br>
Psych hx:<br>
Fam hx:<br>
History of genetic conditions on either side of family:<br>
Genetic screening:<br>
Diet/exercise:<br>
Medications and supplements:<br>
Height/pre pregnancy weight/BMI:<br>
Current weight:<br>
`;

export const laborSoap = (patientData?: PatientProfileForEncounterDTO) =>
  `
  <strong> S: ${patientData?.name}</strong> here w/, feels _____<br>
  <strong> O:</strong> Vitals: <strong>[Insert Vitals]</strong><br>
  <strong> Contractions: </strong><br>
  FHR baseline____ with <strong>[variables/accelerations/decelerations]</strong><br>
  SVE/SSE:<br>
  ROM at <strong>XX</strong> Hours<br>
  GBS status _____<br>
  <strong>A: ${patientData?.name}</strong> is ${displayGTPAL(
    patientData?.gtpal
  )} here for _______ labor/progress, coping, cat, ID<br>
  <strong>P:</strong> IA, IV, pain meds, labor support, AMTSL, consult, monitoring, RT<br>
  <strong>Risk assessment:</strong> remains low risk and suitable for OOH delivery<br>
`;

export const soap = (
  patientData?: PatientProfileForEncounterDTO,
  profile?: any
) =>
  `<strong>${patientData?.name}</strong> is a${displayAge(
    patientData?.age
  )} ${displayGTPAL(patientData?.gtpal)} at <strong>${displayGestationalAge(
    patientData?.edd,
    patientData?.lmp
  )}</strong> estimated by ${displayBirthDate(
    patientData?.edd,
    patientData?.lmp
  )} attending <strong>${profile.practiceName}</strong> for care.<br>
  <br>
  <strong>Subjective:</strong><br>
  <strong>Objective:</strong><br>
  <strong>Assessment:</strong><br>
  <strong>Plan:</strong><br>
`;
