import { useState } from 'react';
import { Box, SelectChangeEvent } from '@mui/material';
import SelectDropdown from '../../components/SelectDropdown';

const CalendarViewMenu = (props: any) => {
  const { calendarRef } = props;
  const [view, setView] = useState('4day');

  const handleChange = (event: SelectChangeEvent) => {
    const value = event.target.value as string;
    setView(value);
    let calendarView = '';
    switch (value) {
      case 'Day':
        calendarView = 'timeGridDay';
        break;
      case 'Week':
        calendarView = 'timeGridWeek';
        break;
      case 'Month':
        calendarView = 'dayGridMonth';
        break;
      case 'Year':
        calendarView = 'multiMonthYear';
        break;
      case 'Schedule':
        calendarView = 'listMonth';
        break;
      case '4day':
        calendarView = 'timeGridFourDay';
        break;
      default:
        calendarView = 'timeGridWeek';
        break;
    }
    calendarRef.current?.getApi().changeView(calendarView);
  };

  const Options = [
    { value: 'Day', text: 'Day' },
    { value: 'Week', text: 'Week' },
    { value: 'Month', text: 'Month' },
    { value: 'Year', text: 'Year' },
    { value: 'Divider' },
    { value: 'Schedule', text: 'Schedule' },
    { value: '4day', text: '4 day' },
  ];

  return (
    <Box sx={{ minWidth: 160 }}>
      <SelectDropdown
        options={Options}
        handleChange={handleChange}
        value={view}
        label=""
        width="120px"
      />
    </Box>
  );
};

export default CalendarViewMenu;
