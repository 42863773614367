import { useRef, useState } from 'react';
import { Divider, CircularProgress } from '@mui/material';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import listPlugin from '@fullcalendar/list';
import multiMonthPlugin from '@fullcalendar/multimonth';
import CalendarSidebar from './CalendarSidebar';
import CalendarHeader from './CalendarHeader';
import ConfirmationModal from '../../components/ConfirmationModal';
import CreateApptModal from './modals/CreateApptModal';
import { rgbWithOpacity, parseAppt, parseApptColor } from '../../utils';
import EditApptModal from './modals/EditApptModal';
import axios from '../../app/axiosConfig';
import {
  useQuery,
  useMutation,
  useQueryClient,
  keepPreviousData,
} from '@tanstack/react-query';
import { logAnalyticEvent } from '../../app/firebase';
import dayjs, { Dayjs } from 'dayjs';
import {
  DateSelectArg,
  DatesSetArg,
  DayHeaderContentArg,
  EventClickArg,
  EventContentArg,
  EventInput,
  EventSourceInput,
} from '@fullcalendar/core';
import { CalendarWrapper } from './CalendarWrapper';
import Typography from '../../components/Typography';
import {
  AppointmentDTO,
  AppointmentWithInviteesDTO,
  CreateAppointmentDTO,
  InviteeDTO,
  Type,
  UpdateAppointmentDTO,
} from '@aster/shared/dtos/appointment';
import { EventImpl } from '@fullcalendar/core/internal';
import { useSnackbar } from '../../components/Snack';
import { NewEvent } from './types';

type CalendarViewTypes =
  // 4 days
  | 'timeGridFourDay'
  // Schedule
  | 'listMonth'
  // Month
  | 'dayGridMonth'
  // Year
  | 'multiMonthYear'
  // Week
  | 'timeGridWeek'
  // Day
  | 'timeGridDay';

const CalendarApp = () => {
  const calendarRef = useRef<FullCalendar | null>(null);
  const [saveEvents, setSaveEvents] = useState<EventInput[]>([]);
  const [currentDate, setCurrentDate] = useState<DatesSetArg>();
  const slotDuration = '00:15:00';
  const slotLabelInterval = { hours: 1 };
  const slotMinTime = '00:00:00';
  const slotMaxTime = '23:59:00';
  const [openDel, setOpenDelete] = useState(false);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<EventClickArg | null>(
    null
  );
  const [newEvent, setNewEvent] = useState<NewEvent | null>(null);
  const calendarAPI = calendarRef.current?.getApi();

  const today = dayjs();
  const timeRef = useRef({
    startTime: today.startOf('day').format(),
    endTime: today.add(4, 'day').startOf('day').format(),
  });

  const { showMessage } = useSnackbar();

  const queryClient = useQueryClient();

  const fetchAppts = async () => {
    const response = await axios.get<
      (AppointmentWithInviteesDTO & { loading: boolean })[]
    >(
      `appointments?startTime=${dayjs(
        timeRef.current.startTime
      ).toISOString()}&endTime=${dayjs(timeRef.current.endTime).toISOString()}`
    );
    return response.data;
  };

  const { data, isLoading, refetch } = useQuery({
    queryKey: [
      'fetchAppts',
      timeRef.current.startTime,
      timeRef.current.endTime,
    ],
    queryFn: fetchAppts,
    placeholderData: keepPreviousData,
  });

  const parseEvents = (
    events: (AppointmentWithInviteesDTO & { loading: boolean })[]
  ) => {
    return events.map((event) => ({
      id: event.id,
      invitedPatients: event.invitedPatients,
      invitedStaffs: event.invitedStaff,
      title: event.type,
      start: event.startTime,
      end: event.endTime,
      note: event.note,
      appointment: parseAppt(event.type),
      telehealth: event.telehealth,
      backgroundColor: rgbWithOpacity(parseApptColor(event.type)),
      borderColor: parseApptColor(event.type),
      editable: event.id && !event.loading,
      loading: event.loading ? event.loading : false,
    })) as EventSourceInput;
  };

  const formattedEvents = data ? parseEvents(data) : [];

  const createApptMutation = async (newAppt: CreateAppointmentDTO) =>
    axios.post('/appointments', newAppt);

  const addApptMutation = useMutation({
    mutationKey: ['createAppt'],
    mutationFn: createApptMutation,
    onMutate: (newAppt: CreateAppointmentDTO) => {
      const previousFetchedAppts = queryClient.getQueryData([
        'fetchAppts',
        timeRef.current.startTime,
        timeRef.current.endTime,
      ]);
      queryClient.setQueryData<CreateAppointmentDTO[]>(
        ['fetchAppts', timeRef.current.startTime, timeRef.current.endTime],
        (old) => {
          if (!old) return [newAppt];
          return [...old, newAppt];
        }
      );
      return { previousFetchedAppts };
    },
    onError: (error, newAppt, context) => {
      queryClient.setQueryData(
        ['fetchAppts', timeRef.current.startTime, timeRef.current.endTime],
        context?.previousFetchedAppts
      );
      showMessage({
        message: 'Error creating appointment',
        type: 'error',
      });
    },
    onSuccess: () => {
      refetch();
      showMessage({
        message: 'Appointment created',
        type: 'success',
      });
      logAnalyticEvent('calendar', 'create_appt');
    },
    onSettled: () => {
      setNewEvent(null);
    },
  });

  const updateApptMutation = async (
    newAppt: UpdateAppointmentDTO & { loading: boolean }
  ) => axios.put(`/appointments/${newAppt.id}`, newAppt);

  const editApptMutation = useMutation({
    mutationKey: ['updateAppt'],
    mutationFn: updateApptMutation,
    onMutate: (newAppt) => {
      newAppt.loading = true;

      const previousFetchedAppts = queryClient.getQueryData([
        'fetchAppts',
        timeRef.current.startTime,
        timeRef.current.endTime,
      ]);
      queryClient.setQueryData<Partial<AppointmentDTO>[]>(
        ['fetchAppts', timeRef.current.startTime, timeRef.current.endTime],
        (old) => {
          if (!old) return [newAppt];
          return old.map((e) => (e.id === newAppt.id ? newAppt : e));
        }
      );
      return { previousFetchedAppts };
    },
    onError: (error, newAppt, context) => {
      queryClient.setQueryData(
        ['fetchAppts', timeRef.current.startTime, timeRef.current.endTime],
        context?.previousFetchedAppts
      );
      showMessage({
        message: 'Error updating appointment',
        type: 'error',
      });
    },
    onSuccess: () => {
      refetch();
      showMessage({
        message: 'Appointment updated',
        type: 'success',
      });
      logAnalyticEvent('calendar', 'edit_appt');
    },
    onSettled: () => {
      setSelectedEvent(null);
    },
  });

  const deleteAppointment = async (appt: EventClickArg) => {
    const id = appt?.event?._def?.publicId;
    return axios.delete(`/appointments/${id}`);
  };

  const deleteApptMutation = useMutation({
    mutationKey: ['deleteAppt'],
    mutationFn: deleteAppointment,
    onMutate: (appt) => {
      const previousFetchedAppts = queryClient.getQueryData([
        'fetchAppts',
        timeRef.current.startTime,
        timeRef.current.endTime,
      ]);
      queryClient.setQueryData<AppointmentDTO[]>(
        ['fetchAppts', timeRef.current.startTime, timeRef.current.endTime],
        (old) => {
          if (!old) return [];
          return old.filter((e) => e.id !== appt.event._def.publicId);
        }
      );
      return { previousFetchedAppts };
    },
    onError: (error, appt, context) => {
      queryClient.setQueryData(
        ['fetchAppts', timeRef.current.startTime, timeRef.current.endTime],
        context?.previousFetchedAppts
      );
      showMessage({
        message: 'Error deleting appointment',
        type: 'error',
      });
    },
    onSuccess: () => {
      refetch();
      showMessage({
        message: 'Appointment deleted',
        type: 'success',
      });
    },
  });

  const handleCreateAppt = () => {
    setNewEvent({
      start: dayjs(currentDate?.start).set('hour', 7).set('minute', 0),
      end: dayjs(currentDate?.start).set('hour', 7).set('minute', 30),
    });
    setOpenCreateModal(true);
  };

  const handleNav = (date: Dayjs) => {
    calendarAPI?.gotoDate(date.toDate());
  };

  const handleEventRemove = () => {
    if (selectedEvent) {
      selectedEvent?.event.remove();
      deleteApptMutation.mutate(selectedEvent);
      logAnalyticEvent('calendar', 'delete_appt');
      setOpenDelete(false);
    }
  };

  const handleCreateEvent = (
    info: Partial<EventImpl> & Record<string, any>,
    typeEvent: string
  ) => {
    let tempRows: EventInput[] = saveEvents;

    let createInfo: EventInput;

    if (typeEvent === 'drag') {
      createInfo = {
        id: info?._def?.publicId,
        invitedPatientIDs: info?._def?.extendedProps?.invitedPatients?.map(
          (p: InviteeDTO) => p.id
        ),
        invitedStaffIDs: info?._def?.extendedProps?.invitedStaffs?.map(
          (s: InviteeDTO) => s.id
        ),
        start: info?.startStr,
        end: info?.endStr,
        block: false,
        allowApp: false,
        title: info?._def?.title,
        backgroundColor: info?._def?.ui?.backgroundColor,
        borderColor: info?._def?.ui?.borderColor,
        telehealth: info?._def?.extendedProps?.telehealth,
        appointment: info?._def?.extendedProps?.appointment,
        note: info?._def?.extendedProps?.note,
        email: '',
      };
    } else {
      createInfo = {
        id: typeEvent === 'create' ? '' : info.apptId,
        invitedPatientIDs: info.invitedPatientIDs,
        invitedStaffIDs: info.invitedStaffIDs,
        start: info.start ?? undefined,
        end: info.end ?? undefined,
        block: info.isBlock,
        allowApp: info.allow,
        title: info.dispAppt ?? info.appt,
        backgroundColor: rgbWithOpacity(info.color),
        borderColor: info.color,
        telehealth: info.checked,
        appointment: info.dispAppt || info?._def?.extendedProps?.appointment,
        note: info.note,
        email: info.email,
      };
    }

    let type: Type = 'block';
    switch (info.dispAppt || info?._def?.extendedProps?.appointment) {
      case 'Initial Visit':
        type = 'initial_visit';
        break;
      case 'New OB':
        type = 'new_ob';
        break;
      case 'Return OB':
        type = 'return_ob';
        break;
      case 'Return GYN':
        type = 'return_gyn';
        break;
      case 'Prob GYN':
        type = 'prob_gyn';
        break;
      case 'PP':
        type = 'post_partum';
        break;
      case 'Prenatal':
        type = 'prenatal';
        break;
      case 'Block':
        type = 'block';
        break;
      case 'General':
        type = 'general';
        break;
    }

    const newAppt:
      | CreateAppointmentDTO
      | (UpdateAppointmentDTO & { loading: boolean }) = {
      invitedPatientIDs: createInfo.invitedPatientIDs ?? [],
      invitedStaffIDs: createInfo.invitedStaffIDs ?? [],
      loading: true,
      type: info.isBlock ? 'block' : type,
      startTime: (info.start as Date).toISOString(),
      endTime: (info.end as Date)?.toISOString(),
      telehealth: false,
      note: info.note,
    };

    if (typeEvent === 'create') {
      addApptMutation.mutate(newAppt);
    }

    if (typeEvent === 'edit') {
      editApptMutation.mutate({
        ...(newAppt as UpdateAppointmentDTO & { loading: boolean }),
        id: info.apptId,
      });
    }

    if (typeEvent === 'drag') {
      const dragInfo = {
        id: info?._def?.publicId,
        invitedPatientIDs: createInfo.invitedPatientIDs ?? [],
        invitedStaffIDs: createInfo.invitedStaffIDs ?? [],
        startTime: info?.startStr,
        endTime: info?.endStr,
        telehealth: info?._def?.extendedProps?.telehealth,
        type,
        note: info?._def?.extendedProps?.note,
      };
      editApptMutation.mutate(
        dragInfo as UpdateAppointmentDTO & { loading: boolean }
      );
    }

    tempRows = [...saveEvents, createInfo];
    setSaveEvents(tempRows);
    calendarAPI?.addEvent(createInfo);
    setOpenCreateModal(false);
  };

  const handleEventClick = (clickInfo: EventClickArg) => {
    setOpenEditModal(true);
    setSelectedEvent(clickInfo);
  };

  const handleDates = (rangeInfo: DatesSetArg) => {
    setCurrentDate(rangeInfo);
    timeRef.current.startTime = rangeInfo.startStr;
    timeRef.current.endTime = rangeInfo.endStr;
    refetch();
  };

  const selectedCalendar = (e: DateSelectArg) => {
    const viewType = e.view.type as CalendarViewTypes;
    const fullDayTypes = [
      'dayGridMonth',
      'multiMonthYear',
    ] as CalendarViewTypes[];
    const [start, end] = fullDayTypes.includes(viewType)
      ? [dayjs(e.start).set('hour', 7), dayjs(e.end).set('hour', 19)]
      : [dayjs(e.startStr), dayjs(e.endStr)];
    setNewEvent({ start, end });
    setOpenCreateModal(true);
  };

  const renderHeader = (param: DayHeaderContentArg) => {
    let text = '';
    let number = '';

    switch (param.view.type as CalendarViewTypes) {
      case 'timeGridDay':
      case 'timeGridWeek':
      case 'listMonth':
      case 'timeGridFourDay':
        number = param.date.getDate().toString();
        text = param.text.slice(0, 3);
        break;
      case 'dayGridMonth':
      case 'multiMonthYear':
        text = param.text;
        break;
    }

    //  TODO: refactor
    return (
      <div className="flex items-center justify-center">
        <Typography
          variant={'h6'}
          customClass={`${param.isToday ? 'text-red' : 'text-gray'}`}
          text={text}
        />
        <Typography
          variant={'h4'}
          customClass={`${param.isToday ? 'text-white bg-red' : 'text-black'}
          ${
            param.view.type === 'multiMonthYear' ||
            param.view.type === 'dayGridMonth'
              ? ''
              : 'h-[40px] w-[40px]'
          }
          ml-2 mt-0 mb-0 rounded-full flex items-center justify-center`}
          text={number}
        />
      </div>
    );
  };

  const renderEventContent = (eventInfo: EventContentArg) => {
    const viewType = calendarAPI?.view.type as CalendarViewTypes;
    const invitedPatients = eventInfo.event._def.extendedProps.invitedPatients;
    let patientInfo: string | null = null;

    if (invitedPatients?.length > 0) {
      const firstPatient = invitedPatients[0];
      const firstPatientName = `${firstPatient.firstName} ${firstPatient.lastName}`;
      const extraPatients = invitedPatients.length - 1;
      patientInfo = `${firstPatientName} ${
        extraPatients > 0 ? `+${extraPatients}` : ''
      }`;
    }

    return (
      <div
        style={{
          borderLeft: `5px solid ${eventInfo?.borderColor}`,
          backgroundColor:
            calendarRef.current !== null &&
            viewType !== 'timeGridDay' &&
            viewType !== 'timeGridWeek'
              ? eventInfo.backgroundColor
              : '',
          width: '100%',
          height: '100%',
          margin: 0,
          borderRadius: 4,
          textOverflow: 'ellipsis',
        }}
        className={`flex flex-row w-full hover:bg-[${eventInfo.borderColor}]`}
      >
        <div className="flex flex-col w-full overflow-hidden px-2">
          <div className="flex flex-wrap w-full content-start justify-between gap-x-2">
            {eventInfo?.event._def.extendedProps.loading && (
              <CircularProgress className="max-w-[10px] max-h-[10px] mt-1 mr-2" />
            )}

            {patientInfo && (
              <Typography
                variant={'bodySmall'}
                customClass="text-gray h-fit font-semibold"
                text={patientInfo}
              />
            )}
            <Typography
              variant={'bodySmall'}
              customClass="text-gray h-fit"
              text={eventInfo?.event.extendedProps.appointment}
            />
          </div>
          <div className="flex flex-wrap content-start gap-x-2">
            {calendarRef.current !== null &&
              !(
                calendarAPI?.view.type === 'timeGridWeek' ||
                calendarAPI?.view.type === 'dayGridMonth'
              ) && (
                <Typography
                  variant={'bodySmall'}
                  customClass="text-gray h-fit"
                  text={eventInfo?.timeText}
                />
              )}
          </div>
        </div>
      </div>
    );
  };

  if (isLoading)
    return (
      <div className="flex justify-center items-center h-screen">
        <CircularProgress />
      </div>
    );

  return (
    <div className="flex flex-col-reverse md:flex-row w-full md:ml-5 overflow-x-hidden overflow-y-auto flex-1">
      <div className="md:mt-10 overflow-auto shrink-0">
        {currentDate && (
          <CalendarSidebar
            currentDate={dayjs(currentDate.start)}
            handleNav={handleNav}
          />
        )}
      </div>
      <Divider
        className="bg-grayLight"
        orientation="vertical"
        flexItem
        sx={{ margin: 1 }}
      />
      <div className="h-full w-full ml-1 mr-10">
        <CalendarHeader
          calendarRef={calendarRef}
          currentDate={currentDate}
          handleCreate={handleCreateAppt}
        />
        <CalendarWrapper>
          <FullCalendar
            //Configuration
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              interactionPlugin,
              resourceTimelinePlugin,
              multiMonthPlugin,
              listPlugin,
            ]}
            initialView="timeGridFourDay"
            nowIndicator
            slotDuration={slotDuration}
            slotLabelInterval={slotLabelInterval}
            slotMinTime={slotMinTime}
            slotMaxTime={slotMaxTime}
            initialDate={new Date()}
            views={{
              timeGridFourDay: {
                type: 'timeGrid',
                duration: { days: 4 },
              },
            }}
            ref={calendarRef}
            events={data ? formattedEvents : []}
            headerToolbar={false}
            editable
            selectable
            selectMirror
            expandRows
            allDaySlot={false}
            weekends
            dayMaxEvents
            dayCellClassNames={
              calendarRef.current !== null &&
              calendarAPI?.view.type === 'multiMonthYear'
                ? 'cell-year'
                : 'cell'
            }
            dayHeaderContent={renderHeader}
            eventContent={renderEventContent}
            eventResize={(e) => handleCreateEvent(e.event, 'drag')}
            //Actions
            datesSet={handleDates}
            eventDrop={(e) => handleCreateEvent(e.event, 'drag')}
            select={(e) => {
              selectedCalendar(e);
            }}
            eventClick={(e) =>
              e.event._def.publicId === 'undefined' ||
              e.event._def.extendedProps.loading
                ? null
                : handleEventClick(e)
            }
          />
        </CalendarWrapper>
      </div>

      {/* Modals */}
      {newEvent && (
        <CreateApptModal
          open={openCreateModal}
          eventInfo={newEvent}
          handleConfirm={(e) => {
            handleCreateEvent(e, 'create');
          }}
          handleClose={() => {
            setOpenCreateModal(false);
          }}
        />
      )}
      {selectedEvent && (
        <EditApptModal
          open={openEditModal && !!selectedEvent}
          eventInfo={selectedEvent.event}
          handleConfirm={(e) => {
            handleCreateEvent(e, 'edit');
            setOpenEditModal(false);
            logAnalyticEvent('calendar', 'edit_appt');
          }}
          handleCancel={() => {
            setOpenEditModal(false);
            setOpenDelete(true);
          }}
          handleClose={() => {
            setOpenEditModal(false);
          }}
          handleRemove={handleEventRemove}
        />
      )}
      <ConfirmationModal
        open={openDel}
        title="Delete this appointment?"
        description="This will send a cancellation notification."
        confirm="OK"
        dismiss="Cancel"
        handleClose={() => setOpenDelete(false)}
        handleConfirm={handleEventRemove}
        handleCancel={() => setOpenDelete(false)}
      />
    </div>
  );
};

export default CalendarApp;
