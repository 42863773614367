import { firebaseConfig } from './firebase';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import Navbar from '../components/Navbar';
import Router from '../Router';
import Header from '../components/Header';
import { AuthProvider } from '../authentication/AuthProvider';
import { DosespotProvider } from '../features/dosespot/DosespotProvider';

function App() {
  const app = initializeApp(firebaseConfig);
  getAnalytics(app);
  return (
    <div className="flex h-screen w-full">
      <AuthProvider>
        <DosespotProvider>
          <Navbar />
          <div className="flex flex-col w-full">
            <Header />
            <main className="w-full flex flex-col h-screen overflow-auto">
              <Router />
            </main>
          </div>
        </DosespotProvider>
      </AuthProvider>
    </div>
  );
}

export default App;
