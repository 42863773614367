import { VitalsDTO } from '@aster/shared/dtos/vitals';
import { createId } from '@paralleldrive/cuid2';
import axios from '../../../../app/axiosConfig';
import { cacheKey } from '../queries/fetch-vitals.query';
import dayjs from 'dayjs';
import { useMutation, useQueryClient } from '@tanstack/react-query';

const createVitals = async (newForm: any) => axios.post(`/vitals`, newForm);

export function useAddVitalsMutation(
  patientID: string,
  onSuccessCb: (() => void) | null
) {
  const queryClient = useQueryClient();
  const addVitalsMutation = useMutation({
    mutationKey: ['createVitals'],
    mutationFn: createVitals,
    onMutate: async (newVitals) => {
      newVitals = {
        ...newVitals,
        id: createId(),
        timeCreated: dayjs().toISOString(),
      };
      await queryClient.cancelQueries({ queryKey: cacheKey(patientID) });
      onSuccessCb && onSuccessCb();

      const previousVitals = queryClient.getQueryData<VitalsDTO[]>(
        cacheKey(patientID)
      );

      queryClient.setQueryData<VitalsDTO[]>(
        cacheKey(patientID),
        (oldVitals) => [newVitals, ...(oldVitals ?? [])]
      );

      return { previousVitals };
    },
    onError: (_err, _newVitals, context) => {
      queryClient.setQueryData(cacheKey(patientID), context?.previousVitals);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: cacheKey(patientID) });
    },
  });

  return { addVitalsMutation };
}
