import { AllergyDetails } from '@aster/shared/dtos/patient';
import BasicTextfield from '../../../components/Textfield';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';

export type PatientAllergiesProps = {
  allergyList: AllergyDetails[];
  setAllergies: (allergyList: AllergyDetails[]) => void;
};

const PatientAllergies = ({
  allergyList,
  setAllergies,
}: PatientAllergiesProps) => {
  const handleAllergy = (index: number, newAllergyValue: AllergyDetails) => {
    const updatedAllergies = allergyList.map((allergy, i) =>
      i === index ? { ...allergy, ...newAllergyValue } : allergy
    );
    setAllergies(updatedAllergies);
  };

  const deleteAllergy = (index: number) => {
    const updatedAllergies = allergyList.filter((_, i) => i !== index);
    setAllergies(updatedAllergies);
  };

  const addAllergy = () => {
    const newAllergy: AllergyDetails = {
      name: '',
      reaction: '',
    };
    setAllergies([...allergyList, newAllergy]);
  };

  return (
    <div>
      {allergyList.map((allergy, index) => (
        <div
          key={index}
          className="border border-grayLight md:border-0 w-fit p-2 grid grid-cols-[max-content_max-content] gap-5 gap-y-4 md:grid-cols-[minmax(0,_1fr)_minmax(0,_1fr)_max-content] mt-2"
        >
          <BasicTextfield
            id="name"
            variant="filled"
            label="Name"
            name="name"
            value={allergy.name}
            onChange={(e) =>
              handleAllergy(index, {
                ...allergy,
                name: e?.currentTarget?.value,
              })
            }
            containerClasses="row-start-1 col-start-1 w-[300px]"
          />

          <BasicTextfield
            id="reaction"
            variant="filled"
            label="Reaction"
            name="reaction"
            value={allergy.reaction}
            onChange={(e) =>
              handleAllergy(index, {
                ...allergy,
                reaction: e?.currentTarget?.value,
              })
            }
            containerClasses="row-start-2 col-start-1 md:row-start-1 md:col-start-2 w-[300px]"
          />
          <IconButton
            className="text-red row-start-1 col-start-2 md:col-start-3"
            disableRipple={true}
            onClick={() => deleteAllergy(index)}
            aria-label="delete"
          >
            <DeleteIcon />
          </IconButton>
        </div>
      ))}
      <Button
        className="text-green font-semibold"
        onClick={addAllergy}
        variant="text"
        startIcon={<AddIcon />}
      >
        Add Allergy
      </Button>
    </div>
  );
};

export default PatientAllergies;
