import { useMemo } from 'react';
import Combobox, {
  MultiselectTags,
  Option,
} from '../../../components/Autocomplete';
import { useGetPatients } from '../queries/use-get-patients.query';
import { useGetStaffs } from '../queries/use-get-staffs.query';

type SelectInviteesProps = {
  invitedPatients: Option[];
  setInvitedPatients: (newPatients: Option[]) => void;
  invitedStaff: Option[];
  setInvitedStaff: (newStaff: Option[]) => void;
};

const SelectInvitees = ({
  invitedPatients,
  setInvitedPatients,
  invitedStaff,
  setInvitedStaff,
}: SelectInviteesProps) => {
  const { patientList, arePatientsLoading, arePatientsFetching, search } =
    useGetPatients('active');
  const { staffMembers, areStaffLoading } = useGetStaffs();
  const patientOptions = useMemo(() => {
    if (patientList?.items && !arePatientsLoading && !arePatientsFetching) {
      return patientList.items.map((r) => ({
        id: r.id,
        label: `${r.firstName} ${r.lastName}`,
      }));
    }
    return [];
  }, [patientList?.items, arePatientsLoading, arePatientsFetching]);

  const staffOptions = useMemo(() => {
    if (staffMembers && !areStaffLoading) {
      return staffMembers?.map((r) => ({
        id: r.id,
        label: `${r.firstName} ${r.lastName}`,
      }));
    }
    return [];
  }, [areStaffLoading, staffMembers]);
  return (
    <>
      <Combobox
        // freeSolo silences gigantic console warning
        freeSolo
        className="mb-4"
        multiple
        label="Patients"
        value={invitedPatients}
        isServerSide={true}
        loading={arePatientsLoading || arePatientsFetching}
        onChange={(_, newValue) => {
          setInvitedPatients(newValue as Option[]);
        }}
        renderTags={(value, getTagProps) => (
          <MultiselectTags value={value} getTagProps={getTagProps} />
        )}
        onInputChange={(_: any, newInputValue: string) => {
          search(newInputValue);
        }}
        isOptionEqualToValue={(option, value) =>
          (option as Option).id === (value as Option).id
        }
        options={patientOptions}
        width={'100%'}
      />
      <Combobox
        // freeSolo silences gigantic console warning
        freeSolo
        multiple
        className="mb-4"
        label="Staff"
        value={invitedStaff}
        isServerSide={true}
        loading={areStaffLoading}
        onChange={(_, newValue) => {
          setInvitedStaff(newValue as Option[]);
        }}
        renderTags={(value, getTagProps) => (
          <MultiselectTags value={value} getTagProps={getTagProps} />
        )}
        isOptionEqualToValue={(option, value) =>
          (option as Option).id === (value as Option).id
        }
        options={staffOptions}
        width={'100%'}
      />
    </>
  );
};

export default SelectInvitees;
