import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import BasicTextfield from '../../../components/Textfield';
import SelectDropdown from '../../../components/SelectDropdown';
import { PersonalInfo } from '../types/patient-profile';
import Typography from '../../../components/Typography';
import dayjs from 'dayjs';
import { DateField } from '@mui/x-date-pickers/DateField';

type PersonalInfoProps = {
  personalInfo: PersonalInfo;
  handleInfo: (event: any) => void;
  handleBirthday: (event: any) => void;
  handlePersonalInfoSelect: (event: any, property: string) => void;
};

const PersonalInformation = (props: PersonalInfoProps) => {
  const {
    firstName,
    middleName,
    lastName,
    dateOfBirth,
    legalSex,
    ethnicity,
    race,
    preferredLanguage,
    preferredName,
    pronouns,
    genderIdentity,
  } = props.personalInfo;
  const handleInfo = props.handleInfo;
  const handleBirthday = props.handleBirthday;
  const handlePersonalInfoSelect = props.handlePersonalInfoSelect;

  return (
    <div className="h-fit flex flex-wrap justify-center md:justify-start gap-10 xl:gap-40">
      <div className="flex flex-col">
        <Typography
          text="Personal Information"
          variant="h5"
          customClass="font-semibold mb-5"
        ></Typography>
        <BasicTextfield
          id="firstName"
          variant="filled"
          label="First name"
          name="firstName"
          value={firstName}
          onChange={handleInfo}
          required
          classes="mb-5 w-[300px]"
        />
        <BasicTextfield
          id="middlename"
          variant="filled"
          label="Middle name"
          name="middleName"
          value={middleName}
          onChange={handleInfo}
          classes="mb-5 w-[300px]"
        />
        <BasicTextfield
          id="lastName"
          variant="filled"
          label="Last name"
          name="lastName"
          value={lastName}
          onChange={handleInfo}
          required
          classes="mb-5 w-[300px]"
        />
        <div className="flex mb-5">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateField
              label="Date of birth"
              value={dateOfBirth}
              maxDate={dayjs()}
              sx={{
                width: '300px',
                backgroundColor: 'white',
                borderRadius: 1,
              }}
              onChange={handleBirthday}
            />
          </LocalizationProvider>
        </div>
        <div className="flex">
          <SelectDropdown
            label="Legal sex"
            options={[
              { value: 'female', text: 'Female' },
              { value: 'male', text: 'Male' },
              { value: 'other', text: 'Other' },
            ]}
            value={legalSex ?? ''}
            width="300px"
            handleChange={(event: any) =>
              handlePersonalInfoSelect(event, 'legalSex')
            }
            required
          />
        </div>
        <div className="mt-4">
          <SelectDropdown
            label="Race"
            options={[
              { value: 'black', text: 'Black or African American' },
              { value: 'eastasian', text: 'East and Southeast Asian' },
              { value: 'more', text: 'More than one race' },
              { value: 'native', text: 'Native American or Alaska Native' },
              {
                value: 'hawaiian',
                text: 'Native Hawaiian or Pacific Islander',
              },
              { value: 'other', text: 'Other' },
              {
                value: 'notsay',
                text: 'Prefer not to say',
              },
              { value: 'southasian', text: 'South and Central Asian' },
              { value: 'white', text: 'White' },
            ]}
            value={race}
            width="300px"
            handleChange={(event: any) =>
              handlePersonalInfoSelect(event, 'race')
            }
          />
        </div>
        <div className="mt-4 mb-4">
          <SelectDropdown
            label="Ethnicity"
            options={[
              { value: 'hispanic', text: 'Hispanic or Latino' },
              { value: 'nohispanic', text: 'Not Hispanic or Latino' },
              {
                value: 'notsay',
                text: 'Prefer not to say',
              },
              { value: 'other', text: 'Other' },
            ]}
            value={ethnicity}
            width="300px"
            handleChange={(event: any) =>
              handlePersonalInfoSelect(event, 'ethnicity')
            }
          />
        </div>
        <BasicTextfield
          id="preferredLanguage"
          variant="filled"
          label="Preferred language"
          name="preferredLanguage"
          value={preferredLanguage}
          onChange={handleInfo}
          classes="mb-5 w-[300px]"
        />
      </div>
      <div className="flex flex-col">
        <Typography
          text="Personal Identity"
          variant="h5"
          customClass="font-semibold mb-5"
        ></Typography>
        <BasicTextfield
          id="preferredName"
          variant="filled"
          label="Preferred name"
          name="preferredName"
          value={preferredName}
          onChange={handleInfo}
          classes="mb-5 w-[300px]"
        />
        <div className="mb-5">
          <SelectDropdown
            label="Pronouns"
            options={[
              { value: 'she', text: 'She / Her / Hers' },
              { value: 'he', text: 'He / Him / His' },
              { value: 'they', text: 'They / Them / Theirs' },
            ]}
            value={pronouns}
            width="300px"
            handleChange={(event: any) =>
              handlePersonalInfoSelect(event, 'pronouns')
            }
          />
        </div>
        <SelectDropdown
          label="Gender identity"
          options={[
            { value: 'woman', text: 'Woman' },
            { value: 'man', text: 'Man' },
            { value: 'transgender', text: 'Transgender' },
            { value: 'nonbinary', text: 'Nonbinary' },
            { value: 'other', text: 'Other' },
          ]}
          value={genderIdentity}
          width="300px"
          handleChange={(event: any) =>
            handlePersonalInfoSelect(event, 'genderIdentity')
          }
        />
      </div>
    </div>
  );
};

export default PersonalInformation;
