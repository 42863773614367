import { z } from 'zod';

export type PaginatedResult<T> = {
  items: T[];
  queryMetadata: {
    page: number;
    pageSize: number;
    pageCount: number;
    rowCount: number;
  };
};

export const SearchArgs = z.object({
  search: z.string().optional(),
  page: z.number().int().nonnegative().optional(),
  pageSize: z.number().int().positive().optional(),
  sortBy: z.string().optional(),
  sortDir: z
    .enum(['ASC', 'DESC', 'asc', 'desc'])
    .default('desc')
    .optional()
    .nullable(),
});

export type SearchArgs = z.infer<typeof SearchArgs>;

export function searchArgsToString(searchArgs: SearchArgs): string {
  return Object.entries(searchArgs)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');
}
