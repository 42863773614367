import * as React from 'react';
import { colors } from '../theme';
import { colorPerInitial, customTwMerge } from '../utils';
import Typography from './Typography';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import styled from '@mui/system/styled';
import TextField from '@mui/material/TextField';
import Autocomplete, {
  AutocompleteProps,
  AutocompleteRenderGetTagProps,
} from '@mui/material/Autocomplete';
import { ChipTypeMap } from '@mui/material/Chip';
import { SxProps } from '@mui/material/styles';
import Box from '@mui/system/Box';
import CircularProgress from '@mui/material/CircularProgress';

export type Option = {
  id: string;
  label: string;
};

const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: colors.primary,
    fontWeight: 600,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: colors.transparent,
    borderRadius: 10,
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '10px',
    backgroundColor: colors.white,
    overflow: 'hidden',
    padding: '10px, 15px, 10px, 15px',
    '& fieldset': {
      borderColor: colors.grayLight,
    },
    '&:hover fieldset': {
      borderColor: colors.grayLight,
    },
    '&.Mui-focused fieldset': {
      borderColor: colors.primary,
    },
  },
  '& .MuiFilledInput-root': {
    overflow: 'hidden',
    borderRadius: 8,
    backgroundColor: colors.white,
    border: '1px solid',
    borderColor: colors.primary,
    '&:hover': {
      backgroundColor: colors.white,
    },
    '&.Mui-focused': {
      backgroundColor: colors.white,
      boxShadow: colors.grayLight,
    },
  },
});

export function MultiselectTags({
  value,
  getTagProps,
}: {
  value: Option[];
  getTagProps: AutocompleteRenderGetTagProps;
}) {
  return value.map((option, index) => (
    <div
      key={option.id}
      className={customTwMerge(
        'flex items-center bg-asterGray rounded-md mt-1 mr-2',
        colorPerInitial(option.label.charAt(0).toUpperCase())
      )}
    >
      <Typography
        variant="bodySmall"
        text={option.label}
        customClass="bg-gray-200 px-2 rounded-full font-semibold h-5"
      />

      <IconButton
        className="p-1"
        disableRipple
        onClick={getTagProps({ index }).onDelete}
      >
        <CloseIcon className="text-primary text-sm" />
      </IconButton>
    </div>
  ));
}

const Combobox = <
  Value,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
  ChipComponent extends React.ElementType = ChipTypeMap['defaultComponent']
>(
  props: Omit<
    AutocompleteProps<
      Value,
      Multiple,
      DisableClearable,
      FreeSolo,
      ChipComponent
    >,
    'renderInput'
  > & {
    label: string;
    width?: string;
    icon?: React.ReactNode;
    isServerSide?: boolean;
    autoCompleteStyles?: React.CSSProperties | SxProps;
  }
) => {
  const { label, width, icon, isServerSide, ...otherProps } = props;

  return (
    <Box
      component="form"
      sx={{
        width: width || '380px',
        maxWidth: '100%',
      }}
      noValidate
      autoComplete="on"
      width={width}
    >
      <Autocomplete
        {...otherProps}
        popupIcon={icon}
        forcePopupIcon={true}
        filterOptions={props.isServerSide ? (x) => x : undefined}
        sx={{ width: width || '380px', ...props.autoCompleteStyles }}
        renderInput={(params) => (
          <CssTextField
            {...params}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {props.loading ? (
                    <div className="flex items-center absolute top-1/2 right-16 -translate-y-1/2">
                      <CircularProgress color="inherit" size={14} />
                    </div>
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            variant="filled"
            label={label}
          />
        )}
      />
    </Box>
  );
};

export default Combobox;
