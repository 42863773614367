import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import ButtonType from '../../../components/Button';
import Typography from '../../../components/Typography';
import { Divider, FormHelperText } from '@mui/material';
import StaffMedicalPrimary from '../../../assets/icons/StaffMedicalPrimary';
import CircleInfo from '../../../assets/icons/CircleInfo';
import { useForm } from '@tanstack/react-form';
import { zodValidator } from '@tanstack/zod-form-adapter';
import { z } from 'zod';
import BasicTextfield from '../../../components/Textfield';
import BorderLinearProgress from './LinearProgress';
import { US_STATES } from '../constants';
import SelectDropdown from '../../../components/SelectDropdown';
import {
  DateField,
  DateValidationError,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import BasicDatePicker from '../../../components/DatePicker';
import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import { colors } from '../../../theme';
import { useDosespot } from '../DosespotProvider';
import { useAuth } from '../../../authentication/AuthProvider';
import { error } from 'console';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { formatPhone } from '../util/formatPhone';

type AddPracticeModalProps = {
  incrementStep: () => void;
};

const AddClinician = ({ incrementStep }: AddPracticeModalProps) => {
  const dosespotContext = useDosespot();
  const [formError, setFormError] = useState<string[] | null>(null);
  const { profile } = useAuth();
  const form = useForm({
    defaultValues: {
      provider_first_name: profile ? profile.firstName : '',
      provider_last_name: profile ? profile.lastName : '',
      provider_DOB: null as dayjs.Dayjs | null,
      provider_address: '',
      provider_city: '',
      provider_state: '',
      provider_zip: '',
      provider_phone: '',
      provider_dea: '',
      provider_npi: '',
      provider_license: '',
    },
    validatorAdapter: zodValidator,
    onSubmit: async (values) => {
      setFormError(null);

      const { client_id, client_secret } = dosespotContext.dosespotData;
      const username = import.meta.env.VITE_DOSESPOT_ADMIN_USER_ID;

      if (!username || !client_secret) {
        setFormError(['Could not find dosespot clinic']);
        return;
      }

      return createDosespotClinician(
        client_id as string,
        client_secret as string,
        username
      )
        .then((res) => {
          if (res && res.data.ModelState) {
            const errors = Object.values(
              res.data.ModelState as Record<string, string[]>
            ).map((error) => error[0]);
            setFormError(errors);
            return;
          }
          incrementStep();
        })
        .catch((error) => {
          console.error(error);
          throw new Error(
            'Error creating clinician, please make sure all fields are correct'
          );
        });
    },
  });

  const createDosespotClinician = async (
    client_id: string,
    client_secret: string,
    username: string
  ) => {
    await dosespotContext.getDosespotJwt({
      client_id,
      client_secret,
      username,
      password: client_secret,
    });
    const phone = formatPhone(form.getFieldValue('provider_phone'));
    const clinician = await dosespotContext.createDosespotClinician({
      FirstName: form.getFieldValue('provider_first_name'),
      LastName: form.getFieldValue('provider_last_name'),
      DateOfBirth:
        form.getFieldValue('provider_DOB')?.format('YYYY-MM-DD') ?? '',
      Address1: form.getFieldValue('provider_address'),
      City: form.getFieldValue('provider_city'),
      State: form.getFieldValue('provider_state'),
      ZipCode: form.getFieldValue('provider_zip'),
      PrimaryPhone: phone,
      PrimaryFax: phone,
      DEANumber: form.getFieldValue('provider_dea').toUpperCase(),
      NPINumber: form.getFieldValue('provider_npi'),
      MedicalLicenseNumbers: [
        {
          State: form.getFieldValue('provider_state'),
          ClinicId: client_id,
          LicenseNumber: form.getFieldValue('provider_license'),
        },
      ],
    });
    return clinician;
  };

  return (
    <>
      <form
        className=""
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          void form.handleSubmit();
        }}
      >
        <DialogActions className="flex justify-between p-6">
          <Typography
            variant={'h4'}
            customClass="font-semibold"
            text={'Add prescribing clinician'}
          />
          <div className="flex items-end gap-4">
            <div className="flex flex-col items-center w-[140px]">
              <Typography
                variant="bodySmall"
                customClass="text-asterGray"
                text="2/3 steps"
              />
              <BorderLinearProgress
                variant="determinate"
                className="w-full mt-1"
                value={66}
              />
            </div>
          </div>
        </DialogActions>
        <Divider />
        <DialogContent className="grid grid-cols-1 items-start gap-4">
          <div className="col-span-1">
            <div className="flex justify-between pr-5 pl-8">
              <Typography
                customClass="font-semibold leading-0"
                variant="h6"
                text="Provider Information"
              />
              <StaffMedicalPrimary />
            </div>
            {formError &&
              formError.map((error) => (
                <Typography
                  variant={'bodyMedium'}
                  customClass="text-red mt-2"
                  text={error}
                />
              ))}
            <div className="grid gap-5 p-5 mt-5 bg-grayBackground rounded-[20px]">
              <div className="grid grid-cols-2 gap-x-4">
                <form.Field
                  name="provider_first_name"
                  validators={{
                    onBlur: z
                      .string()
                      .min(1, {
                        message: 'Provider first name is required',
                      })
                      .max(255, {
                        message:
                          'Provider first name must be less than 255 characters',
                      }),
                  }}
                  children={(field) => (
                    <BasicTextfield
                      id="provider_first_name"
                      error={field.state.meta.errors.length > 0}
                      helperText={field.state.meta.errors.join('\r')}
                      name={field.name}
                      value={field.state.value}
                      onBlur={field.handleBlur}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        field.handleChange(event.target.value)
                      }
                      required
                      variant="filled"
                      label="First name"
                      width="100%"
                    />
                  )}
                />
                <form.Field
                  name="provider_last_name"
                  validators={{
                    onBlur: z
                      .string()
                      .min(1, {
                        message: 'Provider last name is required',
                      })
                      .max(255, {
                        message:
                          'Provider last name must be less than 255 characters',
                      }),
                  }}
                  children={(field) => (
                    <BasicTextfield
                      id="provider_last_name"
                      error={field.state.meta.errors.length > 0}
                      helperText={field.state.meta.errors.join('\r')}
                      name={field.name}
                      value={field.state.value}
                      onBlur={field.handleBlur}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        field.handleChange(event.target.value)
                      }
                      required
                      variant="filled"
                      label="Last name"
                      width="100%"
                    />
                  )}
                />
              </div>
              <div className="grid grid-cols-2 gap-x-4">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <form.Field
                    name="provider_DOB"
                    defaultMeta={{ isTouched: true }}
                    validators={{
                      onChange: z.coerce
                        .date()
                        .max(
                          dayjs().subtract(18, 'years').toDate(),
                          'Provider must be at least 18 years old'
                        ),
                    }}
                    children={(field) => (
                      <div>
                        <DateField
                          required
                          minDate={dayjs().subtract(100, 'year')}
                          maxDate={dayjs().subtract(18, 'year')}
                          sx={{
                            '& .MuiTextField-root': {
                              backgroundColor: colors.white,
                            },
                          }}
                          defaultValue={null}
                          onBlur={field.handleBlur}
                          disableFuture
                          name={field.name}
                          value={field.state.value}
                          onChange={(value) => {
                            field.handleChange(value);
                          }}
                          label="Provider DOB"
                        />
                        <FormHelperText error={true}>
                          {field.state.meta.errors?.join('\r')}
                        </FormHelperText>
                      </div>
                    )}
                  />
                </LocalizationProvider>
                <form.Field
                  name="provider_phone"
                  validators={{
                    onBlur: z
                      .string()
                      .min(1, {
                        message: 'Provider phone is required',
                      })
                      .max(25, {
                        message: 'Phone number must be less than 25 characters',
                      }),
                  }}
                  children={(field) => (
                    <BasicTextfield
                      id="provider_phone"
                      error={field.state.meta.errors.length > 0}
                      helperText={field.state.meta.errors.join('\r')}
                      name={field.name}
                      value={field.state.value}
                      onBlur={field.handleBlur}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        field.handleChange(event.target.value)
                      }
                      required
                      variant="filled"
                      label="Phone"
                      width="100%"
                    />
                  )}
                />
              </div>
              <form.Field
                name="provider_address"
                validators={{
                  onBlur: z
                    .string()
                    .min(1, {
                      message: 'Provider address is required',
                    })
                    .max(255, {
                      message:
                        'Provider address must be less than 255 characters',
                    }),
                }}
                children={(field) => (
                  <BasicTextfield
                    id="provider_address"
                    error={field.state.meta.errors.length > 0}
                    helperText={field.state.meta.errors.join('\r')}
                    name={field.name}
                    value={field.state.value}
                    onBlur={field.handleBlur}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      field.handleChange(event.target.value)
                    }
                    required
                    variant="filled"
                    label="Provider address"
                    width="100%"
                  />
                )}
              />
              <form.Field
                name="provider_city"
                validators={{
                  onBlur: z
                    .string()
                    .min(1, {
                      message: 'Practice city is required',
                    })
                    .max(35, {
                      message: 'Practice city must be less than 35 characters',
                    }),
                }}
                children={(field) => (
                  <BasicTextfield
                    id="provider_city"
                    error={field.state.meta.errors.length > 0}
                    helperText={field.state.meta.errors.join('\r')}
                    name={field.name}
                    value={field.state.value}
                    onBlur={field.handleBlur}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      field.handleChange(event.target.value)
                    }
                    required
                    variant="filled"
                    label="Provider city"
                    width="100%"
                  />
                )}
              />
              <div className="grid grid-cols-2 gap-x-4">
                <form.Field
                  name="provider_state"
                  validators={{
                    onBlur: z
                      .string()
                      .min(1, {
                        message: 'Provider state is required',
                      })
                      .max(20, {
                        message:
                          'Provider state must be less than 20 characters',
                      }),
                  }}
                  children={(field) => (
                    <SelectDropdown
                      handleChange={(
                        event: React.ChangeEvent<HTMLSelectElement>
                      ) => field.handleChange(event.target.value)}
                      options={US_STATES}
                      name={field.name}
                      value={field.state.value}
                      required
                      label="Provider state"
                      width="100%"
                    />
                  )}
                />
                <form.Field
                  name="provider_zip"
                  validators={{
                    onBlur: z
                      .string()
                      .min(1, {
                        message: 'Provider zip is required',
                      })
                      .max(10, {
                        message: 'Provider zip must be less than 10 characters',
                      }),
                  }}
                  children={(field) => (
                    <BasicTextfield
                      id="provider_zip"
                      error={field.state.meta.errors.length > 0}
                      helperText={field.state.meta.errors.join('\r')}
                      name={field.name}
                      value={field.state.value}
                      onBlur={field.handleBlur}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        field.handleChange(event.target.value)
                      }
                      required
                      variant="filled"
                      label="Provider zip"
                      width="100%"
                    />
                  )}
                />
              </div>
              <Typography
                customClass="font-semibold leading-0"
                variant="h6"
                text="Licensing Information"
              />
              <div className="grid grid-cols-2 gap-x-4">
                <form.Field
                  name="provider_dea"
                  validators={{
                    onBlur: z.string().max(255, {
                      message: 'Provider DEA must be less than 255 characters',
                    }),
                  }}
                  children={(field) => (
                    <BasicTextfield
                      id="provider_dea"
                      error={field.state.meta.errors.length > 0}
                      helperText={field.state.meta.errors.join('\r')}
                      name={field.name}
                      value={field.state.value}
                      onBlur={field.handleBlur}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        field.handleChange(event.target.value)
                      }
                      variant="filled"
                      label="Provider DEA"
                      width="100%"
                      inputProps={{
                        className: 'uppercase',
                      }}
                    />
                  )}
                />
                <form.Field
                  name="provider_npi"
                  validators={{
                    onBlur: z
                      .string()
                      .min(10, {
                        message: 'Provider NPI is required',
                      })
                      .max(10, {
                        message:
                          'Provider NPI must be less than 255 characters',
                      }),
                  }}
                  children={(field) => (
                    <BasicTextfield
                      id="provider_npi"
                      error={field.state.meta.errors.length > 0}
                      helperText={field.state.meta.errors.join('\r')}
                      name={field.name}
                      value={field.state.value}
                      onBlur={field.handleBlur}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        field.handleChange(event.target.value)
                      }
                      required
                      variant="filled"
                      label="Provider NPI"
                      width="100%"
                    />
                  )}
                />
              </div>
              <form.Field
                name="provider_license"
                validators={{
                  onBlur: z
                    .string()
                    .min(1, {
                      message: 'Provider license is required',
                    })
                    .max(255, {
                      message:
                        'Provider license must be less than 255 characters',
                    }),
                }}
                children={(field) => (
                  <BasicTextfield
                    id="provider_license"
                    error={field.state.meta.errors.length > 0}
                    helperText={field.state.meta.errors.join('\r')}
                    name={field.name}
                    value={field.state.value}
                    onBlur={field.handleBlur}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      field.handleChange(event.target.value)
                    }
                    required
                    variant="filled"
                    label="Provider license"
                    width="100%"
                  />
                )}
              />
            </div>
            <div className="grid col-span-1 items-start">
              <div className="flex items-center gap-4 bg-pastelOrange p-3 mt-5 rounded-2xl bg-opacity-[25%]">
                <CircleInfo className="flex shrink-0" />
                <Typography
                  customClass="text-asterGray leading-1"
                  variant="bodyMedium"
                  text="You’ll be able to add more providers to this DoseSpot account, after the initial set up."
                />
              </div>
            </div>
          </div>
          <div className="flex justify-center w-full">
            <form.Subscribe
              selector={(state) => [state.canSubmit, state.isSubmitting]}
              children={([canSubmit, isSubmitting]) => (
                <ButtonType
                  disabled={!canSubmit}
                  classes="w-1/2"
                  loading={isSubmitting}
                  type="submit"
                  variant="contained"
                  text={'Next'}
                />
              )}
            ></form.Subscribe>
          </div>
        </DialogContent>
      </form>
    </>
  );
};

export default AddClinician;
