import { useParams } from 'react-router-dom';
import PatientProfileForEncounterHeader from '../sections/PatientProfileForEncounterHeader';
import { CircularProgress } from '@mui/material';
import {
  PushButtonTab,
  PushButtonTabPanel,
  PushButtonTabs,
  PushButtonTabsList,
} from '../../../components/PushButtonTabs';
import { Chip } from '../../../components/Chip';
import Prechart from './Prechart';
import { usePatientProfile } from '../queries/query-patient-profile';
import { LabsProvider } from '../../labs/LabsContext';
import EncounterLabs from '../../labs/EncounterLabs';
import { customTwMerge as twMerge } from '../../../utils';

type PatientProfileForEncounterProps = {
  isEncounterSigned: boolean;
  enforceSaveMissing: boolean;
  handleUnsaved: (isDirty: boolean) => void;
  children?: React.ReactNode | React.ReactNode[];
  className?: string;
};

const PatientProfileForEncounter = ({
  isEncounterSigned,
  enforceSaveMissing,
  handleUnsaved,
  children,
  className,
}: PatientProfileForEncounterProps) => {
  const { patient } = useParams();
  const { patientProfile, isProfileLoading } = usePatientProfile(patient);

  if (isProfileLoading || !patientProfile) {
    return (
      <div className="flex flex-col justify-center items-center h-full w-[400px]">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div
      className={twMerge(
        'w-full lg:w-[400px] h-full lg:shadow-lg px-[15px] overflow-auto flex flex-col gap-[15px] pb-4',
        className
      )}
    >
      <PatientProfileForEncounterHeader
        name={patientProfile.name}
        edd={patientProfile.edd}
        dateOfConception={patientProfile.dateOfConception}
        lmp={patientProfile.lmp}
        dateToCalculateDueDate={patientProfile.dateToCalculateDueDate}
        gtpal={patientProfile.gtpal}
        age={patientProfile.age}
        dateOfBirth={patientProfile.dateOfBirth}
        problemList={patientProfile.problemList}
        mrn={patientProfile.mrn}
      />

      <PushButtonTabs defaultValue="prechart">
        <PushButtonTabsList>
          <PushButtonTab value="prechart">Prechart</PushButtonTab>
          <PushButtonTab value="labs">
            Labs
            <Chip
              label="Beta"
              color="warning"
              size="small"
              classes={{
                root: 'ml-2',
                label: 'text-primary font-[500]',
              }}
            />
          </PushButtonTab>
        </PushButtonTabsList>
        <PushButtonTabPanel className="mt-4" value="prechart">
          {children || (
            <Prechart
              isEncounterSigned={isEncounterSigned}
              patientID={patient}
              enforceSaveMissing={enforceSaveMissing}
              handleUnsaved={handleUnsaved}
            />
          )}{' '}
        </PushButtonTabPanel>
        <PushButtonTabPanel value="labs">
          <LabsProvider patientID={patient}>
            <EncounterLabs isEncounterSigned={isEncounterSigned} />
          </LabsProvider>
        </PushButtonTabPanel>
      </PushButtonTabs>
    </div>
  );
};

export default PatientProfileForEncounter;
