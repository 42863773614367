import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { IconButton, Menu, MenuItem } from '@mui/material';
import React, { useState } from 'react';
import { colors } from '../../theme';
import Typography from '../../components/Typography';

interface PatientOptionsMenuProps {
  className?: string;
  openDeletePatientModal: (value: boolean | undefined) => void;
}

const PatientOptionsMenu = ({
  className,
  openDeletePatientModal,
}: PatientOptionsMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={className}>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <SettingsOutlinedIcon sx={{ color: colors.gray }} />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => openDeletePatientModal(true)}>
          <Typography
            variant="bodySmall"
            customClass="text-red ml-2"
            text="Delete patient"
          />
        </MenuItem>
      </Menu>
    </div>
  );
};

export default PatientOptionsMenu;
