import { Input, InputProps } from '@mui/base/Input';
import { customTwMerge } from '../utils';
import Search from '../assets/icons/Search';
import { ReactNode } from 'react';

interface InputAdornmentProps {
  children: ReactNode;
}

const InputAdornment = ({ children }: InputAdornmentProps) => {
  return (
    <div className="mr-2 inline-flex items-center justify-center">
      {children}
    </div>
  );
};

const SearchInput = ({ className, ...props }: InputProps) => {
  return (
    <Input
      {...props}
      slotProps={{
        root: {
          className: customTwMerge(
            'bg-grayBackground w-full px-4 py-2 min-h-[40px] rounded-[6.25rem] text-bodySmall flex items-center',
            className
          ),
        },
        input: {
          className: 'w-full bg-transparent',
        },
      }}
      id={props.id}
      startAdornment={
        <InputAdornment>
          <Search />
        </InputAdornment>
      }
    />
  );
};

export default SearchInput;
