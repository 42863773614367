const Users = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 20 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 6.5C15.5938 6.5 14.5 5.40625 14.5 4C14.5 2.625 15.5938 1.5 17 1.5C18.375 1.5 19.5 2.625 19.5 4C19.5 5.40625 18.375 6.5 17 6.5ZM17 2.5C16.1562 2.5 15.5 3.1875 15.5 4C15.5 4.84375 16.1562 5.5 17 5.5C17.8125 5.5 18.5 4.84375 18.5 4C18.5 3.1875 17.8125 2.5 17 2.5ZM10 7.5C8.0625 7.5 6.5 5.9375 6.5 4C6.5 2.09375 8.0625 0.5 10 0.5C11.9062 0.5 13.5 2.09375 13.5 4C13.5 5.9375 11.9062 7.5 10 7.5ZM10 1.5C8.59375 1.5 7.5 2.625 7.5 4C7.5 5.40625 8.59375 6.5 10 6.5C11.375 6.5 12.5 5.40625 12.5 4C12.5 2.625 11.375 1.5 10 1.5ZM17.625 7.5C18.9062 7.5 20 8.625 20 10C20 10.2812 19.75 10.5 19.5 10.5C19.2188 10.5 19 10.2812 19 10C19 9.1875 18.375 8.5 17.625 8.5H16.375C16.1875 8.5 16.0312 8.5625 15.875 8.625C15.625 8.34375 15.375 8.125 15.0625 7.90625C15.4375 7.65625 15.875 7.5 16.375 7.5H17.625ZM3 6.5C1.59375 6.5 0.5 5.40625 0.5 4C0.5 2.625 1.59375 1.5 3 1.5C4.375 1.5 5.5 2.625 5.5 4C5.5 5.40625 4.375 6.5 3 6.5ZM3 2.5C2.15625 2.5 1.5 3.1875 1.5 4C1.5 4.84375 2.15625 5.5 3 5.5C3.8125 5.5 4.5 4.84375 4.5 4C4.5 3.1875 3.8125 2.5 3 2.5ZM12.5 8.125C13.625 8.125 14.7188 8.65625 15.375 9.59375C15.75 10.1875 16 10.875 16 11.625V13C16 13.8438 15.3125 14.5 14.5 14.5H5.5C4.65625 14.5 4 13.8438 4 13V11.625C4 10.875 4.21875 10.1875 4.59375 9.59375C5.25 8.65625 6.34375 8.125 7.46875 8.125C8.53125 8.125 8.78125 8.5 10 8.5C11.1875 8.5 11.4375 8.125 12.5 8.125ZM15 13H14.9688V11.625C14.9688 11.0938 14.8438 10.5938 14.5312 10.1562C14.125 9.53125 13.3438 9.125 12.5 9.125C11.625 9.125 11.3438 9.5 10 9.5C8.65625 9.5 8.34375 9.125 7.46875 9.125C6.625 9.125 5.84375 9.53125 5.4375 10.1562C5.125 10.5938 5 11.0938 5 11.625V13C5 13.2812 5.21875 13.5 5.5 13.5H14.5C14.75 13.5 15 13.2812 15 13ZM4.90625 7.90625C4.59375 8.125 4.34375 8.34375 4.09375 8.625C3.9375 8.5625 3.78125 8.5 3.625 8.5H2.375C1.59375 8.5 1 9.1875 1 10C1 10.2812 0.75 10.5 0.5 10.5C0.21875 10.5 0 10.2812 0 10C0 8.625 1.0625 7.5 2.375 7.5H3.625C4.09375 7.5 4.53125 7.65625 4.90625 7.90625Z"
        fill="#586068"
      />
    </svg>
  );
};

export default Users;
