import Patients from '../features/patients/Patients';

const PatientsPage = () => {
  return (
    <div className="container h-screen overflow-auto">
      <Patients />
    </div>
  );
};

export default PatientsPage;
