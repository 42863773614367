import { ReactNode, useEffect } from 'react';
import { useAuth } from './AuthProvider';

const events = [
  'load',
  'mousemove',
  'mousedown',
  'click',
  'scroll',
  'keypress',
];

interface Props {
  children?: ReactNode;
}

const AppLogout = ({ children }: Props) => {
  let timer: any;
  const { logout } = useAuth();

  const handleLogoutTimer = () => {
    timer = setTimeout(() => {
      resetTimer();
      Object.values(events).forEach((item) => {
        window.removeEventListener(item, resetTimer);
      });
      logout();
    }, 1200000); // is im ms (this is 20 min) 3600000 (this is 60 min)
  };

  const resetTimer = () => {
    if (timer) clearTimeout(timer);
  };

  useEffect(() => {
    Object.values(events).forEach((item) => {
      window.addEventListener(item, () => {
        resetTimer();
        handleLogoutTimer();
      });
    });
  });

  return children;
};

export default AppLogout;
