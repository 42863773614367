import React, { useState } from 'react';
import dayjs from 'dayjs';
import ButtonType from '../../../components/Button';
import SelectDropdown from '../../../components/SelectDropdown';
import BasicTextfield from '../../../components/Textfield';
import { Option } from '../../../components/Autocomplete';
import { colors } from '../../../theme';
import AppointmentOptions from '../AppointmentOptions';
import { NewEvent } from '../types';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { SelectChangeEvent } from '@mui/material/Select';
import { EventImpl } from '@fullcalendar/core/internal';
import SelectInvitees from './SelectInvitees';
import CalendarDatePicker from './CalendarDatePicker';

type ModalProps = {
  open: boolean;
  eventInfo: NewEvent;
  handleConfirm: (e: Partial<EventImpl>) => void;
  handleCancel?: () => void;
  handleClose: () => void;
};

const CreateApptModal = (props: ModalProps) => {
  const { open, handleClose, handleConfirm, eventInfo } = props;
  const [type, setType] = useState(0);
  const [blockTitle, setBlockTitle] = useState('Block');
  const [invitedPatients, setInvitedPatients] = useState<Option[]>([]);
  const [invitedStaff, setInvitedStaff] = useState<Option[]>([]);
  const [appt, setAppt] = useState('');
  const [disApp, setDisApp] = useState('');
  const [checked, setChecked] = useState(true);
  const [note, setNote] = useState('');
  const [color, setColor] = useState(colors.grayLight);
  const [startDate, setStartDate] = useState(
    eventInfo?.start
      ? dayjs(eventInfo.start)
      : dayjs().set('hour', 7).set('minute', 0)
  );
  const [endDate, setEndDate] = useState(() =>
    eventInfo?.end
      ? dayjs(eventInfo.end)
      : dayjs().set('hour', 7).set('minute', 30)
  );
  const disableButton = !appt;

  const handleApptChange = (event: SelectChangeEvent) => {
    setAppt(event.target.value as string);
    switch (event.target.value) {
      case 'Initial Visit':
        setColor(colors.pastelCyan);
        setDisApp('Initial Visit');
        break;
      case 'Return OB':
        setColor(colors.pastelGreen);
        setDisApp('Return OB');
        break;
      case 'Return GYN':
        setColor(colors.pastelYellow);
        setDisApp('Return GYN');
        break;
      case 'Prob GYN':
        setColor(colors.pastelOrange);
        setDisApp('Prob GYN');
        break;
      case 'Post Partum':
        setColor(colors.pastelBlue);
        setDisApp('PP');
        break;
      case 'Prenatal':
        setColor(colors.purple);
        setDisApp('Prenatal');
        break;
      case 'Block':
        setColor(colors.grayLight);
        setDisApp('Block');
        break;
      case 'General':
        setColor(colors.beige);
        setDisApp('General');
        break;
    }
  };

  const prepareInfo = () => {
    const start = startDate;
    const end = endDate;

    const apptInfo: Partial<EventImpl> & Record<string, unknown> = {
      invitedPatientIDs: invitedPatients.map((option) => option.id),
      invitedStaffIDs: invitedStaff.map((option) => option.id),
      blockTitle,
      appt,
      dispAppt: disApp,
      start: start.toDate(),
      end: end.toDate(),
      note,
      checked,
      color,
      isBlock: type === 1,
    };
    handleConfirm(apptInfo);
    setType(0);
    setBlockTitle('Block');
    setChecked(true);
    setInvitedPatients([]);
    setInvitedStaff([]);
    setNote('');
    setAppt('');
  };

  return (
    <Dialog
      open={open}
      keepMounted
      fullWidth
      maxWidth={'sm'}
      onClose={() => {
        handleClose();
        setStartDate(dayjs());
        setInvitedPatients([]);
        setNote('');
        setAppt('');
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <div className="flex flex-col items-center">
        <h2
          className={
            'xs:text-h6 md:text-h4 font-semibold my-5 flex flex-col items-center'
          }
        >
          Create New Event
        </h2>

        <DialogContent className="bg-white w-full flex flex-col items-center my-3 py-0">
          <SelectInvitees
            invitedPatients={invitedPatients}
            setInvitedPatients={setInvitedPatients}
            invitedStaff={invitedStaff}
            setInvitedStaff={setInvitedStaff}
          />
          <SelectDropdown
            required
            options={AppointmentOptions}
            handleChange={handleApptChange}
            value={appt}
            label="Appointment type"
          />

          <CalendarDatePicker
            startDate={startDate}
            onStartDateChange={setStartDate}
            endDate={endDate}
            onEndDateChange={setEndDate}
            eventInfo={eventInfo}
            isEdit={false}
          />
          <BasicTextfield
            id="1"
            variant="filled"
            label="Short note..."
            rows={4}
            value={note}
            multiline
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setNote(event.target.value);
            }}
          />
        </DialogContent>
      </div>
      <DialogActions className=" bg-white flex">
        <ButtonType
          variant="contained"
          onClick={prepareInfo}
          text="Confirm"
          classes={'!rounded-full'}
          disabled={disableButton}
        />
      </DialogActions>
    </Dialog>
  );
};

export default CreateApptModal;
