import { DoNotDisturb, MailOutline, MoreHoriz } from '@mui/icons-material';
import { IconButton, Menu, MenuItem } from '@mui/material';
import React, { useState } from 'react';
import { colors } from '../../theme';
import Typography from '../../components/Typography';

interface MemberOptionsMenuProps {
  token: string;
  email: string;
  openRevokeModal: (token: string, email: string) => void;
  sendInvite: (email: string) => void;
}

const MemberOptionsMenu = ({
  openRevokeModal,
  sendInvite,
  token,
  email,
}: MemberOptionsMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreHoriz />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem
          onClick={() => {
            sendInvite(email);
            handleClose();
          }}
        >
          <MailOutline sx={{ color: colors.gray }} />
          <Typography
            variant="bodySmall"
            customClass="text-gray ml-2"
            text="Resend invite"
          />
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            openRevokeModal(token, email);
          }}
        >
          <DoNotDisturb sx={{ color: colors.gray }} />
          <Typography
            variant="bodySmall"
            customClass="text-gray ml-2"
            text="Revoke invite"
          />
        </MenuItem>
      </Menu>
    </div>
  );
};

export default MemberOptionsMenu;
