import React, { useState, ChangeEvent, useEffect, useCallback } from 'react';
import { equals } from 'rambda';
import { CircularProgress } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ButtonType from '../../../components/Button';
import StyledDataGrid from '../../../components/StyledDataGrid';
import BasicTextfield from '../../../components/Textfield';
import { useSnackbar } from '../../../components/Snack';
import { colors } from '../../../theme';
import dayjs from 'dayjs';
import CollapsibleComponent from '../../../components/CollapsibleComponent';
import { useParams } from 'react-router-dom';
import { CreateVitalsDTO, VitalsDTO } from '@aster/shared/dtos/vitals';
import { usePatientVitals } from './queries/fetch-vitals.query';
import { useAddVitalsMutation } from './mutations/add-vital.mutation';

type VitalsProps = {
  enforceSaveMissing: boolean;
  disabled: boolean;
  handleUnsaved: (isDirty: boolean) => void;
  handleNote?: (note: string) => void;
};

const emptyState = (patientID: string, encounterID: string) =>
  ({
    bloodPressure: '',
    heartRate: undefined,
    temperature: undefined,
    bloodGlucose: undefined,
    weight: undefined,
    height: undefined,
    spo2: undefined,
    bmi: undefined,
    urineAnalysis: '',
    urineOutput: undefined,
    fetalHeartRate: undefined,
    patientID,
    encounterID,
  } satisfies CreateVitalsDTO);

const Vitals = ({
  disabled,
  enforceSaveMissing,
  handleNote,
  handleUnsaved,
}: VitalsProps) => {
  const { showMessage } = useSnackbar();
  const [open, setOpen] = useState(false);
  const { patient, encounterId } = useParams();

  const [state, setState] = useState<CreateVitalsDTO>(() =>
    emptyState(patient as string, encounterId as string)
  );

  const handleFormInput = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    isNumber?: boolean
  ) => {
    handleUnsaved(true);
    const value = event.target.value;
    const decimalRegex = /^\d*\.?\d*$/;
    if ((isNumber && decimalRegex.test(value)) || (!isNumber && value !== '')) {
      setState({
        ...state,
        [event.target.name]: value,
      });
    }
  };

  const { isLoading, vitals } = usePatientVitals(patient as string);
  const { addVitalsMutation } = useAddVitalsMutation(patient as string, () => {
    createNote();
    resetVitals();
    handleUnsaved(false);
    showMessage({
      message: 'Vitals saved successfully',
      type: 'success',
    });
  });

  function resetVitals() {
    setState(emptyState(patient as string, encounterId as string));
  }

  const createNote = () => {
    const note = `Vitals: 
      ${state.bloodPressure ? `BP: ${state.bloodPressure},` : ''}
      ${state.heartRate ? `HR: ${state.heartRate},` : ''}
      ${state.temperature ? `Temperature: ${state.temperature},` : ''}
      ${state.bloodGlucose ? `BG: ${state.bloodGlucose},` : ''}
      ${state.weight ? `Weight: ${state.weight},` : ''}
      ${state.height ? `Height: ${state.height},` : ''}
      ${state.spo2 ? `SpO2: ${state.spo2},` : ''}
      ${state.bmi ? `BMI: ${state.bmi},` : ''}
     at ${dayjs(new Date()).format('MMMM D, YYYY h:mm A')}.`;
    handleNote && handleNote(note);
  };

  const saveVitals = useCallback(() => {
    const newResult: CreateVitalsDTO = {
      bloodPressure: state.bloodPressure,
      heartRate: Number(state.heartRate),
      temperature: Number(state.temperature),
      bloodGlucose: Number(state.bloodGlucose),
      weight: Number(state.weight),
      height: Number(state.height),
      spo2: Number(state.spo2),
      bmi: state.bmi,
      patientID: patient as string,
      encounterID: encounterId,
    };

    addVitalsMutation.mutateAsync(newResult);
  }, [
    addVitalsMutation,
    encounterId,
    patient,
    state.bloodGlucose,
    state.bloodPressure,
    state.bmi,
    state.heartRate,
    state.height,
    state.spo2,
    state.temperature,
    state.weight,
  ]);

  const handleClose = () => setOpen(false);

  useEffect(() => {
    const isStateChanged = () => {
      return !equals(
        state,
        emptyState(patient as string, encounterId as string)
      );
    };
    if (enforceSaveMissing && isStateChanged()) {
      saveVitals();
    }
  }, [encounterId, enforceSaveMissing, patient, saveVitals, state]);

  const handleRadioValuesFocus = (
    event: React.FocusEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    if (!value) {
      setState((prevState) => ({
        ...prevState,
        [name]: ' /',
      }));
    }
    handleUnsaved(true);
  };

  const columns: GridColDef<VitalsDTO>[] = [
    {
      field: 'bloodPressure',
      headerName: 'BP',
      headerAlign: 'left',
      width: 100,
      headerClassName: 'bg-grayBackground',
      sortable: false,
    },
    {
      field: 'heartRate',
      headerName: 'HR',
      headerAlign: 'left',
      headerClassName: 'bg-grayBackground',
      sortable: false,
    },
    {
      field: 'temperature',
      headerName: 'Temp.',
      headerAlign: 'left',
      headerClassName: 'bg-grayBackground',
      sortable: false,
    },
    {
      field: 'bloodGlucose',
      headerName: 'BG',
      headerAlign: 'left',
      headerClassName: 'bg-grayBackground',
      sortable: false,
    },
    {
      field: 'weight',
      headerName: 'Weight',
      headerAlign: 'left',
      headerClassName: 'bg-grayBackground',
      sortable: false,
    },
    {
      field: 'height',
      headerName: 'Height',
      headerAlign: 'left',
      headerClassName: 'bg-grayBackground',
      sortable: false,
    },
    {
      field: 'spo2',
      headerName: 'SpO2',
      headerAlign: 'left',
      headerClassName: 'bg-grayBackground',
      sortable: false,
    },
    {
      field: 'bmi',
      headerName: 'BMI',
      headerAlign: 'left',
      headerClassName: 'bg-grayBackground',
      sortable: false,
    },
    {
      field: 'timeCreated',
      renderCell: (params) =>
        dayjs(params.row.timeCreated).format('MMMM D, YYYY h:mm A'),
      headerName: 'Date & Time',
      headerAlign: 'left',
      width: 200,
      headerClassName: 'bg-grayBackground',
      sortable: false,
    },
  ];

  return (
    <>
      <CollapsibleComponent label="Vitals" containerClass="relative">
        <div className="grid w-full h-full rounded-b-lg bg-grayCard self-stretch p-2.5">
          {!disabled && (
            <>
              <div className="flex flex-row flex-1 gap-4 pb-2">
                <BasicTextfield
                  id="bloodPressure"
                  variant="filled"
                  name="bloodPressure"
                  label="BP (mm/Hg)"
                  value={state.bloodPressure}
                  onChange={handleFormInput}
                  onFocus={handleRadioValuesFocus}
                  onKeyDown={saveVitals}
                />
                <BasicTextfield
                  id="heartRate"
                  variant="filled"
                  name="heartRate"
                  type="text"
                  label="HR (bpm)"
                  value={state.heartRate ?? ''}
                  onChange={(e) => handleFormInput(e, true)}
                  onKeyDown={saveVitals}
                />
              </div>
              <div className="flex flex-row flex-1 gap-4 pb-2">
                <BasicTextfield
                  id="temperature"
                  variant="filled"
                  name="temperature"
                  label="Temperature (ºF)"
                  value={state.temperature ?? ''}
                  onChange={(e) => handleFormInput(e, true)}
                  onKeyDown={saveVitals}
                />
                <BasicTextfield
                  id="bloodGlucose"
                  variant="filled"
                  name="bloodGlucose"
                  label="BG (mg/dl)"
                  value={state.bloodGlucose ?? ''}
                  onChange={(e) => handleFormInput(e, true)}
                  onKeyDown={saveVitals}
                />
              </div>
              <div className="flex flex-row flex-1 gap-4 pb-2">
                <BasicTextfield
                  id="weight"
                  variant="filled"
                  name="weight"
                  label="Weight (lb)"
                  value={state.weight ?? ''}
                  onChange={(e) => handleFormInput(e, true)}
                  onKeyDown={saveVitals}
                />
                <BasicTextfield
                  id="height"
                  variant="filled"
                  name="height"
                  label="Height (ft, in)"
                  value={state.height ?? ''}
                  onChange={(e) => handleFormInput(e, true)}
                  onKeyDown={saveVitals}
                />
              </div>
              <div className="flex flex-row flex-1 gap-4 pb-2">
                <BasicTextfield
                  id="spo2"
                  variant="filled"
                  name="spo2"
                  label="SpO2 (%)"
                  value={state.spo2 ?? ''}
                  onChange={(e) => handleFormInput(e, true)}
                  onKeyDown={saveVitals}
                />
                <BasicTextfield
                  id="bmi"
                  variant="filled"
                  name="bmi"
                  label="BMI (kg/m2)"
                  value={state.bmi ?? ''}
                  onChange={(e) => handleFormInput(e, true)}
                  onKeyDown={saveVitals}
                />
              </div>

              <ButtonType
                variant="contained"
                onClick={saveVitals}
                text="Add Vitals"
                classes={'max-h-[30px] mx-1'}
              />
            </>
          )}

          <StyledDataGrid
            rows={vitals || []}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5]}
            disableRowSelectionOnClick
            onCellClick={() => setOpen(true)}
            autoHeight
            slots={{
              noRowsOverlay: () => (
                <div className="flex justify-center items-center h-full">
                  No vitals yet.
                </div>
              ),
            }}
            sx={{
              backgroundColor: colors.white,
              overflow: 'auto',
              width: '100%',
              fontSize: '13px',
              textAlign: 'center',
              marginTop: 1,
            }}
          />
        </div>
      </CollapsibleComponent>

      <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        fullWidth
        maxWidth="md"
      >
        <div className="flex flex-col items-center">
          <DialogTitle sx={{ m: 0, p: 2, height: 10, fontWeight: 600 }}>
            Vitals
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent className="flex flex-col items-center w-full">
            {isLoading ? (
              <div className="flex flex-col justify-center items-center h-full w-full">
                <CircularProgress />
              </div>
            ) : (
              <StyledDataGrid
                rows={vitals || []}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 5,
                    },
                  },
                }}
                pageSizeOptions={[5]}
                disableRowSelectionOnClick
                autoHeight
                slots={{
                  noRowsOverlay: () => (
                    <div className="flex justify-center items-center h-full">
                      No vitals yet.
                    </div>
                  ),
                }}
                sx={{
                  backgroundColor: colors.white,
                  width: '100%',
                  overflow: 'auto',
                  fontSize: '13px',
                  textAlign: 'center',
                  marginTop: 1,
                }}
              />
            )}
          </DialogContent>
        </div>
      </Dialog>
    </>
  );
};

export default Vitals;
