import React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import dayjs, { Dayjs } from 'dayjs';

type CalendarSidebarProps = {
  currentDate: Dayjs;
  handleNav: (date: Dayjs) => void;
};

const CalendarSidebar = (props: CalendarSidebarProps) => {
  const { currentDate, handleNav } = props;
  const [value, setValue] = React.useState<Dayjs | null>(
    currentDate ?? dayjs()
  );

  return (
    <div className="w-full h-full flex justify-center items-center md:items-start">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateCalendar
          sx={{
            width: 250,
            '& .MuiButtonBase-root.MuiPickersDay-root:not(.Mui-selected)[aria-current="date"]':
              {
                marginTop: '3px',
                paddingTop: '2px',
                height: '28px',
                width: '30px',
              },
          }}
          value={value}
          onChange={(newValue: Dayjs) => {
            handleNav(newValue);
            setValue(newValue);
          }}
        />
      </LocalizationProvider>
    </div>
  );
};

export default CalendarSidebar;
