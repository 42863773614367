import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { motion } from 'framer-motion';
import ChevronLeft from '../../assets/icons/ChevronLeft';
import ChevronRight from '../../assets/icons/ChevronRight';
import CalendarViewMenu from './CalendarViewMenu';
import AddIcon from '@mui/icons-material/Add';
import ButtonType from '../../components/Button';

function CalendarHeader(props: any) {
  const { calendarRef, currentDate, handleCreate } = props;
  const calendarApi = () => calendarRef.current?.getApi();

  return (
    <div className="flex flex-col md:flex-row w-full flex-wrap justify-between z-10 container md:my-5 mb-2">
      <div className="flex flex-col md:flex-row items-center">
        <div className="flex items-center">
          <ButtonType
            variant="outlined"
            text="Today"
            onClick={() => calendarApi().today()}
            classes="
              bg-white border border-beige
              hover:bg-beige
              text-black text-body font-semibold
              px-2
              ml-2
              w-fit
            "
            notRounded
          />

          <Tooltip title="Previous">
            <IconButton
              aria-label="Previous"
              onClick={() => calendarApi().prev()}
              disableRipple
            >
              <ChevronLeft />
            </IconButton>
          </Tooltip>
          <Tooltip title="Next">
            <IconButton
              aria-label="Next"
              onClick={() => calendarApi().next()}
              disableRipple
            >
              <ChevronRight />
            </IconButton>
          </Tooltip>
          <div className="flex">
            <p className="text-h4 font-semibold">
              {currentDate?.view.title.slice(0, -4)}
            </p>
            <p className="text-h4 font-normal pl-2">
              {currentDate?.view.title.slice(-4)}
            </p>
          </div>
        </div>
      </div>

      <motion.div
        className="flex items-center justify-center mt-5 md:mt-0 shrink-0"
        initial={{ opacity: 1 }}
      >
        <div className="mr-[-40px]">
          <CalendarViewMenu calendarRef={calendarRef} />
        </div>
        <div className="mr-10">
          <ButtonType
            className="w-[185px]"
            variant="contained"
            text="New Event"
            classes={'mx-2'}
            icon
            specificIcon={<AddIcon sx={{ marginRight: 1 }} />}
            onClick={handleCreate}
          />
        </div>
      </motion.div>
    </div>
  );
}

export default CalendarHeader;
