import { useSnackbar } from '../../../components/Snack';
import axios from '../../../app/axiosConfig';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { EncounterDTO } from '@aster/shared/dtos/encounter';

export const useUpdateEncounterMutation = (
  encounterID?: string,
  onSuccess?: () => void,
  successMessage = 'Encounter saved successfully'
) => {
  const { showMessage } = useSnackbar();
  const queryClient = useQueryClient();

  const updateNoteMutation = async (info: Partial<EncounterDTO>) => {
    await axios.put(`encounters/${encounterID}`, info);
  };

  const updateEncounter = useMutation({
    mutationKey: ['updateEncounter'],
    mutationFn: updateNoteMutation,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['encounter', encounterID] });
      queryClient.invalidateQueries({ queryKey: ['patientProfile'] });
      onSuccess && onSuccess();
      showMessage({
        message: successMessage,
        type: 'success',
      });
    },
    onError: (error) => {
      showMessage({
        message:
          'An error occurred while saving your encounter. Please try again.',
        type: 'error',
        autoClose: false,
      });
    },
    retry: 3,
  });

  return { updateEncounter };
};
