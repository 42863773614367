import { Badge, styled } from '@mui/material';
import { colors } from '../theme';

export const NotificationBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    color: colors.white,
    backgroundColor: colors.red,
    minWidth: '15px',
    width: '15px',
    height: '15px',
    padding: '0',
  },
})) as typeof Badge;
