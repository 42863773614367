import React from 'react';
import programs from '../assets/empty-images/programs.webp';

const Programs = () => {
  return (
    <div className="flex flex-col justify-center items-center h-full w-full">
      <img src={programs} alt="Programs" width="481px" />
      <div className="flex flex-col items-center">
        <p className="text-h3 text-primary">Programs will be available soon</p>
        <p className="text-body text-gray">
          Create, manage, and distribute content to your patients.
        </p>
      </div>
    </div>
  );
};

export default Programs;
