import { useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

type PaginationModel = {
  page: number;
  pageSize: number;
};

type SortModel = {
  sortBy?: string;
  sortDir?: 'asc' | 'desc' | null;
};

export type UseQueryArgsProps = {
  searchDelay?: number;
  initialPaginationModel?: PaginationModel;
  initialSortModel?: SortModel;
};

const DEFAULT_PAGINATION_MODEL: PaginationModel = {
  page: 0,
  pageSize: 10,
};

const DEFAULT_SORT_MODEL: SortModel = {
  sortBy: 'timeUpdated',
  sortDir: 'desc',
};

function useSearchQueryArgs({
  searchDelay = 300,
  initialPaginationModel = DEFAULT_PAGINATION_MODEL,
  initialSortModel = DEFAULT_SORT_MODEL,
}: UseQueryArgsProps = {}) {
  const [paginationModel, setPaginationModel] = useState(
    initialPaginationModel
  );

  const [sortModel, setSortModel] = useState<SortModel>(initialSortModel);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const search = useDebouncedCallback((value: string) => {
    setSearchQuery(value);
    setPaginationModel((prev) => ({ ...prev, page: 0 }));
  }, searchDelay);

  return {
    paginationModel,
    setPaginationModel,
    searchQuery,
    search,
    sortModel,
    setSortModel,
  };
}

export default useSearchQueryArgs;
