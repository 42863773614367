import React from 'react';
import billing from '../assets/empty-images/billing.webp';

const Billing = () => {
  return (
    <div className="flex flex-col justify-center items-center h-full w-full">
      <img src={billing} alt="Billing" width="481px" />
      <div className="flex flex-col items-center">
        <p className="text-h3 text-primary">Billing will be available soon</p>
        <p className="text-body text-gray">
          Generate superbills and manage payments from clients.
        </p>
      </div>
    </div>
  );
};

export default Billing;
