import { useState, useMemo } from 'react';
import { CircularProgress } from '@mui/material';
import { GridColDef, GridEventListener } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate, useParams } from 'react-router-dom';
import axios from '../../../app/axiosConfig';
import dayjs from 'dayjs';
import { useQuery, useMutation } from '@tanstack/react-query';
import Typography from '../../../components/Typography';
import ConfirmationModal from '../../../components/ConfirmationModal';
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
import { colors } from '../../../theme';
import CodingModal from '../../notes/components/CodingModal';
import { Codes } from '../../notes/types/encounter';
import { EncounterDTO } from '@aster/shared/dtos/encounter';
import StyledDataGrid from '../../../components/StyledDataGrid';

const EncountersList = () => {
  const navigate = useNavigate();
  const { patient } = useParams();
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const [selectedEncounterID, setSelectedEncounterID] = useState<string | null>(
    null
  );
  const [selectedCodes, setSelectedCodes] = useState<Codes>({
    cptCodes: [],
    icdCodes: [],
  });
  const [codeModalOpen, setCodeModalOpen] = useState(false);

  const fetchEncounters = async () => {
    const response = await axios.get<EncounterDTO[]>(
      `encounters/byPatient/${patient}`
    );
    return response.data;
  };

  function sentenceCase(s: string): string {
    return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
  }
  const { data, isLoading, refetch } = useQuery({
    queryKey: ['fetchEncounters', patient],
    queryFn: fetchEncounters,
  });

  const selectedEncounter = useMemo(() => {
    if (selectedEncounterID) {
      return data?.find((r) => r.id === selectedEncounterID);
    }
    return undefined;
  }, [selectedEncounterID, data]);

  const handleChangeCodes = (codes: Codes) => {
    setSelectedCodes(codes);
  };

  const rows = useMemo(() => {
    if (data) {
      return data.map((r) => {
        const newRow = {
          id: r.id,
          start: dayjs(r.startTime).format('MMMM D, YYYY h:mm A'),
          byName: r.signedByName ? r.signedByName : r.lastModifiedByName,
          type:
            r.templateType === 'birthflow'
              ? 'Labor & Birth'
              : sentenceCase(r.templateType),
          status: r.signedBy ? 'Signed ' : 'Draft',
          codes: { cptCodes: r.cptCodes, icdCodes: r.icdCodes } as Codes,
        };
        return newRow;
      });
    }
    return [];
  }, [data]);

  type EncounterRow = (typeof rows)[0];

  const deleteNote = async () => axios.delete(`encounters/${deleteId}`);

  const deleteNoteMutation = useMutation({
    mutationKey: ['deleteNote'],
    mutationFn: deleteNote,
    onSuccess: () => {
      setOpenConfirmation(false);
      refetch();
    },
  });

  const handleDelete = () => {
    deleteNoteMutation.mutate();
  };

  const columns: GridColDef[] = [
    {
      field: 'type',
      headerName: 'Encounter Type',
      headerAlign: 'left',
      flex: 1.2,
      headerClassName: 'bg-grayBackground',
    },
    {
      field: 'status',
      headerName: 'Status',
      headerAlign: 'left',
      flex: 1,
      headerClassName: 'bg-grayBackground',
    },
    {
      field: 'start',
      headerName: 'Start',
      headerAlign: 'left',
      flex: 1.2,
      headerClassName: 'bg-grayBackground',
    },

    {
      field: 'byName',
      headerName: 'Last Saved By',
      headerAlign: 'left',
      flex: 1.2,
      headerClassName: 'bg-grayBackground',
    },
    {
      field: 'codes',
      headerName: 'Codes',
      headerAlign: 'left',
      flex: 2,
      headerClassName: 'bg-grayBackground',
      renderCell: (params: { row: EncounterRow }) => (
        <div className="flex flex-col overflow-x-scroll">
          <div className="flex flex-row">
            {params.row.codes.icdCodes.map((code: string) => (
              <div key={code} className="bg-grayLight rounded-[5px] px-1 mr-1">
                <Typography variant="bodySmall" text={code} />
              </div>
            ))}
          </div>
          <div className="flex flex-row mt-1">
            {params.row.codes.cptCodes.map((code: string) => (
              <div key={code} className="bg-pastelBlue rounded-[5px] px-1 mr-1">
                <Typography variant="bodySmall" text={code} />
              </div>
            ))}
          </div>
        </div>
      ),
    },
    {
      field: 'delete',
      headerName: 'Action',
      headerAlign: 'left',
      flex: 1,
      headerClassName: 'bg-grayBackground',
      renderCell: (params: { row: EncounterRow }) => (
        <div className="flex">
          <RequestQuoteOutlinedIcon
            className="cursor-pointer"
            sx={{ marginRight: '5px', color: colors.gray }}
            onClick={() => {
              setSelectedEncounterID(params.row.id);
              setSelectedCodes(params.row.codes);
              setCodeModalOpen(true);
            }}
          />
          <DeleteIcon
            className="cursor-pointer"
            onClick={() => {
              setDeleteId(params.row.id);
              setOpenConfirmation(true);
            }}
          />
        </div>
      ),
    },
  ];

  const handleCellClick: GridEventListener<'cellClick'> = (params) => {
    const { field, id } = params;
    if (field !== columns[columns.length - 1].field) {
      const found = rows.find((ele) => ele.id === id) || { id: '' };

      if (found.id) {
        navigate(`/encounter/${patient}/${found.id}`);
      }
    }
  };

  if (isLoading) {
    return (
      <div className="flex flex-col justify-center items-center h-full w-full">
        <CircularProgress />
      </div>
    );
  }

  if (data && data?.length > 0) {
    return (
      <div className="flex flex-col h-full w-full overflow-auto pb-10">
        <div className="grid">
          <StyledDataGrid
            rows={rows}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[10, 25, 50]}
            columns={columns}
            autoHeight
            onCellClick={handleCellClick}
            disableRowSelectionOnClick
            getRowClassName={() => 'cursor-pointer'}
            sx={{ overflow: 'auto', width: '100%' }}
          />
        </div>
        <ConfirmationModal
          open={openConfirmation}
          title="Delete Encounter"
          description={`Are you sure you want to delete this encounter?`}
          confirm="Delete"
          dismiss="Cancel"
          handleClose={() => setOpenConfirmation(false)}
          handleConfirm={handleDelete}
          handleCancel={() => setOpenConfirmation(false)}
        />
        <CodingModal
          encounterInfo={selectedEncounter}
          selectedCodes={selectedCodes}
          setSelectedCodes={handleChangeCodes}
          open={codeModalOpen}
          confirm={'Save'}
          dismiss={'Cancel'}
          handleClose={() => {
            setCodeModalOpen(false);
          }}
        />
      </div>
    );
  }

  if (data && data?.length === 0) {
    return (
      <div className="flex flex-col h-full justify-center items-center">
        <Typography
          variant="h3"
          text="You will find a list of your encounters here"
        />
        <Typography
          variant="h4"
          text="Generate notes for encounters, appointments and more."
          customClass="text-gray"
        />
      </div>
    );
  }
};

export default EncountersList;
