import { useState, useMemo, useEffect } from 'react';
import { Tooltip, CircularProgress, Snackbar } from '@mui/material';
import { GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import MedicationIcon from '@mui/icons-material/Medication';
import { useLocation, useNavigate } from 'react-router-dom';
import midwife from '../../assets/midwife.webp';
import ButtonType from '../../components/Button';
import Users from '../../assets/icons/Users';
import axios from '../../app/axiosConfig';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import RegisterPatient from './RegisterPatient';
import dayjs from 'dayjs';
import {
  calculateDueDate,
  calculateGestationalAge,
  formatMRN,
} from '../../utils';
import { colors } from '../../theme';
import CloseIcon from '@mui/icons-material/Close';
import { PatientListItemDTO } from '@aster/shared/dtos/patient';
import {
  PaginatedResult,
  SearchArgs,
  searchArgsToString,
} from '@aster/shared/dtos/search';
import StyledDataGrid from '../../components/StyledDataGrid';
import SearchInput from '../../components/SearchInput';
import useDataGridSearchQueryArgs from '../../hooks/useDataGridSearchQueryArgs';

const fetchPatients = async (searchArgs: SearchArgs) => {
  const response = await axios.get<PaginatedResult<PatientListItemDTO>>(
    `patients?${searchArgsToString(searchArgs)}`
  );
  return response.data;
};

const Patients = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const {
    paginationModel,
    setPaginationModel,
    searchQuery,
    search,
    sortModel,
    setDataGridSortModel,
  } = useDataGridSearchQueryArgs();

  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([]);

  const {
    data: paginatedPatients,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: [
      'patients',
      searchQuery,
      paginationModel.page,
      paginationModel.pageSize,
      sortModel.sortBy,
      sortModel.sortDir,
    ],
    queryFn: () =>
      fetchPatients({ search: searchQuery, ...paginationModel, ...sortModel }),
    placeholderData: keepPreviousData,
  });
  const [snackBarMessage, setSnackBarMessage] = useState<string | null>(null);

  const { state: deletedPatient } = useLocation();

  useEffect(() => {
    if (deletedPatient) {
      setSnackBarMessage('Patient deleted');
    }
    window.history.replaceState(null, '');
  }, [deletedPatient]);

  const rows = useMemo(() => {
    if (!paginatedPatients) return [];

    return paginatedPatients.items.map((r) => {
      const dueDate = calculateDueDate({
        lmp: r.lmp,
        edd: r.edd,
        dateOfConception: r.dateOfConception,
        dateToCalculateDueDate: r.dateToCalculateDueDate,
      });
      const gestationAge = calculateGestationalAge(dueDate);

      return {
        id: r.id,
        name: `${r.firstName} ${r.lastName}`,
        mrn: formatMRN(r.mrn),
        clientID: r.id,
        dateOfBirth: r.dateOfBirth ?? '-',
        dueDate: dueDate ? dayjs(dueDate).format('YYYY-MM-DD') : '-',
        gestationAge,
        lastVisit: r.lastVisit ? dayjs(r.lastVisit).format('YYYY-MM-DD') : '-',
        email: r.email,
        phone: r.phoneNumber,
      };
    });
  }, [paginatedPatients]);

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      headerAlign: 'left',
      flex: 1.2,
      headerClassName: 'bg-grayBackground',
    },
    {
      field: 'mrn',
      headerName: 'Client ID',
      headerAlign: 'left',
      flex: 1,
      headerClassName: 'bg-grayBackground',
    },
    {
      field: 'dateOfBirth',
      headerName: 'DOB',
      description: 'Date of Birth',
      headerAlign: 'left',
      flex: 0.5,
      headerClassName: 'bg-grayBackground',
    },
    {
      field: 'dueDate',
      headerName: 'Due Date',
      headerAlign: 'left',
      sortable: false,
      flex: 0.5,
      headerClassName: 'bg-grayBackground',
    },
    {
      field: 'gestationAge',
      headerName: 'Gest. Age',
      description: 'Gestation Age',
      headerAlign: 'left',
      sortable: false,
      flex: 0.5,
      headerClassName: 'bg-grayBackground',
    },
    {
      field: 'lastVisit',
      headerName: 'Last Encounter',
      headerAlign: 'left',
      sortable: false,
      flex: 1,
      headerClassName: 'bg-grayBackground',
    },
    {
      field: 'email',
      headerName: 'Email',
      headerAlign: 'left',
      flex: 1.3,
      headerClassName: 'bg-grayBackground',
    },
    {
      field: 'phone',
      headerName: 'Phone',
      headerAlign: 'left',
      flex: 1,
      headerClassName: 'bg-grayBackground',
    },
  ];

  if (isLoading) {
    return (
      <div className="flex flex-col justify-center items-center w-full h-full">
        <CircularProgress />
      </div>
    );
  }

  if (rows.length < 1 && !search) {
    return (
      <div className="full-w-container flex-col items-center">
        <img src={midwife} alt="Midwife" width={'250px'} height={'162px'} />
        <div className="flex flex-col items-center mb-10">
          <p className="text-h3 text-primary">Your patient list is empty</p>
          <p className="text-body text-gray ">
            Let's get started by adding your first patient.
          </p>
        </div>

        <ButtonType
          variant="contained"
          onClick={() => {
            setOpen(true);
          }}
          text="Add new patient"
          classes={'w-[300px]'}
        />
        <br />

        <Tooltip title="Importing an existing patient list is coming soon.">
          <div>
            <ButtonType
              variant="outlined"
              text="Import existing patient"
              classes={'mt-5 w-[300px]'}
              disabled
            />
          </div>
        </Tooltip>
        <RegisterPatient
          open={open}
          setOpen={setOpen}
          setSnackBarMessage={setSnackBarMessage}
        />
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={snackBarMessage !== null}
          onClose={() => setSnackBarMessage(null)}
          autoHideDuration={2000}
          message={snackBarMessage}
          sx={{
            backgroundColor: colors.gray,
            color: colors.white,
          }}
          action={<CloseIcon onClick={() => setSnackBarMessage(null)} />}
        />
      </div>
    );
  }

  return (
    <div className="flex flex-col full-w-container overflow-auto">
      <div className="flex justify-between mb-4 flex-wrap gap-y-2">
        <div className="flex flex-col">
          <div className="flex">
            <Users />
            <span className="text-h6 text-gray ml-2 ">Patient List</span>
          </div>
          <p className="text-h4 text-black font-semibold m-0">All Patients</p>
        </div>

        <div className="flex gap-4 flex-wrap self-center">
          <ButtonType
            variant="contained"
            onClick={() => setOpen(true)}
            text="Add new patient"
          />
        </div>
      </div>
      <div className="w-full h-fit mb-2 grid">
        <SearchInput
          onChange={(e) => search(e.currentTarget.value)}
          placeholder="Search by patient name"
          className="mb-3"
        />
        <StyledDataGrid
          rows={rows}
          rowCount={paginatedPatients?.queryMetadata.rowCount ?? -1}
          loading={isLoading || isFetching}
          paginationMode="server"
          sortingMode="server"
          onPaginationModelChange={setPaginationModel}
          onSortModelChange={setDataGridSortModel}
          paginationModel={paginationModel}
          pageSizeOptions={[5, 10, 25, 50]}
          columns={columns}
          autoHeight
          disableColumnFilter
          disableColumnMenu
          onRowSelectionModelChange={(
            newRowSelectionModel: GridRowSelectionModel
          ) => {
            setRowSelectionModel(newRowSelectionModel);
            const found = rows.find(
              (ele: any) => ele.id === newRowSelectionModel[0]
            ) || { id: '' };

            if (found.id) {
              navigate(`/patientProfile/${found.id}`);
            }
          }}
          rowSelectionModel={rowSelectionModel}
          getRowClassName={() => 'cursor-pointer'}
        />
      </div>
      <RegisterPatient
        open={open}
        setOpen={setOpen}
        setSnackBarMessage={setSnackBarMessage}
      />
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={snackBarMessage !== null}
        onClose={() => setSnackBarMessage(null)}
        autoHideDuration={2000}
        message={snackBarMessage}
        sx={{
          backgroundColor: colors.gray,
          color: colors.white,
        }}
        action={<CloseIcon onClick={() => setSnackBarMessage(null)} />}
      />
    </div>
  );
};

export default Patients;
