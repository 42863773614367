import axios from '../../app/axiosConfig';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { GridColDef, GridToolbarQuickFilter } from '@mui/x-data-grid';
import UploadDocumentModal from './UploadDocumentModal';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { CircularProgress, Snackbar } from '@mui/material';
import DocumentOptionsMenu from './DocumentOptionsMenu';
import { createId } from '@paralleldrive/cuid2';
import { colors } from '../../theme';
import { PutObjectCommand } from '@aws-sdk/client-s3';
import { getS3ClientWithCredentials } from './utils/get-s3-client-with-credentials';
import { DocumentIcon } from './DocumentIcon';
import { downloadFile } from './utils/download-file';
import { NoRowsOverlay } from './NoRowsOverlay';
import StyledDataGrid from '../../components/StyledDataGrid';
import { deleteFiles, fetchPatientFiles } from './utils/requests';

type PatientDocumentsProps = {
  openUploadModal: boolean;
  toggleUploadModal: (value: boolean | undefined) => void;
};

const PatientDocuments = ({
  openUploadModal,
  toggleUploadModal,
}: PatientDocumentsProps) => {
  const { patient } = useParams();
  const [isUploading, setIsUploading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const queryClient = useQueryClient();
  const cacheKey = ['patientFiles', patient];

  const postFiles = async (files: File[]) => {
    setIsUploading(true);
    const s3 = await getS3ClientWithCredentials();
    if (s3) {
      try {
        for (const file of files) {
          const s3key = `${patient}-${createId()}`;
          const s3object = await s3.send(
            new PutObjectCommand({
              Bucket: import.meta.env.VITE_DOCUMENTS_BUCKET,
              Key: s3key,
              Body: file,
            })
          );
          if (s3object.$metadata.httpStatusCode === 200) {
            await axios.post(`documents/${patient}`, {
              s3key,
              fileName: file.name,
              patientID: patient,
              folderID: null,
            });
          }
        }
      } catch (error) {
        setSnackBarMessage('Failed to upload file');
      }
    } else {
      setSnackBarMessage('Permission required to upload files');
    }
    setIsUploading(false);
  };

  const addFileMutation = useMutation({
    mutationKey: ['addFile'],
    mutationFn: postFiles,
    onMutate: async (files) => {
      setSnackBarMessage(
        `Uploading ${files.length} file${files.length > 1 ? 's' : ''}...`
      );
      setOpenSnackbar(true);
      const previousFiles = queryClient.getQueryData(cacheKey);
      queryClient.setQueryData(cacheKey, (old: any) => {
        const optimisticFiles = files.map((file) => ({
          id: createId(),
          fileName: file.name,
          s3key: '',
          timeCreated: '',
        }));
        return [...old, ...optimisticFiles];
      });
      return { previousFiles };
    },
    onError: (err, variables, context) => {
      queryClient.setQueryData(cacheKey, context?.previousFiles);
      setSnackBarMessage(`Failed to upload file`);
      setOpenSnackbar(true);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [cacheKey] });
      setIsUploading(false);
    },
    onSuccess: () => {
      setSnackBarMessage(`File upload complete`);
      setOpenSnackbar(true);
    },
  });

  const removeFileMutation = useMutation({
    mutationKey: ['removeFiles'],
    mutationFn: deleteFiles,
    onMutate: async (ids) => {
      setSnackBarMessage(`Deleting files...`);
      setOpenSnackbar(true);
      const previousFiles = queryClient.getQueryData(cacheKey);
      queryClient.setQueryData(cacheKey, (old: any) => {
        return old.filter((file: any) => !ids.includes(file.id));
      });
      return { previousFiles };
    },
    onError: (err, variables, context) => {
      queryClient.setQueryData(cacheKey, context?.previousFiles);
    },
    onSuccess: () => {
      setSnackBarMessage(`Files deleted`);
      setOpenSnackbar(true);
    },
  });

  const { data, isLoading } = useQuery({
    queryKey: cacheKey,
    queryFn: () => fetchPatientFiles(patient as string),
  });

  const rows: RowData[] = useMemo(() => {
    if (data) {
      return data.map((file: any) => {
        return {
          id: file.id,
          file: file.file,
          name: file.fileName,
          s3key: file.s3key,
          timeCreated: file.timeCreated,
        };
      });
    }
    return [];
  }, [data]);

  const columns: GridColDef[] = [
    {
      field: 'file',
      width: 50,
      renderCell: (params) => {
        const { name } = params.row as RowData;
        return <div className="flex items-center">{DocumentIcon(name)}</div>;
      },
    },
    { field: 'name', width: 200 },
    {
      field: 'id',
      flex: 1,
      align: 'right',

      renderCell: (params) => {
        const { id, s3key, name } = params.row as RowData;
        if (s3key === '' || s3key === null) {
          return <i className={'fa fa-spinner px-6 fa-spin'}></i>;
        }
        return (
          <DocumentOptionsMenu
            downloadFile={downloadFile}
            fileName={name}
            s3key={s3key}
            deleteFile={() => removeFileMutation.mutate([id])}
            id={id}
          />
        );
      },
    },
  ];

  const CustomToolBar = () => (
    <div className="flex justify-between m-2">
      <div className="flex flex-1">
        <GridToolbarQuickFilter className="flex-1 mr-8" />
      </div>
    </div>
  );

  if (isLoading) {
    return (
      <div className="flex flex-col justify-center items-center w-full h-full">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="pb-10">
      <div className="grid">
        <StyledDataGrid
          pageSizeOptions={[10, 25, 50]}
          slots={{
            toolbar: CustomToolBar,
            columnHeaders: () => null,
            noRowsOverlay: NoRowsOverlay,
          }}
          autoHeight
          columns={columns}
          rows={rows}
          disableColumnFilter
          disableDensitySelector
          disableColumnSelector
          disableColumnMenu
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          disableRowSelectionOnClick
        />
      </div>
      <UploadDocumentModal
        title="Upload Files"
        dismiss="Cancel"
        confirm="Upload"
        handleCancel={() => toggleUploadModal(false)}
        open={openUploadModal}
        handleConfirm={addFileMutation}
        handleClose={() => toggleUploadModal(false)}
        isUploading={isUploading}
      />
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        autoHideDuration={3000}
        message={snackBarMessage}
        sx={{
          backgroundColor: colors.gray,
          color: colors.white,
        }}
      />
    </div>
  );
};

export default PatientDocuments;
