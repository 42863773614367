const AsterLogo = () => {
  return (
    <svg
      width="92"
      height="40"
      viewBox="0 0 92 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.20842 25.5C3.02197 25.5 0.646973 22.8748 0.646973 18.109C0.646973 10.8393 5.83977 5.14459 12.8038 5.14459C15.38 5.14459 17.272 5.91195 18.3186 6.80048L20.9351 5.18498H23.1089L19.8885 20.3708C19.7677 20.9362 19.6872 21.2593 19.6872 21.542C19.6872 22.1074 19.969 22.3498 20.4521 22.3498C21.0559 22.3498 21.6194 21.9863 22.2233 21.3805L22.6661 20.9362L23.1089 22.269L22.7063 22.794C21.0961 24.773 19.5665 25.5 17.5135 25.5C15.1788 25.5 13.8504 24.5307 13.6088 22.6729C13.5283 22.3094 13.5283 21.8651 13.6088 21.3401C12.0792 23.8037 10.1067 25.5 7.20842 25.5ZM10.0665 21.8651C11.4754 21.8651 12.8038 20.9362 13.8906 19.2803L16.1046 8.77948C15.2995 7.89095 14.3737 7.44669 13.166 7.44669C10.0665 7.44669 7.24867 12.1721 7.24867 17.8263C7.24867 20.6939 8.13427 21.8651 10.0665 21.8651Z"
        fill="#203A3B"
      />
      <path
        d="M31.2169 25.5C28.1979 25.5 25.5411 25.0557 23.8102 24.4095L23.5284 19.3207H25.3398L26.2254 20.4111C28.0771 22.6325 29.325 23.1575 31.2572 23.1575C33.7127 23.1575 34.7996 22.3094 34.7996 20.815C34.7996 19.3207 33.7127 18.6341 30.6936 17.8667C25.7021 16.6147 23.7699 15.08 23.7699 11.5662C23.7699 7.56785 27.3928 5.14459 32.4648 5.14459C35.1216 5.14459 37.5369 5.58885 39.2275 6.19467L39.5093 11.0412H37.6979L36.8123 9.82956C35.4839 8.01212 34.5983 7.48708 32.6258 7.48708C30.6131 7.48708 29.3652 8.25444 29.3652 9.7084C29.3652 11.1624 30.4521 11.8893 33.9542 12.6567C38.7445 13.7472 40.4352 15.5242 40.4352 18.9976C40.4352 23.0767 36.8525 25.5 31.2169 25.5Z"
        fill="#203A3B"
      />
      <path
        d="M49.1106 25.5C44.9241 25.5 42.5491 23.8845 42.5491 19.1187V8.29483H39.8521V6.39661C42.7504 4.74071 44.1593 3.77141 46.4136 1.71163L47.6614 0.5H48.9898V5.54846H54.9877V8.29483H48.9898V18.9976C48.9898 21.1381 49.8352 22.1478 51.5661 22.1478C52.6127 22.1478 53.5386 21.8247 54.6657 20.8958L55.5915 22.067C54.0619 24.5711 51.9686 25.5 49.1106 25.5Z"
        fill="#203A3B"
      />
      <path
        d="M72.729 19.6034L73.8561 20.6131C72.2459 23.5614 69.2268 25.5 64.8794 25.5C58.5192 25.5 54.1315 21.9863 54.1315 15.3223C54.1315 8.90065 58.7607 5.14459 64.5976 5.14459C70.6357 5.14459 73.8158 8.53716 73.8158 14.3934V15.1204H60.9345C61.0955 20.3304 63.3497 21.8651 66.8921 21.8651C69.1866 21.8651 71.0383 21.2189 72.729 19.6034ZM64.5976 7.48708C62.5044 7.48708 61.2565 9.54685 60.9747 13.101L67.5764 12.7375C67.7374 9.46607 66.7713 7.48708 64.5976 7.48708Z"
        fill="#203A3B"
      />
      <path
        d="M85.0607 25.0961H73.0649V23.521L73.9505 23.2787C75.3594 22.8748 75.4399 22.3498 75.4399 20.7342V10.7585C75.4399 9.34491 75.1984 8.90065 74.1921 8.65832L73.1455 8.37561V6.84087L80.995 5.14459H81.9209L81.4378 9.91034C83.209 6.84087 85.6243 5.14459 88.0395 5.14459C90.4145 5.14459 91.8234 6.55816 91.8234 8.86026C91.8234 11.0008 90.4145 12.4548 88.3213 12.4548C86.3891 12.4548 85.101 11.2027 85.101 9.46607C85.101 9.26413 85.101 9.14297 85.1412 8.98142C84.0946 9.34491 82.9272 10.1527 81.9209 11.4855V20.7342C81.9209 22.3498 82.1222 22.8748 83.6518 23.1979L85.0607 23.521V25.0961Z"
        fill="#203A3B"
      />
      <path
        d="M29.3652 33.5C29.3652 30.7386 31.6038 28.5 34.3652 28.5H58.3652C61.1267 28.5 63.3652 30.7386 63.3652 33.5V34.5C63.3652 37.2614 61.1267 39.5 58.3652 39.5H34.3652C31.6038 39.5 29.3652 37.2614 29.3652 34.5V33.5Z"
        fill="#586068"
      />
      <path
        d="M34.6301 37.5C34.5646 37.5 34.5088 37.4767 34.4629 37.43C34.417 37.3833 34.3941 37.3267 34.3941 37.26V30.75C34.3941 30.6767 34.417 30.6167 34.4629 30.57C34.5088 30.5233 34.5646 30.5 34.6301 30.5H37.4828C38.014 30.5 38.4468 30.5833 38.7812 30.75C39.1157 30.91 39.3616 31.1333 39.519 31.42C39.6829 31.7067 39.7649 32.04 39.7649 32.42C39.7649 32.6733 39.719 32.8967 39.6272 33.09C39.5419 33.2767 39.4337 33.43 39.3026 33.55C39.178 33.67 39.0534 33.7633 38.9288 33.83C39.1845 33.95 39.4108 34.15 39.6075 34.43C39.8042 34.71 39.9026 35.0433 39.9026 35.43C39.9026 35.83 39.8141 36.1867 39.637 36.5C39.4599 36.8133 39.1976 37.06 38.8501 37.24C38.5025 37.4133 38.073 37.5 37.5615 37.5H34.6301ZM35.7614 36.44L37.4041 36.44C37.7582 36.44 38.0271 36.3433 38.2107 36.15C38.3943 35.95 38.4861 35.71 38.4861 35.43C38.4861 35.1367 38.391 34.8933 38.2009 34.7C38.0172 34.5067 37.7516 34.41 37.4041 34.41H35.7614V36.44ZM35.7614 33.37H37.3057C37.6533 33.37 37.9123 33.2867 38.0828 33.12C38.2599 32.9467 38.3484 32.7233 38.3484 32.45C38.3484 32.17 38.2599 31.9533 38.0828 31.8C37.9123 31.64 37.6533 31.56 37.3057 31.56H35.7614V33.37Z"
        fill="white"
      />
      <path
        d="M41.3736 37.5C41.3081 37.5 41.2523 37.4767 41.2064 37.43C41.1605 37.3833 41.1376 37.3267 41.1376 37.26V30.75C41.1376 30.6767 41.1605 30.6167 41.2064 30.57C41.2523 30.5233 41.3081 30.5 41.3736 30.5H45.7018C45.7739 30.5 45.8329 30.5233 45.8789 30.57C45.9248 30.6167 45.9477 30.6767 45.9477 30.75V31.41C45.9477 31.4767 45.9248 31.5333 45.8789 31.58C45.8329 31.6267 45.7739 31.65 45.7018 31.65H42.4655V33.42H45.4854C45.5575 33.42 45.6165 33.4433 45.6624 33.49C45.7083 33.53 45.7313 33.5867 45.7313 33.66V34.28C45.7313 34.3533 45.7083 34.4133 45.6624 34.46C45.6165 34.5 45.5575 34.52 45.4854 34.52H42.4655V36.35H45.7805C45.8526 36.35 45.9116 36.3733 45.9575 36.42C46.0034 36.4667 46.0264 36.5267 46.0264 36.6V37.26C46.0264 37.3267 46.0034 37.3833 45.9575 37.43C45.9116 37.4767 45.8526 37.5 45.7805 37.5H41.3736Z"
        fill="white"
      />
      <path
        d="M49.0076 37.5C48.942 37.5 48.8863 37.4767 48.8404 37.43C48.7945 37.3833 48.7715 37.3267 48.7715 37.26V31.73H46.9517C46.8862 31.73 46.8304 31.7067 46.7845 31.66C46.7386 31.6133 46.7157 31.5567 46.7157 31.49V30.75C46.7157 30.6767 46.7386 30.6167 46.7845 30.57C46.8304 30.5233 46.8862 30.5 46.9517 30.5H51.9685C52.0406 30.5 52.0996 30.5233 52.1455 30.57C52.1914 30.6167 52.2144 30.6767 52.2144 30.75V31.49C52.2144 31.5567 52.1914 31.6133 52.1455 31.66C52.0996 31.7067 52.0406 31.73 51.9685 31.73H50.1585V37.26C50.1585 37.3267 50.1356 37.3833 50.0897 37.43C50.0437 37.4767 49.9847 37.5 49.9126 37.5H49.0076Z"
        fill="white"
      />
      <path
        d="M52.0691 37.5C52.0166 37.5 51.9674 37.48 51.9215 37.44C51.8822 37.4 51.8625 37.35 51.8625 37.29C51.8625 37.2633 51.869 37.2333 51.8822 37.2L54.2528 30.75C54.2725 30.6833 54.3085 30.6267 54.361 30.58C54.42 30.5267 54.4987 30.5 54.5971 30.5H55.6693C55.7677 30.5 55.8431 30.5267 55.8955 30.58C55.948 30.6267 55.9873 30.6833 56.0136 30.75L58.3842 37.2C58.3908 37.2333 58.3941 37.2633 58.3941 37.29C58.3941 37.35 58.3744 37.4 58.335 37.44C58.2957 37.48 58.2498 37.5 58.1973 37.5H57.3415C57.2497 37.5 57.1809 37.48 57.135 37.44C57.0956 37.3933 57.0694 37.35 57.0563 37.31L56.5939 36.09H53.6724L53.2101 37.31C53.197 37.35 53.1675 37.3933 53.1216 37.44C53.0822 37.48 53.0167 37.5 52.9249 37.5H52.0691ZM54.0167 34.94H56.2496L55.1283 31.85L54.0167 34.94Z"
        fill="white"
      />
    </svg>
  );
};

export default AsterLogo;
