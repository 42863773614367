import React, { useState } from 'react';
import dayjs from 'dayjs';
import AddIcon from '@mui/icons-material/Add';
import { GridColDef } from '@mui/x-data-grid';
import BasicTextfield from '../../../components/Textfield';
import ButtonType from '../../../components/Button';
import CollapsibleComponent from '../../../components/CollapsibleComponent';
import Typography from '../../../components/Typography';
import StyledDataGrid from '../../../components/StyledDataGrid';
import { customTwMerge } from '../../../utils';
import { MedicationInfo } from './types/birth.types';
import TableDialog from './TableDialog';

type MedicationsProps = {
  data: MedicationInfo[];
  disabled: boolean;
  color?: string;
  handleNote: (note: string) => void;
  handleSave: (property: string, event: MedicationInfo[]) => void;
};

const Medications = ({
  data,
  disabled,
  color,
  handleSave,
  handleNote,
}: MedicationsProps) => {
  const [medication, setMedication] = useState<MedicationInfo>({
    id: 0,
    name: '',
    dose: '',
    route: '',
    dateTime: '',
    giver: '',
  });
  const medications = data || [];
  const [show, setShow] = useState<boolean>(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setMedication((prevMedication) => ({
      ...prevMedication,
      [name]: value,
    }));
  };

  const createNote = () => {
    const note = `Medication: ${medication.name}, ${medication.dose}, ${medication.route} administered at ${medication.dateTime} by ${medication.giver}.`;
    handleNote(note);
  };

  const handleSubmit = () => {
    handleSave('medications', [
      ...medications,
      { ...medication, id: medications.length + 1 },
    ]);
    createNote();
    setMedication({
      id: 0,
      name: '',
      dose: '',
      route: '',
      dateTime: '',
      giver: '',
    });
    setShow(!show);
  };

  const handleDateFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (!value) {
      setMedication((prevState) => ({
        ...prevState,
        [name]: dayjs().format('MM/DD/YYYY HH:mm:ss'),
      }));
    }
  };

  return (
    <CollapsibleComponent
      label="Medications Log"
      color={color}
      actions={
        !disabled && (
          <ButtonType
            variant="contained"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setShow(!show);
            }}
            text="Add"
            classes={'max-h-[30px]'}
            icon
            specificIcon={<AddIcon sx={{ fontSize: '15px' }} />}
          />
        )
      }
    >
      <div
        className={customTwMerge(
          'flex flex-col w-full h-full rounded-b-lg self-stretch p-2.5',
          color ? `${color}` : 'bg-grayCard'
        )}
      >
        {show && (
          <div className="mt-2 grid grid-cols-4 gap-2">
            <BasicTextfield
              containerClasses="col-span-2"
              id="name"
              variant="filled"
              name="name"
              label="Name"
              value={medication.name}
              onChange={handleChange}
            />
            <BasicTextfield
              containerClasses="col-span-2"
              id="dose"
              variant="filled"
              name="dose"
              label="Dose"
              value={medication.dose}
              onChange={handleChange}
            />
            <BasicTextfield
              containerClasses="col-span-2"
              id="route"
              variant="filled"
              name="route"
              label="Route"
              value={medication.route}
              onChange={handleChange}
            />
            <BasicTextfield
              containerClasses="col-span-2"
              id="giver"
              variant="filled"
              name="giver"
              label="Giver"
              value={medication.giver}
              onChange={handleChange}
            />
            <BasicTextfield
              containerClasses="col-span-3"
              id="dateTime"
              variant="filled"
              name="dateTime"
              label="Date/Time"
              value={medication.dateTime}
              onChange={handleChange}
              onFocus={handleDateFocus}
            />
            <ButtonType
              variant="contained"
              onClick={handleSubmit}
              text="Save"
              classes={'max-h-[30px] mt-3'}
            />
          </div>
        )}
        {medications.length > 0 && (
          <MedicationTable medications={medications} />
        )}
        {medications.length < 1 && (
          <Typography
            text="Below will be a list of medications"
            variant="bodySmall"
            customClass="text-gray"
          />
        )}
      </div>
    </CollapsibleComponent>
  );
};

interface MedicationTableProps {
  medications: MedicationInfo[];
}

const MedicationTable = ({ medications }: MedicationTableProps) => {
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const columns: GridColDef<(typeof medications)[number]>[] = [
    {
      field: 'name',
      headerName: 'Name',
      headerAlign: 'left',
      flex: 1.5,
      headerClassName: 'bg-grayBackground',
      sortable: false,
    },
    {
      field: 'dose',
      headerName: 'Dose',
      headerAlign: 'left',
      flex: 1,
      headerClassName: 'bg-grayBackground',
      sortable: false,
    },
    {
      field: 'route',
      headerName: 'Route',
      headerAlign: 'left',
      flex: 1,
      headerClassName: 'bg-grayBackground',
      sortable: false,
    },
    {
      field: 'dateTime',
      headerName: 'Date/Time',
      type: 'number',
      headerAlign: 'left',
      flex: 1.5,
      headerClassName: 'bg-grayBackground',
      sortable: false,
    },
    {
      field: 'giver',
      headerName: 'Giver',
      headerAlign: 'left',
      flex: 1,
      headerClassName: 'bg-grayBackground',
      sortable: false,
    },
  ];

  return (
    <div className="grid overflow-x-auto">
      <StyledDataGrid
        rows={medications}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
        disableRowSelectionOnClick
        autoHeight
        onCellClick={() => setShowDetailsModal(true)}
        slots={{
          noRowsOverlay: () => (
            <div className="flex justify-center items-center h-full">
              No medications yet.
            </div>
          ),
        }}
        sx={{
          backgroundColor: 'white',
          overflow: 'auto',
          fontSize: '12px',
          textAlign: 'center',
        }}
      />
      <TableDialog
        open={showDetailsModal}
        handleClose={() => setShowDetailsModal(false)}
      >
        <StyledDataGrid
          rows={medications}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          pageSizeOptions={[5]}
          disableRowSelectionOnClick
          autoHeight
          slots={{
            noRowsOverlay: () => (
              <div className="flex justify-center items-center h-full">
                No medications yet.
              </div>
            ),
          }}
          sx={{
            backgroundColor: 'white',
            overflow: 'auto',
            width: '100%',
            fontSize: '12px',
            textAlign: 'center',
          }}
        />
      </TableDialog>
    </div>
  );
};

export default Medications;
