const Prescription = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.08984 3.89706H10.1875C12.332 3.89706 14.125 5.69003 14.125 7.83456C14.125 9.94394 12.4727 11.6666 10.3984 11.7721L14.125 15.4986L18.7656 10.8228C18.9766 10.6119 19.3633 10.6119 19.5742 10.8228C19.7852 11.0338 19.7852 11.4205 19.5742 11.6314L14.8984 16.2721L19.5742 20.9478C19.7852 21.1588 19.7852 21.5455 19.5742 21.7564C19.3633 21.9674 18.9766 21.9674 18.7656 21.7564L14.125 17.0807L9.44922 21.7564C9.23828 21.9674 8.85156 21.9674 8.64062 21.7564C8.42969 21.5455 8.42969 21.1588 8.64062 20.9478L13.3164 16.2721L8.81641 11.7721H5.125V14.5846C5.125 14.901 4.84375 15.1471 4.5625 15.1471C4.24609 15.1471 4 14.901 4 14.5846V11.2096V5.02206C4 4.38925 4.45703 3.89706 5.08984 3.89706ZM9.0625 10.6471H10.1875C11.7344 10.6471 13 9.4166 13 7.83456C13 6.28769 11.7344 5.02206 10.1875 5.02206H5.125V10.6471H9.0625Z"
      fill="#586068"
    />
  </svg>
);

export default Prescription;
