import React, { ReactNode, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import { colors } from '../../theme';
import ButtonType from '../../components/Button';
import axios from '../../app/axiosConfig';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import EncountersList from './forms/EncountersList';
import ConfirmationModal from '../../components/ConfirmationModal';
import { usePatientForm } from './hooks/usePatientForm';
import { useLeavingPageProtection } from '../../hooks/usePageLeavingProtection';
import PatientDocuments from '../documents/PatientDocuments';
import ProfileTab from './profileTabs/ProfileTab';
import HealthTab from './profileTabs/HealthTab';
import { HorizontalLinkTab } from '../../components/Tabs';
import StyledHorizontalTab from './StyledHorizontalTab';
import Typography from '../../components/Typography';
import PatientProfileForEncounterHeader from '../notes/sections/PatientProfileForEncounterHeader';
import PreferencesTab from './profileTabs/PreferencesTab';
import { Add, FileUploadOutlined } from '@mui/icons-material';
import PatientOptionsMenu from './PatientOptionsMenu';
import ConfirmDeletePatientModal from './ConfirmDeletePatientModal';
import { deletePatient } from './requests';
import PatientLabs from '../labs/PatientLabs';
import { LabsProvider } from '../labs/LabsContext';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import { useSnackbar } from '../../components/Snack';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import { SelectChangeEvent } from '@mui/material/Select/SelectInput';
import styled from '@mui/system/styled';
import Select from '@mui/material/Select';
import { usePatientProfileQuery } from '../notes/queries/patient-profile.query';
import Prescriptions from '../dosespot/Prescriptions';
import AddPatientForm from '../dosespot/AddPatientForm';
import { useDosespot } from '../dosespot/DosespotProvider';
import Chip from '../../components/Chip';

const PatientProfile = () => {
  const navigate = useNavigate();
  const { patient } = useParams();
  const [value, setValue] = useState(0);
  const [type, setType] = useState('');
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [openDeletePatientModal, setOpenDeletePatientModal] = useState(false);
  const { showMessage } = useSnackbar();

  const dosespotContext = useDosespot();

  const toggleUploadModal = (value: boolean | undefined) => {
    if (value !== undefined) {
      setOpenUploadModal(value);
      return;
    }
    setOpenUploadModal(!openUploadModal);
  };

  const { patientProfile } = usePatientProfileQuery(patient as string);

  const fetchPatient = async () => {
    const response = await axios.get(`patients/${patient}`);
    return response.data;
  };

  const {
    data,
    isLoading,
    refetch: refetchPatient,
  } = useQuery({
    queryKey: ['patient', patient],
    queryFn: fetchPatient,
  });

  const createProfileMutation = async (newForm: any) =>
    axios.put(`patients/${patient}`, newForm);

  const queryClient = useQueryClient();

  const addProfileMutation = useMutation({
    mutationKey: ['createProfile'],
    mutationFn: createProfileMutation,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['patient', patient] });
      queryClient.invalidateQueries({ queryKey: ['patientForLabs', patient] });
      queryClient.invalidateQueries({
        queryKey: ['isPatientValidForLabs', patient],
      });
      queryClient.invalidateQueries({ queryKey: ['patientProfile', patient] });
      showMessage({
        type: 'success',
        message: 'The patient information has been saved',
      });
      setIsDirty(false);
    },
    onError: () => {
      showMessage({
        type: 'error',
        message: 'An error occurred while saving the patient information.',
      });
      setIsDirty(false);
    },
  });

  const sendIntakeForm = async () =>
    axios.post(`patients/${patient}/sendIntakeFormLink`);

  const sendIntakeFormMutation = useMutation({
    mutationFn: sendIntakeForm,
    onSuccess: () => {
      showMessage({
        type: 'success',
        message: `Intake form link sent to ${data.email}`,
      });
    },
    onError: () => {
      showMessage({
        type: 'error',
        message: 'An error occurred while sending the intake form link',
      });
    },
  });

  const deletePatientMutation = useMutation({
    mutationKey: ['deletePatient'],
    mutationFn: deletePatient,
    onError: () => {
      showMessage({
        type: 'error',
        message: 'An error occurred while deleting the patient',
      });
    },
    onSuccess: () => {
      showMessage({
        type: 'success',
        message: `The patient was successfuly archived`,
      });
      queryClient.invalidateQueries({ queryKey: ['patients'] });
      navigate('/patients', { state: { deletedPatient: true } });
    },
  });

  const {
    isDirty,
    setIsDirty,
    acccessInfo,
    commuInformation,
    additionalInformation,
    socialInformation,
    lifestyleInformation,
    medicalHistory,
    obstetricInformation,
    pregnancyInformation,
    gynHis,
    referralInfo,
    preferredPharmacyInfo,
    contactListInfo,
    insurance,
    relationship,
    contactInformation,
    personalInformation,
    handleSocialInfo,
    handleGynDate,
    handleBirthday,
    handleGynHisInfo,
    handleContactInfo,
    handleGynHisOption,
    handlePersonalInfo,
    handlePregancyInfo,
    handlePregnancyCheck,
    handleReferralInfo,
    handleInsuranceInfo,
    handleAdditionalInfo,
    handleLifestylelInfo,
    handlePregnancyDates,
    handleCheckboxesCommu,
    handleCheckGynHisInfo,
    handleContactListInfo,
    handleLifestyleOption,
    handleCheckboxesAccess,
    handleRelationshipInfo,
    handleSocialInfoOption,
    handleCheckboxesSocialInfo,
    handleCheckboxesGynHist,
    handleCheckboxesMedical,
    handleCheckInsuranceInfo,
    handleMedicalHistoryInfo,
    handleMedicalHistoryAllergies,
    handleMedicalHistoryCurrentMedications,
    handlePersonalInfoSelect,
    handlePregancyInfoOption,
    handleReferralInfoSelect,
    handlePreferredPharmacyInfo,
    handleCheckboxesLifestyle,
    handleMedicalHistoryOption,
    handleContactListInfoSelect,
    handlePregnanciesInformation,
    saveInfo,
  } = usePatientForm({
    data,
    patientID: patient,
    updatePatientMutation: addProfileMutation,
  });

  // Block navigating elsewhere when form is dirty
  const { blocker } = useLeavingPageProtection({ canLeave: !isDirty });

  const handleClose = (confirmed: boolean) => {
    if (confirmed) {
      blocker?.proceed?.();
      setIsDirty(false); // Reset only if the user confirms leaving
    } else {
      blocker?.reset?.();
    }
  };

  const addNoteMutation = async (info: any) => axios.post(`encounters/`, info);

  const addNote = useMutation({
    mutationKey: ['addNote'],
    mutationFn: addNoteMutation,
    onSuccess: (response) => {
      const encounterId = response.data.id;
      if (encounterId !== undefined) {
        navigate(`/encounter/${patient}/${encounterId}`);
      }
    },
    onSettled: () => {
      setType('');
    },
  });

  const handleChangeTab = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  if (isLoading) {
    return (
      <div className="flex flex-1 flex-col justify-center items-center">
        <CircularProgress />
      </div>
    );
  }

  const handleChangeType = (event: SelectChangeEvent) => {
    const typeValue = event.target.value as string;
    setType(typeValue);
    const info = {
      patientID: patient,
      templateType: typeValue,
    };
    addNote.mutate(info);
  };

  const Options = [
    { value: 'basic', text: 'Basic' },
    { value: 'birthflow', text: 'Labor & Birth' },
    { value: 'prenatal', text: 'Prenatal' },
    { value: 'soap', text: 'SOAP' },
  ] as const;

  const CustomSelect = styled(Select)({
    '& .MuiSelect-outlined': {
      borderRadius: '20px',
      backgroundColor: 'transparent',
      borderColor: colors.primary,
      '&:focus': {
        borderRadius: '20px',
        backgroundColor: 'transparent',
      },
    },
  });

  const SelectTypeComponent = () => {
    return addNote.status === 'pending' ? (
      <CircularProgress size={20} />
    ) : (
      <FormControl>
        <CustomSelect
          placeholder="Create Note"
          className="rounded-full bg-transparent text-body font-semibold border-1"
          defaultValue="Create Note"
          variant="outlined"
          value={type}
          displayEmpty
          onChange={
            handleChangeType as (
              event: SelectChangeEvent<unknown>,
              child: ReactNode
            ) => void
          }
          inputProps={{ 'aria-label': 'Without label' }}
          style={{ height: 40, width: 150, borderRadius: 20 }}
        >
          <MenuItem value="" disabled>
            Create Note
          </MenuItem>
          {Options.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {item.text}{' '}
              {item.value === 'birthflow' && (
                <Chip
                  label="Beta"
                  color="warning"
                  classes={{
                    root: 'ml-2',
                    label: 'text-primary font-[500]',
                  }}
                />
              )}
            </MenuItem>
          ))}
        </CustomSelect>
      </FormControl>
    );
  };

  const HeaderButtons = () => {
    return (
      <div className="flex items-center">
        {value === 3 && <SelectTypeComponent />}
        {value === 5 && (
          <ButtonType
            variant="contained"
            text={'Upload Files'}
            onClick={() => setOpenUploadModal(true)}
            icon={true}
            specificIcon={
              <FileUploadOutlined htmlColor={colors.white} className="mr-2" />
            }
          />
        )}
        {[0, 1, 2, 4, 6, 7].includes(value) && (
          <div className="flex gap-2">
            {
              <ButtonType
                loading={sendIntakeFormMutation.status === 'pending'}
                disabled={sendIntakeFormMutation.status === 'pending'}
                variant="outlined"
                text="Send intake form"
                onClick={() => sendIntakeFormMutation.mutate()}
              />
            }
            <ButtonType
              loading={addProfileMutation.status === 'pending'}
              disabled={addProfileMutation.status === 'pending'}
              variant="contained"
              text="Save"
              onClick={saveInfo}
            />
          </div>
        )}
        {patient && (
          <PatientOptionsMenu
            className="ml-2"
            openDeletePatientModal={() => setOpenDeletePatientModal(true)}
          />
        )}
      </div>
    );
  };

  if (data) {
    return (
      <div className="grid grid-rows-fill-last h-full overflow-auto">
        <div className="flex justify-between w-full bg-white p-6 border-grayLight">
          <div className="flex w-full justify-between flex-wrap gap-2">
            {patientProfile && (
              <PatientProfileForEncounterHeader
                name={patientProfile.name}
                mrn={patientProfile.mrn}
                edd={patientProfile.edd}
                dateOfConception={patientProfile.dateOfConception}
                lmp={patientProfile.lmp}
                dateToCalculateDueDate={patientProfile.dateToCalculateDueDate}
                gtpal={patientProfile.gtpal}
                age={patientProfile.age}
                dateOfBirth={patientProfile.dateOfBirth}
                problemList={patientProfile.problemList}
              />
            )}
          </div>
        </div>

        <div className="full-w-container py-0 mb-4 flex flex-col h-full overflow-y-auto">
          <div className="flex flex-wrap justify-between mb-3 gap-2">
            <div className="shrink-0 flex bg-grayBackground p-1 rounded-xl w-fit">
              <Tabs
                className="flex items-center h-auto"
                allowScrollButtonsMobile
                scrollButtons
                variant="standard"
                orientation="horizontal"
                value={value}
                onChange={handleChangeTab}
                classes={{
                  flexContainer: 'gap-x-1',
                }}
                style={{ minHeight: 'auto' }}
              >
                <StyledHorizontalTab
                  label={<Typography variant="bodyMedium" text="Profile" />}
                />
                <StyledHorizontalTab
                  label={
                    <Typography variant="bodyMedium" text="Health Records" />
                  }
                />
                <StyledHorizontalTab
                  label={<Typography variant="bodyMedium" text="Patient Rx" />}
                />
                <StyledHorizontalTab
                  label={<Typography variant="bodyMedium" text="Encounters" />}
                />
                <StyledHorizontalTab
                  label={
                    <Typography variant="bodyMedium">
                      Labs{' '}
                      <Chip
                        label="Beta"
                        color="warning"
                        classes={{
                          label: 'text-primary font-[500]',
                        }}
                      />
                    </Typography>
                  }
                />
                <StyledHorizontalTab
                  label={<Typography variant="bodyMedium" text="Documents" />}
                />
                <StyledHorizontalTab
                  label={<Typography variant="bodyMedium" text="Preferences" />}
                />
              </Tabs>
            </div>
            <HeaderButtons />
          </div>

          <Divider className="bg-grayLight mb-4" />

          <HorizontalLinkTab value={value} index={0}>
            <ProfileTab
              personalInformation={personalInformation}
              handlePersonalInfo={handlePersonalInfo}
              handleBirthday={handleBirthday}
              handlePersonalInfoSelect={handlePersonalInfoSelect}
              contactInformation={contactInformation}
              handleContactInfo={handleContactInfo}
              relationship={relationship}
              handleRelationshipInfo={handleRelationshipInfo}
              insurance={insurance}
              handleInsuranceInfo={handleInsuranceInfo}
              handleCheckInsuranceInfo={handleCheckInsuranceInfo}
              contactListInfo={contactListInfo}
              preferredPharmacyInfo={preferredPharmacyInfo}
              referralInfo={referralInfo}
              handleReferralInfo={handleReferralInfo}
              handleReferralInfoSelect={handleReferralInfoSelect}
              handlePreferredPharmacyInfo={handlePreferredPharmacyInfo}
              handleContactListInfo={handleContactListInfo}
              handleContactListInfoSelect={handleContactListInfoSelect}
            />
          </HorizontalLinkTab>
          <HorizontalLinkTab value={value} index={1}>
            <HealthTab
              gynHis={gynHis}
              handleGynHisOption={handleGynHisOption}
              handleGynHisInfo={handleGynHisInfo}
              handleCheckGynHisInfo={handleCheckGynHisInfo}
              handleCheckboxesGynHist={handleCheckboxesGynHist}
              handleGynDate={handleGynDate}
              pregnancyInformation={pregnancyInformation}
              handlePregancyInfo={handlePregancyInfo}
              handlePregnancyCheck={handlePregnancyCheck}
              handlePregancyInfoOption={handlePregancyInfoOption}
              handlePregnancyDates={handlePregnancyDates}
              obstetricInformation={obstetricInformation}
              handlePregnanciesInformation={handlePregnanciesInformation}
              medicalHistory={medicalHistory}
              handleMedicalHistoryOption={handleMedicalHistoryOption}
              handleMedicalHistoryInfo={handleMedicalHistoryInfo}
              handleMedicalHistoryAllergies={handleMedicalHistoryAllergies}
              handleMedicalHistoryCurrentMedications={
                handleMedicalHistoryCurrentMedications
              }
              handleCheckboxesMedical={handleCheckboxesMedical}
              lifestyleInformation={lifestyleInformation}
              handleLifestyleOption={handleLifestyleOption}
              handleLifestylelInfo={handleLifestylelInfo}
              handleCheckboxesLifestyle={handleCheckboxesLifestyle}
              socialInformation={socialInformation}
              handleCheckboxesSocialInfo={handleCheckboxesSocialInfo}
              handleSocialInfoOption={handleSocialInfoOption}
              handleSocialInfo={handleSocialInfo}
              handleAdditionalInfo={handleAdditionalInfo}
              additionalInformation={additionalInformation}
            />
          </HorizontalLinkTab>
          <HorizontalLinkTab value={value} index={2} classes="h-full">
            <>
              {!data.dosespotPatientId &&
              dosespotContext.dosespotData.client_id &&
              dosespotContext.dosespotData.client_secret &&
              dosespotContext.dosespotData.username &&
              dosespotContext.dosespotData.password ? (
                <AddPatientForm
                  patient={data}
                  refetchPatient={refetchPatient}
                />
              ) : (
                <Prescriptions dosespotPatientId={data.dosespotPatientId} />
              )}
            </>
          </HorizontalLinkTab>
          <HorizontalLinkTab value={value} index={3}>
            <EncountersList />
          </HorizontalLinkTab>
          <HorizontalLinkTab value={value} index={4}>
            <LabsProvider patientID={patient}>
              <PatientLabs />
            </LabsProvider>
          </HorizontalLinkTab>
          <HorizontalLinkTab value={value} index={5}>
            <PatientDocuments
              openUploadModal={openUploadModal}
              toggleUploadModal={toggleUploadModal}
            />
          </HorizontalLinkTab>
          <HorizontalLinkTab value={value} index={6}>
            <PreferencesTab
              acccessInfo={acccessInfo}
              handleCheckboxesAccess={handleCheckboxesAccess}
              handleCheckboxesCommu={handleCheckboxesCommu}
              commuInformation={commuInformation}
            />
          </HorizontalLinkTab>
        </div>
        <ConfirmationModal
          open={blocker.state === 'blocked'}
          title="Unsaved Changes"
          description={`Are you sure you want to leave? Changes may not be saved.`}
          confirm="Leave"
          dismiss="Cancel"
          handleClose={() => handleClose(false)}
          handleConfirm={() => handleClose(true)}
          handleCancel={() => handleClose(false)}
        />
        <ConfirmDeletePatientModal
          patientID={patient as string}
          open={openDeletePatientModal}
          handleClose={() => setOpenDeletePatientModal(false)}
          handleConfirm={deletePatientMutation}
        />
      </div>
    );
  }

  return (
    <div className="flex flex-col justify-center items-center h-full">
      <CircularProgress />
    </div>
  );
};

export default PatientProfile;
