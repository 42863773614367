import { colors } from '../../theme';

const circle = (color: string) => {
  return (
    <span
      className={`h-[15px] w-[15px] rounded-full mr-2`}
      style={{ backgroundColor: color }}
    ></span>
  );
};

const AppointmentOptions = [
  {
    text: 'Initial Visit',
    value: 'Initial Visit',
    extra: circle(colors.pastelCyan),
  },
  {
    text: 'Prenatal',
    value: 'Prenatal',
    extra: circle(colors.purple),
  },
  {
    text: 'Return OB',
    value: 'Return OB',
    extra: circle(colors.pastelGreen),
  },
  {
    text: 'Return GYN',
    value: 'Return GYN',
    extra: circle(colors.pastelYellow),
  },
  {
    text: 'Prob GYN',
    value: 'Prob GYN',
    extra: circle(colors.pastelOrange),
  },
  {
    text: 'Post Partum',
    value: 'Post Partum',
    extra: circle(colors.pastelBlue),
  },
  {
    text: 'Block',
    value: 'Block',
    extra: circle(colors.gray),
  },
  {
    text: 'General',
    value: 'General',
    extra: circle(colors.beige),
  },
];

export default AppointmentOptions;
