import DynamicCheckboxes from '../components/DynamicCheckboxes';
import CollapsibleText from '../../../components/CollapsibleText';
import Vitals from './Vitals';
import { useEffect, useState } from 'react';
import Tag from '../../../components/Tag';
import { colorPerInitial } from '../../../utils';
import axios from '../../../app/axiosConfig';
import CircularProgress from '@mui/material/CircularProgress';
import { PatientProfileForEncounterDTO } from '@aster/shared/dtos/patient';
import { usePatientProfileQuery } from '../queries/patient-profile.query';
import { useMutation } from '@tanstack/react-query';

export type PrechartProps = {
  patientID?: string;
  isEncounterSigned: boolean;
  enforceSaveMissing: boolean;
  handleUnsaved: (isDirty: boolean) => void;
  handleNote?: (note: string) => void;
};

const Problems = ({
  patientProfile,
}: {
  patientProfile?: PatientProfileForEncounterDTO;
}) => {
  return patientProfile?.problemList.map((item) => (
    <div key={item.abbr} className="flex gap-2 items-center mb-2">
      <Tag
        tagText={item.abbr}
        color={colorPerInitial(item.abbr.charAt(0)).split('-')[1]}
      />
      <p>{item.name}</p>
    </div>
  ));
};

const Prechart = ({
  patientID,
  isEncounterSigned,
  enforceSaveMissing,
  handleUnsaved,
  handleNote,
}: PrechartProps) => {
  const [state, setState] = useState({
    additionalproblem: '',
    important: '',
    prechartnotes: '',
    vaccinationnotes: '',
    medicationnotes: '',
  });

  // TODO: When the encounter is signed we need to fetch from the `/patients/byEncounter/{encounterID}`
  // endpoint instead of the `/patients/{patientID}/profile` endpoint
  // That endpoint will return the snapshot of the patient profile at the moment of signing
  const { patientProfile, isPatientProfileLoading } = usePatientProfileQuery(
    patientID as string
  );
  const createProfileMutation = async (newForm: any) =>
    axios.put(`patients/${patientID}/profile`, newForm);

  const addProfileMutation = useMutation({
    mutationKey: ['reateProfile'],
    mutationFn: createProfileMutation,
    onSuccess: () => {
      handleUnsaved(false);
    },
    onMutate: () => {
      handleUnsaved(true);
    },
  });

  const saveInfo = () => {
    const info = {
      additionalProblems: state.additionalproblem,
      importantNotes: state.important,
      preChartNotes: state.prechartnotes,
      vaccinationNotes: state.vaccinationnotes,
      medicationNotes: state.medicationnotes,
      checklist: {
        ...patientProfile?.checklist,
      },
    };
    addProfileMutation.mutate(info);
  };

  useEffect(() => {
    if (patientProfile) {
      setState({
        additionalproblem: patientProfile.additionalProblems ?? '',
        important: patientProfile.importantNotes ?? '',
        prechartnotes: patientProfile.preChartNotes ?? '',
        vaccinationnotes: patientProfile.vaccinationNotes ?? '',
        medicationnotes: patientProfile.medicationNotes ?? '',
      });
    }
  }, [patientProfile]);

  const handleFormInput = (event: any) => {
    handleUnsaved(true);
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };

  if (isPatientProfileLoading || !patientProfile) {
    return (
      <div className="flex flex-col justify-center items-center h-full w-[400px]">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-[15px]">
      <Vitals
        disabled={isEncounterSigned}
        enforceSaveMissing={enforceSaveMissing}
        handleUnsaved={handleUnsaved}
        handleNote={handleNote}
      />
      <div>
        <CollapsibleText
          label="Problem list"
          textValue={state.additionalproblem}
          name="additionalproblem"
          rows={2}
          handleChange={handleFormInput}
          handleBlur={saveInfo}
          extraComponent={<Problems />}
          disabled={isEncounterSigned}
        />
      </div>
      <DynamicCheckboxes data={patientProfile} disabled={isEncounterSigned} />
      <div>
        <CollapsibleText
          label="Important"
          textValue={state.important}
          name="important"
          rows={2}
          handleChange={handleFormInput}
          handleBlur={saveInfo}
          disabled={isEncounterSigned}
        />
      </div>
      <div>
        <CollapsibleText
          label="Vaccinations"
          textValue={state.vaccinationnotes}
          name="vaccinationnotes"
          rows={2}
          handleChange={handleFormInput}
          handleBlur={saveInfo}
          disabled={isEncounterSigned}
        />
      </div>
      <div>
        <CollapsibleText
          label="Medications"
          textValue={state.medicationnotes}
          name="medicationnotes"
          rows={2}
          handleChange={handleFormInput}
          handleBlur={saveInfo}
          disabled={isEncounterSigned}
        />
      </div>
      <div>
        <CollapsibleText
          label="Prechart notes"
          textValue={state.prechartnotes}
          name="prechartnotes"
          rows={2}
          handleChange={handleFormInput}
          handleBlur={saveInfo}
          disabled={isEncounterSigned}
        />
      </div>
    </div>
  );
};

export default Prechart;
