import { Tabs, tabsClasses } from '@mui/material';
import StyledTab from '../StyledTab';
import { useState } from 'react';
import PersonalInformation from '../forms/PersonalInformation';
import ContactInformation from '../forms/ContactInformation';
import Relationship from '../forms/Relationship';
import Insurance from '../forms/Insurance';
import ContactList from '../forms/ContactList';
import { LinkTab } from '../../../components/Tabs';
import { usePatientForm } from '../hooks/usePatientForm';

type ProfileTabProps = Pick<
  ReturnType<typeof usePatientForm>,
  | 'personalInformation'
  | 'handlePersonalInfo'
  | 'handleBirthday'
  | 'handlePersonalInfoSelect'
  | 'contactInformation'
  | 'handleContactInfo'
  | 'relationship'
  | 'handleRelationshipInfo'
  | 'insurance'
  | 'handleInsuranceInfo'
  | 'handleCheckInsuranceInfo'
  | 'contactListInfo'
  | 'preferredPharmacyInfo'
  | 'referralInfo'
  | 'handleReferralInfo'
  | 'handleReferralInfoSelect'
  | 'handlePreferredPharmacyInfo'
  | 'handleContactListInfo'
  | 'handleContactListInfoSelect'
>;

const ProfileTab = ({
  personalInformation,
  handlePersonalInfo,
  handleBirthday,
  handlePersonalInfoSelect,
  contactInformation,
  handleContactInfo,
  relationship,
  handleRelationshipInfo,
  insurance,
  handleInsuranceInfo,
  handleCheckInsuranceInfo,
  contactListInfo,
  preferredPharmacyInfo,
  referralInfo,
  handleReferralInfo,
  handleReferralInfoSelect,
  handlePreferredPharmacyInfo,
  handleContactListInfo,
  handleContactListInfoSelect,
}: ProfileTabProps) => {
  const [value, setValue] = useState(0);
  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <div className="flex grow h-full">
      <Tabs
        orientation={'vertical'}
        allowScrollButtonsMobile
        variant="scrollable"
        value={value}
        onChange={handleChangeTab}
        aria-label="Vertical tabs"
        className="shrink-0"
        sx={{
          borderRight: 1,
          borderColor: 'divider',
          overflow: 'auto',
          [`& .${tabsClasses.scrollButtons}`]: {
            '&.Mui-disabled': { opacity: 0.3 },
          },
        }}
      >
        <StyledTab label="Personal Information" />
        <StyledTab label="Contact Information" />
        <StyledTab label="Relationship" />
        <StyledTab label="Insurance" />
        <StyledTab label="Contact List" />
      </Tabs>
      <LinkTab value={value} index={0}>
        <PersonalInformation
          personalInfo={personalInformation}
          handleInfo={handlePersonalInfo}
          handleBirthday={handleBirthday}
          handlePersonalInfoSelect={handlePersonalInfoSelect}
        />
      </LinkTab>
      <LinkTab value={value} index={1}>
        <ContactInformation
          contactInfo={contactInformation}
          handleInfo={handleContactInfo}
        />
      </LinkTab>
      <LinkTab value={value} index={2}>
        <Relationship
          relationship={relationship}
          handleInfo={handleRelationshipInfo}
        />
      </LinkTab>
      <LinkTab value={value} index={3}>
        <Insurance
          insurance={insurance}
          handleInfo={handleInsuranceInfo}
          handleCheck={handleCheckInsuranceInfo}
        />
      </LinkTab>
      <LinkTab value={value} index={4}>
        <ContactList
          contactList={contactListInfo}
          preferredPharmacyInfo={preferredPharmacyInfo}
          referralInfo={referralInfo}
          handleReferralInfo={handleReferralInfo}
          handleReferralInfoSelect={handleReferralInfoSelect}
          handlePreferredPharmacyInfo={handlePreferredPharmacyInfo}
          handleInfo={handleContactListInfo}
          handleInfoSelect={handleContactListInfoSelect}
        />
      </LinkTab>
    </div>
  );
};

export default ProfileTab;
