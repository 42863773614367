import { Box, TextField, TextFieldProps, styled } from '@mui/material';
import { colors } from '../theme';
import { useMask } from '@react-input/mask';
import { customTwMerge as twMerge } from '../utils';

type CustomTextFieldProps = TextFieldProps & {
  width?: string;
  height?: string;
  classes?: string;
  containerClasses?: string;
  endAdornment?: React.ReactNode;
};

const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: colors.primary,
    fontWeight: 600,
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: colors.grayLight,
    borderRadius: 10,
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '10px',
    backgroundColor: colors.white,
    overflow: 'hidden',
    padding: '10px 15px',
    '& fieldset': {
      borderColor: colors.grayLight,
    },
    '&:hover fieldset': {
      borderColor: colors.grayLight,
    },
    '&.Mui-focused fieldset': {
      borderColor: colors.primary,
    },
    '&.Mui-disabled fieldset': {
      pointerEvents: 'none !important',
    },
  },
  '& .MuiFilledInput-root': {
    color: colors.gray,
    overflow: 'hidden',
    borderRadius: '10px',
    backgroundColor: colors.white,
    border: '1px solid',
    borderColor: colors.grayLight,
    '&:hover': {
      backgroundColor: colors.white,
      borderColor: `${colors.aster} !important`,
    },
    '&.Mui-focused': {
      backgroundColor: colors.white,
      boxShadow: colors.grayLight,
    },
    '&.Mui-error': {
      borderColor: colors.red,
    },
  },
});

const BasicTextfield = (props: CustomTextFieldProps) => {
  const {
    id,
    variant,
    label,
    placeholder,
    value,
    onChange,
    onBlur,
    onFocus,
    onKeyDown,
    multiline,
    classes,
    error,
    rows,
    minRows,
    maxRows,
    width,
    name,
    required,
    disabled,
    helperText,
    type,
    inputRef,
    containerClasses,
    endAdornment,
    inputProps,
  } = props;

  const handleEnterKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === 'Enter') {
      if (onKeyDown) {
        onKeyDown(event);
        event.preventDefault();
        event.stopPropagation();
      }
    }
  };

  return (
    <Box
      component="div"
      sx={{
        maxWidth: '100%',
      }}
      width={width}
      className={twMerge('w-full', containerClasses)}
    >
      <div className="w-full">
        <CssTextField
          inputRef={inputRef}
          fullWidth
          inputProps={{
            ...inputProps,
            disableUnderline: true,
            endAdornment: endAdornment ?? null,
          }}
          id={id}
          name={name}
          label={label}
          value={value}
          variant={variant}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          onKeyDown={handleEnterKeyPress}
          multiline={multiline}
          rows={rows}
          minRows={minRows}
          maxRows={maxRows}
          className={`full-rounded border ${classes}`}
          error={error}
          disabled={disabled}
          helperText={helperText}
          required={required}
          type={type}
          placeholder={placeholder}
        />
      </div>
    </Box>
  );
};

export const MaskedBasicTextfield = ({
  mask,
  replacement,
  ...props
}: CustomTextFieldProps & {
  mask?: string;
  replacement?: Record<string, any>;
}) => {
  const inputRef = useMask({ mask, replacement });
  return <BasicTextfield {...props} inputRef={inputRef} />;
};

export default BasicTextfield;
