import React, { useState } from 'react';
import AsterLogo from '../assets/aster';
import {
  FormControl,
  InputLabel,
  InputAdornment,
  IconButton,
  FilledInput,
  styled,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import BasicTextfield from '../components/Textfield';
import { colors } from '../theme';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthProvider';
import ButtonType from '../components/Button';

const CssInput = styled(FilledInput)({
  '&.MuiFilledInput-root': {
    color: colors.gray,
    overflow: 'hidden',
    borderRadius: '10px',
    backgroundColor: colors.white,
    border: '1px solid',
    width: '100%',
    borderColor: colors.grayLight,
    '&:hover': {
      backgroundColor: colors.white,
    },
    '&.Mui-focused': {
      backgroundColor: colors.white,
      boxShadow: colors.grayLight,
    },
    '&.Mui-error': {
      borderColor: colors.red,
    },
    '&.MuiFormLabel-root': {
      color: colors.primary,
      fontWeight: 600,
    },
  },
});

const CssLabel = styled(InputLabel)({
  '&.MuiInputLabel-root': {
    '&.Mui-focused': {
      fontWeight: 600,
      color: colors.primary,
    },
    '&.Mui-error': {
      color: colors.red,
    },
  },
});

const SignIn = () => {
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [state, setState] = useState({
    username: '',
    password: '',
  });

  const handleFormInput = (event: any) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const { login } = useAuth();

  const handleSignIn = async (event: any) => {
    setLoading(true);
    event.preventDefault();
    const { username, password } = state;
    await login({ username, password }).catch((err) => {
      setError(err.message);
    });
    setLoading(false);
  };

  return (
    <div className="flex flex-col items-center h-screen w-screen pt-8 md:pt-[5%] bg-grayBackground">
      <div className="align-middle mb-10">
        <AsterLogo />
      </div>
      <div className="flex flex-col items-center lg:w-1/3 sm:w-full  min-h-min rounded-[20px] bg-white p-11 max-w-[480px]">
        <div className="text-h4 text-primary font-semibold">Welcome back!</div>
        <div className="text-body text-gray mb-9">
          Let's log into your account
        </div>

        <BasicTextfield
          id="email"
          variant="filled"
          label="Work email"
          name="username"
          value={state.username}
          onChange={handleFormInput}
          error={!!error}
          width="100%"
          classes="mb-5"
        />

        <FormControl variant="filled" fullWidth>
          <CssLabel htmlFor="filled-adornment-password" error={!!error}>
            Password
          </CssLabel>
          <CssInput
            id="filled-adornment-password"
            type={showPassword ? 'text' : 'password'}
            name="password"
            disableUnderline={true}
            value={state.password}
            onChange={handleFormInput}
            error={!!error}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        {error && (
          <div className="grid grid-cols-6 w-full ml-10">
            <p className="text-red !content-start col-span-5"> {error}</p>
          </div>
        )}

        <div className="mt-3 mb-5">
          <p
            className="text-secondary cursor-pointer hover:opacity-80"
            onClick={() => navigate('/forgotpassword')}
          >
            Forgot your password?
          </p>
        </div>
        <ButtonType
          classes="px-12"
          variant="contained"
          text="Sign in"
          onClick={handleSignIn}
          loading={loading}
        />
      </div>
      <div className="flex gap-2 text-center text-gray sm:text-left bg-grayBackground my-4 sm:my-6">
        <span>Don’t have an account yet?</span>
        <span
          className="text-secondary cursor-pointer hover:opacity-80"
          onClick={() => navigate('/signup')}
        >
          Sign up and get started!
        </span>
      </div>
    </div>
  );
};

export default SignIn;
