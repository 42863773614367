import { CreateOrderDTO, LabsOrderDTO } from '@aster/shared/dtos/labs';
import axios from '../../../app/axiosConfig';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { logAnalyticEvent } from '../../../app/firebase';
import { useSnackbar } from '../../../components/Snack';

const createLabsOrderRequest = async (createOrder: CreateOrderDTO) =>
  axios.post<LabsOrderDTO>('/v2/labs/orders', createOrder);

export const useCreateLabsOrderMutation = (
  onSuccessCb?: (newOrderID: string) => void
) => {
  const { showMessage } = useSnackbar();
  const queryClient = useQueryClient();

  const createLabsOrderMutation = useMutation({
    mutationKey: ['createLabsOrder'],
    mutationFn: createLabsOrderRequest,
    onSuccess: async (data) => {
      logAnalyticEvent('labs', 'order_created');
      showMessage({
        message: 'Lab Order created successfully',
        type: 'success',
      });

      await queryClient.invalidateQueries({
        queryKey: ['patientLabOrders', data.data.patientID],
      });

      onSuccessCb && onSuccessCb(data.data.id);
    },
    onError: (error) => {
      showMessage({
        message:
          'An error occurred while creating lab order. Please try again.',
        type: 'error',
      });
    },
  });

  return { createLabsOrderMutation };
};
