import { MedicationDetails } from '@aster/shared/dtos/patient';
import BasicTextfield from '../../../components/Textfield';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateField } from '@mui/x-date-pickers/DateField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

export type PatientMedicationsProps = {
  medicationList: MedicationDetails[];
  setMedications: (medicationList: MedicationDetails[]) => void;
};

const PatientMedications = ({
  medicationList,
  setMedications,
}: PatientMedicationsProps) => {
  const handleMedication = (
    index: number,
    newMedicationValue: MedicationDetails
  ) => {
    const updatedMedications = medicationList.map((medication, i) =>
      i === index ? { ...medication, ...newMedicationValue } : medication
    );
    setMedications(updatedMedications);
  };

  const deleteMedication = (index: number) => {
    const updatedMedications = medicationList.filter((_, i) => i !== index);
    setMedications(updatedMedications);
  };

  const addMedication = () => {
    const newMedication: MedicationDetails = {
      name: '',
      frequency: '',
      dosage: '',
      method: '',
      purpose: '',
      startDate: '',
      endDate: '',
    };
    setMedications([...medicationList, newMedication]);
  };

  return (
    <div>
      {medicationList.map((medication, index) => (
        <div
          key={index}
          className="border border-grayLight md:border-0 w-fit p-2 grid-flow-row grid grid-cols-[max-content_max-content] gap-5 gap-y-4 md:grid-cols-[minmax(0,_1fr)_minmax(0,_1fr)_max-content] mt-2"
        >
          <BasicTextfield
            id="name"
            variant="filled"
            label="Name"
            name="name"
            value={medication.name}
            onChange={(e) =>
              handleMedication(index, {
                ...medication,
                name: e?.currentTarget?.value,
              })
            }
            containerClasses="col-start-1 w-[300px]"
          />

          <BasicTextfield
            id="frequency"
            variant="filled"
            label="Frequency"
            name="frequency"
            value={medication.frequency}
            onChange={(e) =>
              handleMedication(index, {
                ...medication,
                frequency: e?.currentTarget?.value,
              })
            }
            containerClasses="col-start-1 md:col-start-2 w-[300px]"
          />

          <BasicTextfield
            id="dosage"
            variant="filled"
            label="Dosage"
            name="dosage"
            value={medication.dosage}
            onChange={(e) =>
              handleMedication(index, {
                ...medication,
                dosage: e?.currentTarget?.value,
              })
            }
            containerClasses="col-start-1 md:col-start-1 w-[300px]"
          />

          <BasicTextfield
            id="purpose"
            variant="filled"
            label="Purpose"
            name="purpose"
            value={medication.purpose}
            onChange={(e) =>
              handleMedication(index, {
                ...medication,
                purpose: e?.currentTarget?.value,
              })
            }
            containerClasses="col-start-1 md:col-start-2 w-[300px]"
          />

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateField
              className="col-start-1 md:col-start-1"
              label="Start Date"
              value={
                medication.startDate
                  ? (dayjs(medication.startDate) as any)
                  : null
              }
              onChange={(value: string | null) =>
                handleMedication(index, {
                  ...medication,
                  startDate: value ?? '',
                })
              }
              sx={{
                borderRadius: 1,
              }}
            />
          </LocalizationProvider>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateField
              label="End Date"
              value={
                medication.endDate ? (dayjs(medication.endDate) as any) : null
              }
              onChange={(value: string | null) =>
                handleMedication(index, {
                  ...medication,
                  endDate: value ?? '',
                })
              }
              className="col-start-1 md:col-start-2"
              sx={{
                borderRadius: 1,
              }}
            />
          </LocalizationProvider>

          <IconButton
            className="text-red row-start-1 col-start-2 md:col-start-3"
            disableRipple={true}
            onClick={() => deleteMedication(index)}
            aria-label="delete"
          >
            <DeleteIcon />
          </IconButton>
        </div>
      ))}
      <Button
        className="text-green font-semibold"
        onClick={addMedication}
        variant="text"
        startIcon={<AddIcon />}
      >
        Add Medication
      </Button>
    </div>
  );
};

export default PatientMedications;
