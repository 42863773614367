import React, { useState } from 'react';
import { confirmSignUp, resendSignUpCode } from 'aws-amplify/auth';
import { Snackbar } from '@mui/material';
import AsterLogo from '../assets/aster';
import { colors } from '../theme';
import { useNavigate } from 'react-router-dom';

const Verify = () => {
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const username = localStorage.getItem('user') || '';
  const [state, setState] = useState({
    code: '',
  });

  const handleFormInput = (event: any) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };

  const handleVerification = (event: any) => {
    setLoading(true);
    event.preventDefault();
    const { code } = state;
    confirmSignUp({ username, confirmationCode: code })
      .then(() => {
        navigate('/signin');
        localStorage.clear();
        setLoading(false);
      })
      .catch((err) => {
        setError(err.message);
        setLoading(false);
      });
  };

  const resendCode = (event: any) => {
    event.preventDefault();
    if (username) {
      resendSignUpCode({ username });
      setOpenSnackbar(true);
    }
  };

  return (
    <div className="flex flex-col items-center h-screen w-screen pt-8 md:pt-32 bg-grayBackground">
      <div className="align-middle mb-10">
        <AsterLogo />
      </div>
      <div className="flex flex-col items-center  lg:w-1/3 sm:w-full  min-h-min rounded-[20px] bg-white px-11 max-w-[480px]">
        <div className="text-h4 mt-6 text-primary font-semibold">
          Verify your email
        </div>
        <div className="text-body text-gray">
          Enter the code sent to your email address
        </div>
        <input
          type="text"
          name="code"
          value={state.code}
          placeholder="Verification Code"
          onChange={handleFormInput}
          className={`form-input w-full px-4 py-4 mx-4 mt-6 rounded-[10px] border text-gray ${
            !error ? `border-grayLight` : `border-red`
          }`}
        />
        {error && (
          <div className="grid grid-cols-6 w-full">
            <p className="text-red !content-start col-span-5">{error}</p>
          </div>
        )}
        <button
          className="bg-primary mt-6 px-10 py-3 rounded-full text-white mb-10"
          disabled={loading}
          onClick={handleVerification}
        >
          {loading && (
            <i className="fa fa-spinner fa-spin text-white px-10"></i>
          )}
          {!loading && <p>Confirm</p>}
        </button>
      </div>
      <div className="text-center sm:text-left bg-grayBackground my-4 sm:my-6">
        <span className="text-secondary cursor-pointer" onClick={resendCode}>
          Resend code
        </span>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        autoHideDuration={3000}
        message="Code has been resent!"
        sx={{
          backgroundColor: colors.gray,
          color: colors.white,
        }}
      />
    </div>
  );
};

export default Verify;
