import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { EventImpl } from '@fullcalendar/core/internal';
import { useVirtualizer } from '@tanstack/react-virtual';
import dayjs from 'dayjs';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { colors } from '../../../theme';
import styled from '@mui/system/styled';
import ListItemButton from '@mui/material/ListItemButton';
import Typography from '../../../components/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import CalendarDay from '../../../assets/icons/CalendarDay';
import Clock from '../../../assets/icons/Clock';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import { NewEvent } from '../types';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion elevation={0} square {...props} />
))(({ theme }) => ({
  width: '100%',
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ExpandMoreIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(() => ({
  backgroundColor: colors.white,
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  color: colors.gray,
  overflow: 'hidden',
  borderRadius: '10px',
  backgroundColor: colors.white,
  border: '1px solid',
  borderColor: colors.grayLight,
}));

const StyledButtonList = styled(ListItemButton)({
  width: '100px !important',
  '&.Mui-selected': {
    backgroundColor: colors.gray,
    color: colors.white,
    fontWeight: '700 !important',
    '&:hover': {
      color: colors.gray,
    },
  },
  paddingLeft: 11,
  '& .MuiButtonBase-root': {
    width: '100px !important',
    marginTop: 1,
  },
});

const CustomDay = (
  props: PickersDayProps<dayjs.Dayjs> & {
    startDate: dayjs.Dayjs;
    endDate: dayjs.Dayjs;
  }
) => {
  const { startDate, endDate, ...defaultProps } = props;

  const selected = [
    startDate.format('DD-MM-YYYY'),
    endDate.format('DD-MM-YYYY'),
  ].includes(props.day.format('DD-MM-YYYY'));
  return <PickersDay {...defaultProps} selected={selected} />;
};

type CalendarDatePickerProps = {
  startDate: dayjs.Dayjs;
  onStartDateChange: (date: dayjs.Dayjs) => void;
  endDate: dayjs.Dayjs;
  onEndDateChange: (date: dayjs.Dayjs) => void;
  eventInfo: EventImpl | NewEvent;
  isEdit?: boolean;
};

const CalendarDatePicker = React.memo(
  ({
    startDate,
    onStartDateChange,
    endDate,
    onEndDateChange,
    eventInfo,
    isEdit,
  }: CalendarDatePickerProps) => {
    const endParentRef = React.useRef<HTMLUListElement | null>(null);
    const startParentRef = React.useRef<HTMLUListElement | null>(null);
    const [selectedEndIndex, setSelectedEndIndex] = useState(0);
    const [startTimeIntervalHours, setStartTimeIntervalHours] = useState<
      dayjs.Dayjs[]
    >([]);
    const [selectedStartIndex, setSelectedStartIndex] = useState(0);
    const [endTimeIntervalHours, setEndTimeIntervalHours] = useState<
      dayjs.Dayjs[]
    >([]);
    const startRowVirtualizer = useVirtualizer({
      count: startTimeIntervalHours.length - 1,
      getScrollElement: () => startParentRef.current,
      estimateSize: () => 48,
      overscan: 5,
    });
    const endRowVirtualizer = useVirtualizer({
      count: endTimeIntervalHours.length,
      getScrollElement: () => endParentRef.current,
      estimateSize: () => 48,
      overscan: 5,
    });
    const updateStartTimeIntervalHours = useCallback(() => {
      if (!eventInfo) return;

      const eventStart = dayjs(eventInfo.start);
      const eventEnd = dayjs(eventInfo.end);
      onStartDateChange(eventStart);
      onEndDateChange(eventEnd);
      const startHours: dayjs.Dayjs[] = [];

      let currInterval = eventStart.startOf('day').add(5, 'minute');
      const lastHourOfDay = currInterval.endOf('day');

      while (currInterval.isBefore(lastHourOfDay)) {
        startHours.push(currInterval);
        currInterval = currInterval.add(5, 'minute');
      }

      const startTimeIndex =
        (eventStart.hour() * 60 + eventStart.minute()) / 5 - 1;
      const endTimeIndex =
        ((eventEnd.hour() - eventStart.hour()) * 60 +
          (eventEnd.minute() - eventStart.minute())) /
        5;
      const endHours = startHours.slice(1);

      setStartTimeIntervalHours(startHours);
      setEndTimeIntervalHours(endHours);
      setSelectedStartIndex(startTimeIndex);
      setSelectedEndIndex(endTimeIndex);
    }, [eventInfo, onEndDateChange, onStartDateChange]);
    useEffect(() => {
      updateStartTimeIntervalHours();
    }, [eventInfo, updateStartTimeIntervalHours]);

    useEffect(() => {
      startRowVirtualizer.scrollToIndex(selectedStartIndex - 1);
      endRowVirtualizer.scrollToIndex(selectedEndIndex - 1);
    }, [
      selectedStartIndex,
      selectedEndIndex,
      startRowVirtualizer,
      endRowVirtualizer,
    ]);

    useEffect(() => {
      if (startDate && endDate) {
        const newEndHours = startTimeIntervalHours.slice(
          selectedStartIndex + 1
        );
        if (!startDate.isBefore(endDate)) {
          setEndTimeIntervalHours(newEndHours);
          onEndDateChange(newEndHours[0]);
          setSelectedEndIndex(0);
        } else {
          setEndTimeIntervalHours(newEndHours);
          const endTimeIndex = newEndHours.findIndex(
            (hour) =>
              hour.hour() === endDate.hour() &&
              hour.minute() === endDate.minute()
          );
          setSelectedEndIndex(endTimeIndex);
        }
      }
    }, [
      endDate,
      onEndDateChange,
      selectedStartIndex,
      startDate,
      startTimeIntervalHours,
    ]);

    const handleStartTimeSelected = useCallback(
      (index: number) => {
        const newTime = startTimeIntervalHours[index];
        const newHours = newTime.get('hour');
        const newMinutes = newTime.get('minute');
        onStartDateChange(
          startDate.clone().set('hour', newHours).set('minute', newMinutes)
        );
        setSelectedStartIndex(index);
      },
      [onStartDateChange, startDate, startTimeIntervalHours]
    );

    const handleEndTimeSelected = useCallback(
      (index: number) => {
        const newTime = endTimeIntervalHours[index];
        const newHours = newTime.get('hour');
        const newMinutes = newTime.get('minute');
        onEndDateChange(
          endDate.clone().set('hour', newHours).set('minute', newMinutes)
        );
        setSelectedEndIndex(index);
      },
      [endDate, endTimeIntervalHours, onEndDateChange]
    );

    const dateDisplay = `${startDate.format('MM/DD/YYYY')}${
      startDate.day() !== endDate.day()
        ? ' - ' + endDate.format('MM/DD/YYYY')
        : ''
    }`;

    const displayTime = useMemo(() => {
      const displayTimeString = `${startDate?.format('hh:mm a')} - ${dayjs(
        endDate
      ).format('hh:mm a')}`;
      return displayTimeString;
    }, [startDate, endDate]);

    return (
      <Accordion disableGutters defaultExpanded={isEdit}>
        {!isEdit && (
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div className="grid md:grid-cols-2 w-full h-[50px]">
              <Grid container className="items-center">
                <Grid item xs={2}>
                  <CalendarDay />
                </Grid>
                <Grid item xs={10} className="items-center">
                  <Typography
                    variant={'bodySmall'}
                    text={dateDisplay}
                    customClass="text-black font-semibold"
                  />
                </Grid>
              </Grid>
              <Grid container className="items-center">
                <Grid item xs={2}>
                  <Clock />
                </Grid>
                <Grid item xs={10}>
                  <Typography
                    variant={'bodySmall'}
                    text={displayTime}
                    customClass="text-black font-semibold"
                  />
                </Grid>
              </Grid>
            </div>
          </AccordionSummary>
        )}
        <AccordionDetails className="mb-6">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className="grid grid-cols-fill-first gap-2">
              <DateCalendar
                slots={{
                  day: (props) => (
                    <CustomDay
                      {...props}
                      startDate={startDate}
                      endDate={endDate}
                    />
                  ),
                }}
                disabled={startDate.day() !== endDate.day()}
                value={startDate}
                onChange={(newValue) => {
                  const newStart = newValue
                    .set('hour', startDate.hour())
                    .set('minute', startDate.minute());
                  const newEnd = newValue
                    .set('hour', endDate.hour())
                    .set('minute', endDate.minute());
                  onStartDateChange(newStart);
                  onEndDateChange(newEnd);
                }}
                sx={{ width: '100%' }}
              />
              <div className="grid grid-cols-2">
                <div>
                  <Typography
                    variant="bodySmall"
                    customClass="text-black font-semibold my-5 ml-5"
                    text="Start"
                  />
                  <List
                    sx={{
                      width: '100px',
                      bgcolor: 'background.paper',
                      position: 'relative',
                      overflow: 'auto',
                      maxHeight: 250,
                      '& ul': { paddingLeft: 2 },
                      '[&::-webkit-scrollbar]': 'hidden',
                      '-ms-overflow-style': 'none',
                      'scrollbar-width': 'none',
                    }}
                    ref={startParentRef}
                    subheader={<li />}
                  >
                    <div
                      style={{
                        height: `${startRowVirtualizer.getTotalSize()}px`,
                        width: '100%',
                        position: 'relative',
                      }}
                    >
                      {startRowVirtualizer.getVirtualItems().map((item) => (
                        <StyledButtonList
                          sx={{
                            width: 100,
                            height: item.size,
                            position: 'absolute',
                            transform: `translateY(${item.start}px)`,
                          }}
                          key={item.key}
                          selected={selectedStartIndex === item.index}
                          className="rounded-full hover:opacity-80 cursor-pointer"
                          onClick={() => {
                            handleStartTimeSelected(item.index);
                          }}
                        >
                          <ListItemText
                            primary={`${dayjs(
                              startTimeIntervalHours[item.index]
                            ).format('hh:mm a')}`}
                          />
                        </StyledButtonList>
                      ))}
                    </div>
                  </List>
                </div>
                <div>
                  <Typography
                    variant="bodySmall"
                    customClass="text-black font-semibold my-5 ml-5"
                    text="End"
                  />
                  <List
                    sx={{
                      width: '100px',
                      bgcolor: 'background.paper',
                      position: 'relative',
                      overflow: 'auto',
                      maxHeight: 250,
                      '& ul': { paddingLeft: 2 },
                      '[&::-webkit-scrollbar]': 'hidden',
                      '-ms-overflow-style': 'none',
                      'scrollbar-width': 'none',
                    }}
                    ref={endParentRef}
                    subheader={<li />}
                  >
                    <div
                      style={{
                        height: `${endRowVirtualizer.getTotalSize()}px`,
                        width: '100%',
                        position: 'relative',
                      }}
                    >
                      {endRowVirtualizer.getVirtualItems().map((item) => (
                        <StyledButtonList
                          sx={{
                            width: 100,
                            height: item.size,
                            position: 'absolute',
                            transform: `translateY(${item.start}px)`,
                          }}
                          key={item.key}
                          selected={selectedEndIndex === item.index}
                          className="rounded-full hover:opacity-80 cursor-pointer"
                          onClick={() => {
                            handleEndTimeSelected(item.index);
                          }}
                        >
                          <ListItemText
                            primary={`${dayjs(
                              endTimeIntervalHours[item.index]
                            ).format('hh:mm a')}`}
                          />
                        </StyledButtonList>
                      ))}
                    </div>
                  </List>
                </div>
              </div>
            </div>
          </LocalizationProvider>
        </AccordionDetails>
      </Accordion>
    );
  }
);
export default CalendarDatePicker;
