import axios from '../../../app/axiosConfig';
import { PatientProfileForEncounterDTO } from '@aster/shared/dtos/patient';
import { useQuery } from '@tanstack/react-query';

export function usePatientProfile(patientID: string | undefined) {
  const fetchPatientProfile = async () => {
    const response = await axios.get<PatientProfileForEncounterDTO>(
      `patients/${patientID}/profile`
    );
    return response.data;
  };

  const { data: patientProfile, isLoading: isProfileLoading } = useQuery({
    queryKey: ['patientProfile', patientID],
    queryFn: fetchPatientProfile,
    enabled: patientID !== undefined,
  });

  return { patientProfile, isProfileLoading };
}
