import { GridCellParams, GridColDef } from '@mui/x-data-grid/models';
import StyledDataGrid from '../../components/StyledDataGrid';
import { useLabs } from './LabsContext';
import { CreateLabsOrderDialog } from './create-order/CreateLabsOrder';
import { LabOrderDetailsDialog } from './order-details/LabOrderDetails';
import { useMemo } from 'react';
import { TryVitalResultEntry } from '@aster/shared/dtos/labs';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { names } from '@aster/shared/utils/names';
import { customTwMerge } from '../../utils';
import { resultSeverityClassColor } from '../notes/sections/utils';
import dayjs from 'dayjs';
import CircularProgress from '@mui/material/CircularProgress';
import LabIllustration from '../../assets/icons/LabsIllustration';
import ButtonType from '../../components/Button';
import { CreateLabsOrderProvider } from './create-order/LabsOrderContext';
import Chip from '../../components/Chip';

export default function () {
  const {
    selectedLabOrderID,
    labOrders,
    isLoadingOrders,
    closeLabOrderDetails,
    openLabsOrderCreationWizard,
    openLabOrderDetails,
    closeLabsOrderCreationWizard,
    isLabsOrderCreationWizardOpen,
  } = useLabs();

  const rows = useMemo(() => {
    if (!labOrders) return [];

    return labOrders.map((r) => {
      const outOfRangeResult = (
        r.results?.results as TryVitalResultEntry[] | null
      )?.find(
        (resultEntry) =>
          resultEntry.is_above_max_range ||
          resultEntry.is_above_max_range ||
          resultEntry.interpretation === 'abnormal' ||
          resultEntry.interpretation === 'critical'
      );

      return {
        id: r.id,
        orderedBy: r.orderedBy,
        status: names(r.status).sentenceCase,
        abnormalResult: outOfRangeResult,
        labTestName: r.labTest.name,
        hasLabResults: !!r.results,
        method: names(r.labTest.method).sentenceCase,
        createdAt: dayjs(r.createdAt).format('MMM DD, YYYY'),
      };
    });
  }, [labOrders]);

  const columns: GridColDef[] = [
    {
      field: 'abnormalResult',
      headerClassName: 'bg-grayBackground',
      renderHeader: () => (
        <div className="flex justify-center items-center rounded-full bg-asterGray opacity-75 h-5 w-5">
          <PriorityHighIcon className="text-white text-bodySmall" />
        </div>
      ),
      width: 70,
      renderCell: (params: GridCellParams) => {
        const abnormalResultEntry = params.row.abnormalResult as
          | TryVitalResultEntry
          | undefined;
        return (
          abnormalResultEntry && (
            <div
              className={customTwMerge(
                'rounded-full h-4 w-4 text-white grid place-content-center',
                resultSeverityClassColor(
                  abnormalResultEntry.interpretation ?? '',
                  'bg'
                )
              )}
            >
              <p className="text-bodySmall">
                {abnormalResultEntry.is_above_max_range ? 'H' : 'L'}
              </p>
            </div>
          )
        );
      },
    },
    {
      field: 'labTestName',
      headerName: 'Lab Test',
      headerAlign: 'left',
      flex: 1,
      headerClassName: 'bg-grayBackground',
    },
    {
      field: 'method',
      headerName: 'Test Method',
      headerAlign: 'left',
      flex: 0.7,
      headerClassName: 'bg-grayBackground',
    },
    {
      field: 'status',
      headerName: 'Status',
      headerAlign: 'left',
      flex: 0.7,
      headerClassName: 'bg-grayBackground',
    },
    {
      field: 'orderedBy',
      headerName: 'Ordered By',
      headerAlign: 'left',
      flex: 1,
      headerClassName: 'bg-grayBackground',
    },
    {
      field: 'createdAt',
      headerName: 'Ordered At',
      headerAlign: 'left',
      flex: 0.7,
      headerClassName: 'bg-grayBackground',
    },
  ];

  if (isLoadingOrders) {
    return (
      <div className="flex flex-col justify-center items-center w-full h-full">
        <CircularProgress />
      </div>
    );
  }

  if (rows.length < 1) {
    return (
      <div className="full-w-container flex-col items-center">
        <BannerWarning />
        <LabIllustration className="h-64 w-64" />
        <div className="flex flex-col items-center mb-10">
          <p className="text-h3 text-primary">Your lab order list is empty</p>
          <p className="text-body text-gray ">
            Let's get started by creating your first lab order.
          </p>
        </div>

        <ButtonType
          variant="contained"
          onClick={() => {
            openLabsOrderCreationWizard();
          }}
          text="Create Lab Order"
          classes={'w-[300px]'}
        />
        <CreateLabsOrderProvider closeDialog={closeLabsOrderCreationWizard}>
          <CreateLabsOrderDialog
            fullWidth
            open={isLabsOrderCreationWizardOpen}
            onClose={closeLabsOrderCreationWizard}
            handleClose={closeLabsOrderCreationWizard}
          />
        </CreateLabsOrderProvider>
      </div>
    );
  }

  return (
    <section className="flex flex-col full-w-container overflow-auto">
      <BannerWarning />
      <div className="flex justify-between mb-4 flex-wrap gap-y-2">
        <p className="text-h4 text-black font-semibold m-0">Lab Orders</p>

        <div className="flex gap-4 flex-wrap self-center">
          <ButtonType
            variant="contained"
            onClick={() => {
              openLabsOrderCreationWizard();
            }}
            text="Create Lab Order"
            classes={'w-[300px]'}
          />
        </div>
      </div>
      <div className="w-full h-fit mb-2 mt-4 grid">
        <StyledDataGrid
          rows={rows}
          pageSizeOptions={[5, 10, 25, 50]}
          loading={isLoadingOrders}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          columns={columns}
          autoHeight
          disableColumnFilter
          disableColumnMenu
          isRowSelectable={() => false}
          onRowClick={(clickedRow) => {
            if (clickedRow.row?.id && clickedRow.row?.status === 'Completed') {
              openLabOrderDetails(clickedRow.row.id);
            }
          }}
          getRowClassName={() => 'cursor-pointer'}
        />
      </div>

      <LabOrderDetailsDialog
        fullWidth
        open={Boolean(selectedLabOrderID)}
        onClose={() => closeLabOrderDetails()}
        handleClose={() => closeLabOrderDetails()}
        labOrderID={selectedLabOrderID}
      />
      <CreateLabsOrderProvider closeDialog={closeLabsOrderCreationWizard}>
        <CreateLabsOrderDialog
          fullWidth
          open={isLabsOrderCreationWizardOpen}
          onClose={closeLabsOrderCreationWizard}
          handleClose={closeLabsOrderCreationWizard}
        />
      </CreateLabsOrderProvider>
    </section>
  );
}

const BannerWarning = () => {
  return (
    <div
      role="alert"
      className="py-4 px-6 bg-warning-100 rounded-[0.5rem] flex items-center gap-4 mb-8"
    >
      <Chip
        label="Beta"
        color="warning"
        classes={{
          label: 'text-primary font-[500]',
        }}
      />
      <p>
        <b>Heads up! Labs is in beta right now.</b> We've made a select group of
        panels available for you to use. Please try them out and if you need any
        support,{' '}
        <a className="underline" href="mailto:support@astercare.com">
          email us
        </a>{' '}
        directly.
      </p>
    </div>
  );
};
