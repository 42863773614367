import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent } from '@mui/material';
import { useForm } from '@tanstack/react-form';
import { zodValidator } from '@tanstack/zod-form-adapter';
import BasicTextfield, {
  MaskedBasicTextfield,
} from '../../components/Textfield';
import ButtonType from '../../components/Button';
import { z } from 'zod';
import { useNavigate } from 'react-router-dom';
import axios from '../../app/axiosConfig';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Patient } from './types/patient';
import { logAnalyticEvent } from '../../app/firebase';
import { AxiosError } from 'axios';
import { requiredMessage } from '../../constants/validation-messages';

function useRegisterPatient(
  intakeNavigation = false,
  onSuccessCb: (() => void) | null = null
) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const createPatientMutation = async (newPatient: Patient) => {
    return await axios
      .post('/patients', newPatient)
      .then((res: any) => {
        if (intakeNavigation) {
          navigate(`/patientProfile/${res.data.id}`);
        }
      })
      .catch((err: AxiosError) => {
        if (err.response?.status === 409) {
          throw new Error((err.response.data as any).message);
        } else {
          throw new Error('Error creating patient');
        }
      });
  };

  const registerPatientMutation = useMutation({
    mutationKey: ['createPatient'],
    mutationFn: createPatientMutation,
    onSuccess: async () => {
      logAnalyticEvent('patients', 'create_patient');
      onSuccessCb && onSuccessCb();
      return await queryClient.invalidateQueries({ queryKey: ['patients'] });
    },
  });

  return { registerPatientMutation };
}

export type RegisterPatientProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  setSnackBarMessage: (message: string) => void;
};

function RegisterPatient({
  open,
  setOpen,
  setSnackBarMessage,
}: RegisterPatientProps) {
  const [intakeNavigation, setIntakeNavigaton] = useState(false);

  const form = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      shouldSendIntakeFormLink: false,
    },
    validatorAdapter: zodValidator,
    onSubmit: async ({ value }) => {
      await registerPatientMutation.mutateAsync(value);
    },
  });

  const { registerPatientMutation } = useRegisterPatient(
    intakeNavigation,
    () => {
      setOpen(false);
      if (form.state.values.shouldSendIntakeFormLink) {
        setSnackBarMessage(
          `Intake form link sent to ${form.state.values.email}`
        );
      }
      form.reset();
    }
  );

  return (
    <Dialog
      open={open}
      keepMounted
      fullWidth
      maxWidth={'xs'}
      onClose={() => {
        setOpen(false);
      }}
      aria-describedby="alert-dialog-slide-description"
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          void form.handleSubmit();
        }}
      >
        <div className="flex flex-col items-center">
          <div className="flex flex-col items-center text-h4 font-semibold mt-5">
            Register a new patient
          </div>

          <DialogContent className="bg-white w-full">
            <div className=" flex flex-col items-center">
              <div className="grid grid-cols-2 gap-2 w-full mb-5">
                <form.Field
                  name="firstName"
                  validators={{ onBlur: z.string().min(1) }}
                  children={(field) => (
                    <BasicTextfield
                      id={field.name}
                      variant="filled"
                      label="First Name"
                      name={field.name}
                      value={field.state.value}
                      onBlur={field.handleBlur}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        field.handleChange(event.target.value)
                      }
                      error={field.state.meta.errors?.length > 0}
                      helperText={field.state.meta.errors?.join('\r')}
                      width="100%"
                    />
                  )}
                ></form.Field>
                <form.Field
                  name="lastName"
                  validators={{
                    onBlur: z
                      .string()
                      .min(1, { message: requiredMessage('Last Name') }),
                  }}
                  children={(field) => (
                    <BasicTextfield
                      id={field.name}
                      variant="filled"
                      label="Last Name"
                      name={field.name}
                      value={field.state.value}
                      onBlur={field.handleBlur}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        field.handleChange(event.target.value)
                      }
                      error={field.state.meta.errors?.length > 0}
                      helperText={field.state.meta.errors?.join('\r')}
                      width="100%"
                    />
                  )}
                ></form.Field>
              </div>
              <form.Field
                name="email"
                validators={{
                  onBlur: z.string().email(),
                }}
                children={(field) => (
                  <BasicTextfield
                    id={field.name}
                    variant="filled"
                    label="Email"
                    name={field.name}
                    value={field.state.value}
                    onBlur={field.handleBlur}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      field.handleChange(event.target.value)
                    }
                    width="100%"
                    classes="mb-5"
                    error={field.state.meta.errors?.length > 0}
                    helperText={field.state.meta.errors?.join('\r')}
                  />
                )}
              ></form.Field>

              <form.Field
                name="phoneNumber"
                children={(field) => (
                  <MaskedBasicTextfield
                    id={field.name}
                    mask="+1 (___) ___-__-__"
                    replacement={{ _: /\d/ }}
                    variant="filled"
                    label="Phone"
                    name={field.name}
                    value={field.state.value}
                    onBlur={field.handleBlur}
                    placeholder="+1(415) 555-5555"
                    error={field.state.meta.errors?.length > 0}
                    helperText={field.state.meta.errors?.join('\r')}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      field.handleChange(event.target.value)
                    }
                    width="100%"
                    classes={`mt-6`}
                  />
                )}
              ></form.Field>
              {registerPatientMutation.isError ? (
                <p className="text-red mx-4 mt-4 text-bodySmall">
                  {(registerPatientMutation.error as any).message}
                </p>
              ) : null}
            </div>
          </DialogContent>
        </div>
        <DialogActions className=" bg-white flex flex-col items-center">
          <form.Subscribe
            selector={(state) => [
              state.canSubmit,
              state.isSubmitting,
              state.isFieldsValidating,
            ]}
            children={([canSubmit, isSubmitting, isValidating]) => (
              <div className="w-[250px] sm:w-[400px]">
                <ButtonType
                  variant="contained"
                  onClick={() => {
                    form.state.values.shouldSendIntakeFormLink = true;
                    form.handleSubmit();
                  }}
                  text="Send intake form"
                  disabled={!canSubmit}
                  loading={isSubmitting || isValidating}
                  classes="min-h-[42px]"
                />
                <ButtonType
                  variant="text"
                  onClick={() => {
                    setIntakeNavigaton(true);
                    form.handleSubmit();
                  }}
                  text="Continue to Patient Profile"
                  classes={'mt-2'}
                  disabled={!canSubmit}
                />
              </div>
            )}
          />
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default RegisterPatient;
