import useSearchQueryArgs from '../../../hooks/useSearchQueryArgs';
import { PaginatedResult, searchArgsToString } from '@aster/shared/dtos/search';
import {
  PatientListItemDTO,
  PatientSearchArgsDTO,
  StatusFilter,
} from '@aster/shared/dtos/patient';
import axios from '../../../app/axiosConfig';
import { keepPreviousData, useQuery } from '@tanstack/react-query';

const fetchPatients = async (searchArgs: PatientSearchArgsDTO) => {
  const response = await axios.get<PaginatedResult<PatientListItemDTO>>(
    `patients?${searchArgsToString(searchArgs)}`
  );
  return response.data;
};

export const useGetPatients = (patientStatus?: StatusFilter) => {
  const { paginationModel, searchQuery, search, sortModel } =
    useSearchQueryArgs();

  const {
    data: patientList,
    isLoading: arePatientsLoading,
    isFetching: arePatientsFetching,
  } = useQuery({
    queryKey: [
      'patients',
      searchQuery,
      paginationModel.page,
      paginationModel.pageSize,
      sortModel.sortBy,
      sortModel.sortDir,
      patientStatus,
    ],
    queryFn: () =>
      fetchPatients({
        search: searchQuery,
        statusFilter: patientStatus,
        ...paginationModel,
        ...sortModel,
      }),
    placeholderData: keepPreviousData,
  });

  return { patientList, arePatientsLoading, arePatientsFetching, search };
};
