import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { useParams } from 'react-router-dom';
import NoteHeader from './sections/NoteHeader';
import ConfirmationModal from '../../components/ConfirmationModal';
import { useLeavingPageProtection } from '../../hooks/usePageLeavingProtection';
import TextArea from '../../components/TextArea';
import type { Codes } from '../notes/types/encounter';
import PatientProfileForEncounter from './sections/PatientProfileForEncounter';

import TemplateComponent from './templates/TemplateComponent';
import { useAuth } from '../../authentication/AuthProvider';
import { useUpdateEncounterMutation } from './mutations/update-encounter.mutation';
import { useEncounterDetailQuery } from './queries/encounter-detail.query';

const BasicNote = () => {
  const { patient, encounterId } = useParams();
  const [signed, setSigned] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [enforceSaveMissing, setEnforceSaveMissing] = useState(false);
  const [noteInfo, setNoteInfo] = useState({
    notes: '',
    additional: '',
  });

  const [selectedCodes, setSelectedCodes] = useState<Codes>({
    cptCodes: [],
    icdCodes: [],
  });

  const { profile } = useAuth();

  const { encounterData, isEncounterLoading } = useEncounterDetailQuery(
    encounterId as string
  );

  const handleChangeCodes = (codes: Codes) => {
    setSelectedCodes(codes);
  };

  useEffect(() => {
    if (encounterData) {
      if (encounterData.content) {
        const { notes, additional } =
          Object.keys(encounterData.content).length !== 0
            ? encounterData.content
            : { notes: '', additional: '' };
        setNoteInfo({
          notes: notes,
          additional: additional,
        });
      }
      const { cptCodes, icdCodes } = encounterData as Codes;
      setSelectedCodes({ cptCodes, icdCodes });
      setSigned(encounterData.signedBy !== null);
    }
  }, [encounterData, encounterId]);

  const { updateEncounter } = useUpdateEncounterMutation(encounterId, () => {
    setIsDirty(false);
    setEnforceSaveMissing(false);
  });

  const saveInfo = (signed: boolean) => {
    setSigned(signed);
    const info = {
      patientID: patient,
      startTime: encounterData?.startTime,
      endTime: encounterData?.endTime,
      signed,
      content: {
        notes: noteInfo.notes,
        additional: noteInfo.additional,
      },
      templateType: encounterData?.templateType,
      cptCodes: selectedCodes.cptCodes,
      icdCodes: selectedCodes.icdCodes,
    };
    if (signed) {
      setOpenConfirmation(false);
    }
    updateEncounter.mutate(info);
  };

  const { blocker } = useLeavingPageProtection({ canLeave: !isDirty });

  const handleClose = (confirmed: boolean) => {
    if (confirmed) {
      saveInfo(false);
      setEnforceSaveMissing(true);
      blocker?.reset?.();
    } else {
      blocker?.proceed?.();
      setIsDirty(false);
      setEnforceSaveMissing(false);
    }
  };

  const handleUnsaved = (isDirty: boolean) => {
    setIsDirty(isDirty);
  };

  const buttons = [
    { value: 'history', text: 'History' },
    { value: 'labs', text: 'Labs/Ultrasounds' },
    { value: 'imaging', text: 'Imaging' },
  ];

  if (isEncounterLoading) {
    return (
      <div className="flex flex-col justify-center items-center h-full w-full">
        <CircularProgress />
      </div>
    );
  }

  const handleChangeNote = (content: string) => {
    setNoteInfo((prevNote) => ({
      ...prevNote,
      notes: content,
    }));
    setIsDirty(true);
  };

  const handleChangeAdditional = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setNoteInfo((prevNote) => ({
      ...prevNote,
      additional: e.target.value,
    }));
    setIsDirty(true);
  };

  return (
    <div className="flex flex-col lg:flex-row overflow-auto h-full w-full">
      <div className="flex flex-col lg:max-w-[400px]">
        <PatientProfileForEncounter
          isEncounterSigned={signed}
          enforceSaveMissing={enforceSaveMissing}
          handleUnsaved={handleUnsaved}
        />
      </div>
      <div className="flex flex-col lg:w-full mx-10 mt-5 lg:overflow-y-auto">
        <NoteHeader
          encounterInfo={encounterData}
          selectedCodes={selectedCodes}
          setSelectedCodes={handleChangeCodes}
          title="Basic Note"
          disabled={updateEncounter.status === 'pending'}
          buttonMessageLeft="Save draft"
          actionLeft={() => saveInfo(false)}
          buttonMessageRight="Sign note"
          actionRight={() => setOpenConfirmation(true)}
          hide={signed}
        />
        <div className="flex flex-col pb-5">
          <TemplateComponent
            noteInfo={noteInfo.notes}
            signed={signed}
            handleChangeNote={handleChangeNote}
            buttons={buttons}
          />

          <div className="flex flex-col mt-10 w-full">
            <TextArea
              name="additional"
              textValue={noteInfo.additional}
              topLabel="Additional"
              headerClassName="mb-2"
              topLabelVariant="h6"
              placeholder=" "
              handleChange={handleChangeAdditional}
              border="border-grayBackground"
              disabled={signed}
              rows={4}
            />
          </div>
        </div>

        <ConfirmationModal
          open={openConfirmation}
          title="Sign this encounter note?"
          description={`You're signing as ${profile && profile.firstName} ${
            profile && profile.lastName
          }`}
          confirm="Sign"
          dismiss="Cancel"
          handleClose={() => setOpenConfirmation(false)}
          handleConfirm={() => saveInfo(true)}
          handleCancel={() => setOpenConfirmation(false)}
        />
        <ConfirmationModal
          open={blocker.state === 'blocked'}
          title="Unsaved Changes"
          description={`Are you sure you want to leave? Changes may not be saved.`}
          confirm="Save"
          dismiss="Leave"
          handleClose={() => handleClose(true)}
          handleConfirm={() => handleClose(true)}
          handleCancel={() => handleClose(false)}
        />
      </div>
    </div>
  );
};

export default BasicNote;
