import BasicTextfield from '../../../components/Textfield';
import Typography from '../../../components/Typography';
import { AdditionalInfo } from '../types/patient-profile';

type AdditionalInfoProps = {
  additionalInfo: AdditionalInfo;
  handleInfo: (event: any) => void;
};

const AdditionalInformation = (props: AdditionalInfoProps) => {
  const { additionalInfo } = props.additionalInfo;
  const handleInfo = props.handleInfo;

  return (
    <div className="container w-fit pb-10">
      <div className="grid grid-cols-1 overflow-x-auto">
        <Typography
          text="Additional Information"
          variant="h5"
          customClass="font-semibold mb-2"
        ></Typography>
        <Typography
          text="Anything else you would like us to know about your pregnancy or medical history?"
          variant="body"
          customClass="mb-2 text-gray font-semibold"
        ></Typography>
        <BasicTextfield
          id="additional"
          multiline
          rows={4}
          variant="filled"
          label="Additional information"
          name="additionalInfo"
          value={additionalInfo}
          onChange={handleInfo}
          classes={`mt-1`}
        />
      </div>
    </div>
  );
};

export default AdditionalInformation;
