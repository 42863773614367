import React from 'react';
import workflows from '../assets/empty-images/workflows.webp';

const Workflows = () => {
  return (
    <div className="flex flex-col justify-center items-center h-full w-full">
      <img src={workflows} alt="Workflows" width="481px" />
      <div className="flex flex-col items-center">
        <p className="text-h3 text-primary">Workflows will be available soon</p>
        <p className="text-body text-gray">
          Speed up clinical processes with default and custom workflows.
        </p>
      </div>
    </div>
  );
};

export default Workflows;
